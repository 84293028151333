import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'

import Box from '@material-ui/core/Box'

import Button from '@app/components/atoms/Button/Button'
import Typography from '@app/components/atoms/Typography/Typography'
import TextField from '@app/components/atoms/TextField/TextField'
import ErrorTooltip from '@app/components/atoms/Tooltip/ErrorTooltip'

import { useYupValidationResolver } from '@app/hooks/useYupValidationResolver'
import usePasswordChangeValidationSchema from '@app/components/organisms/UserPanel/PasswordChangeForm/usePasswordChangeValidationSchema'
import { transformFormDataToUpdatePasswordDto } from '@app/components/organisms/UserPanel/PasswordChangeForm/PasswordChangeForm.transformer'
import { UpdatePasswordDto } from '@app/utils/api/types'

export interface PasswordChangeFormData {
  currentPassword: string
  newPassword: string
  confirmedNewPassword: string
}

interface PasswordTabContentProps {
  isLoading: boolean
  onSubmit: (updatePasswordDto: UpdatePasswordDto) => Promise<void>
  onError?: () => void
}

const PasswordChangeForm = ({
  isLoading,
  onSubmit,
  onError,
}: PasswordTabContentProps): JSX.Element => {
  const { t } = useTranslation()

  const validationSchema = usePasswordChangeValidationSchema()

  const validationResolver = useYupValidationResolver(validationSchema)

  const { handleSubmit, register, formState, reset } =
    useForm<PasswordChangeFormData>({
      resolver: validationResolver,
    })

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height={1}
      width={1}
    >
      <StyledForm
        onSubmit={handleSubmit(async (formValues) => {
          await onSubmit(transformFormDataToUpdatePasswordDto(formValues))

          reset()
        }, onError)}
      >
        <Box>
          <Typography variant="formTitle">
            {t('organisms.UserPanel.PasswordChangeForm.heading')}
          </Typography>

          <Box maxWidth={1 / 2} mt={1} mb={2.8}>
            <ErrorTooltip title={formState.errors.currentPassword?.message}>
              <TextField
                label={t(
                  'organisms.UserPanel.PasswordChangeForm.passwordLabel',
                )}
                placeholder={t(
                  'organisms.UserPanel.PasswordChangeForm.passwordPlaceholder',
                )}
                type="password"
                fullWidth
                inputProps={{ ...register('currentPassword') }}
                error={Boolean(formState.errors.currentPassword)}
              />
            </ErrorTooltip>
          </Box>
          <Box maxWidth={1 / 2} mb={1}>
            <ErrorTooltip title={formState.errors.newPassword?.message}>
              <TextField
                label={t(
                  'organisms.UserPanel.PasswordChangeForm.newPasswordLabel',
                )}
                placeholder={t(
                  'organisms.UserPanel.PasswordChangeForm.newPasswordPlaceholder',
                )}
                type="password"
                fullWidth
                inputProps={{ ...register('newPassword') }}
                error={Boolean(formState.errors.newPassword)}
              />
            </ErrorTooltip>
          </Box>
          <Box maxWidth={1 / 2} my={1}>
            <ErrorTooltip
              title={formState.errors.confirmedNewPassword?.message}
            >
              <TextField
                label={t(
                  'organisms.UserPanel.PasswordChangeForm.confirmedNewPasswordLabel',
                )}
                placeholder={t(
                  'organisms.UserPanel.PasswordChangeForm.confirmedNewPasswordPlaceholder',
                )}
                type="password"
                fullWidth
                inputProps={{ ...register('confirmedNewPassword') }}
                error={Boolean(formState.errors.confirmedNewPassword)}
              />
            </ErrorTooltip>
          </Box>
        </Box>
        <StyledButton
          inverted
          type="submit"
          loading={isLoading}
          disabled={!formState.isDirty}
        >
          {t('organisms.UserPanel.PasswordChangeForm.submit')}
        </StyledButton>
      </StyledForm>
    </Box>
  )
}

const StyledForm = styled.form`
  display: flex;
  justify-content: space-between;
  height: 100%;
  flex-direction: column;
  padding-top: 2.5rem;
`

const StyledButton = styled(Button)`
  margin-bottom: 2rem;
  width: 10rem;
`

export default PasswordChangeForm
