import { UserDto } from '@shared/dto/user.dto'

import { UserProfileFormData } from '@app/components/organisms/UserPanel/UserProfileForm/UserProfileForm'
import { UpdateUserDto } from '@app/utils/api/types'

export const transformUserToFormData = (
  user: UserDto,
): UserProfileFormData => ({
  firstName: user.first_name,
  lastName: user.last_name,
  email: user.email_address,
  phone: user.phone,
})

export const transformFormDataToUserProfileData = (
  formData: UserProfileFormData,
): UpdateUserDto => ({
  first_name: formData.firstName,
  last_name: formData.lastName,
  email_address: formData.email,
  phone: formData.phone,
})
