import React from 'react'
import { useTheme } from 'styled-components'

import Badge, { BadgeProps } from '@app/components/atoms/Badge/Badge'

type RejectedBadgeProps = Partial<BadgeProps>

const RejectedBadge = ({
  color,
  label = 'rejected',
  ...props
}: RejectedBadgeProps): JSX.Element => {
  const theme = useTheme()

  return <Badge color={color ?? theme.colors.red} label={label} {...props} />
}

export default RejectedBadge
