import React from 'react'
import ReactMarkdown from 'react-markdown'
import { useTranslation } from 'react-i18next'
import { navigate, RouteComponentProps } from '@reach/router'

import CredentialsLayout, {
  SidebarAction,
} from '@app/components/organisms/CredentialsLayout/CredentialsLayout'

import { Routes } from '@shared/enums'

const BACK_ACTION_ID = 'go-back'

const NotFound = (props: RouteComponentProps): JSX.Element => {
  const { t } = useTranslation()

  const sidebarActions: SidebarAction[] = [
    { id: BACK_ACTION_ID, label: t('pages.NotFound.goBack') },
    { id: Routes.Login, label: t('pages.NotFound.login') },
  ]

  const handleSidebarActionButtonClick = (sidebarAction: SidebarAction) => {
    if (sidebarAction.id === BACK_ACTION_ID) {
      return window.history.back()
    }

    navigate(sidebarAction.id)
  }

  return (
    <CredentialsLayout
      sidebarTitle={t('pages.NotFound.sidebarTitle')}
      sidebarActions={sidebarActions}
      onSidebarActionButtonClick={handleSidebarActionButtonClick}
      sidebarContent={
        <ReactMarkdown>{t('pages.NotFound.content')}</ReactMarkdown>
      }
      {...props}
    />
  )
}

export default NotFound
