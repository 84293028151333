import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Box } from '@material-ui/core'

import Button from '@app/components/atoms/Button/Button'
import Typography from '@app/components/atoms/Typography/Typography'
import TextField from '@app/components/atoms/TextField/TextField'
import ErrorTooltip from '@app/components/atoms/Tooltip/ErrorTooltip'

import { UserDto } from '@shared/dto/user.dto'
import { useYupValidationResolver } from '@app/hooks/useYupValidationResolver'
import useUserProfileValidationSchema from '@app/components/organisms/UserPanel/UserProfileForm/useUserProfileValidationSchema'
import { UpdateUserDto } from '@app/utils/api/types'

import {
  transformFormDataToUserProfileData,
  transformUserToFormData,
} from '@app/components/organisms/UserPanel/UserProfileForm/UserProfileForm.transformer'

export interface UserProfileFormData {
  firstName: string
  lastName: string
  email: string
  phone: string
}

interface UserProfileFormProps {
  user: UserDto
  isLoading: boolean
  onSubmit: (userProfileData: UpdateUserDto) => void
  onError?: () => void
}

const UserProfileForm = ({
  user,
  isLoading,
  onSubmit,
  onError,
}: UserProfileFormProps): JSX.Element => {
  const { t } = useTranslation()

  const validationSchema = useUserProfileValidationSchema()

  const validationResolver = useYupValidationResolver(validationSchema)

  const { handleSubmit, register, formState, reset } =
    useForm<UserProfileFormData>({
      resolver: validationResolver,
      defaultValues: transformUserToFormData(user),
    })

  useEffect(() => {
    reset(transformUserToFormData(user))
  }, [user])

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      flex={1}
      height={1}
      width={1}
    >
      <StyledForm
        onSubmit={handleSubmit(
          (formData) => onSubmit(transformFormDataToUserProfileData(formData)),
          onError,
        )}
      >
        <Box width={1}>
          <Typography variant="formTitle">
            {t('organisms.UserPanel.UserProfileForm.heading')}
          </Typography>
          <Box maxWidth={1 / 2} my={1}>
            <ErrorTooltip title={formState.errors.firstName?.message}>
              <TextField
                label={t('organisms.UserPanel.UserProfileForm.firstName')}
                fullWidth
                inputProps={{ ...register('firstName') }}
                error={Boolean(formState.errors.firstName)}
              />
            </ErrorTooltip>
          </Box>

          <Box maxWidth={1 / 2} my={1}>
            <ErrorTooltip title={formState.errors.lastName?.message}>
              <TextField
                label={t('organisms.UserPanel.UserProfileForm.lastName')}
                fullWidth
                inputProps={{ ...register('lastName') }}
                error={Boolean(formState.errors.lastName)}
              />
            </ErrorTooltip>
          </Box>
          <Box maxWidth={1 / 2} my={1}>
            <ErrorTooltip title={formState.errors.email?.message}>
              <TextField
                label={t('organisms.UserPanel.UserProfileForm.email')}
                fullWidth
                inputProps={{ ...register('email') }}
                error={Boolean(formState.errors.email)}
              />
            </ErrorTooltip>
          </Box>
          <Box maxWidth={1 / 2} my={1}>
            <ErrorTooltip title={formState.errors.phone?.message}>
              <TextField
                label={t('organisms.UserPanel.UserProfileForm.phone')}
                fullWidth
                inputProps={{ ...register('phone') }}
                error={Boolean(formState.errors.phone)}
              />
            </ErrorTooltip>
          </Box>
        </Box>
        <StyledButton
          inverted
          type="submit"
          loading={isLoading}
          disabled={!formState.isDirty}
        >
          {t('organisms.UserPanel.UserProfileForm.submit')}
        </StyledButton>
      </StyledForm>
    </Box>
  )
}

const StyledForm = styled.form`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding-top: 2.5rem;
  height: 100%;
  width: 100%;
`

const StyledButton = styled(Button)`
  margin-bottom: 2rem;
  width: 5.375rem;
`

export default UserProfileForm
