import React, { MouseEvent, useRef, useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { useDateAndTimeFormats } from '@app/hooks/useDateAndTimeFormats'

dayjs.extend(utc)

const TIME_INDICATION_TIMEOUT = 500

interface TimeIndicatorLabelProps {
  $left: string
}

interface TimelineProps {
  viewStart: Date
  viewEnd: Date
  breakpoints: Date[]
  dateFormat: string
  grid: Date[]
  className?: string
}

const Timeline = ({
  breakpoints,
  grid,
  viewStart,
  viewEnd,
  dateFormat,
  className,
}: TimelineProps) => {
  const pendingTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const { timeFormat } = useDateAndTimeFormats()

  const [currentMousePosition, setCurrentMousePosition] = useState(0)
  const [isTimeIndicatorVisible, setIsTimeIndicatorVisible] = useState(false)

  const viewStartDayJs = dayjs.utc(viewStart)
  const visibleDurationInMilliseconds = dayjs
    .utc(viewEnd)
    .diff(dayjs.utc(viewStart))

  const getTimeFromXPosition = (position: number) => {
    if (!containerRef.current) {
      return '-'
    }

    const containerWidth = containerRef.current.offsetWidth
    const positionInPercentage = position / containerWidth
    const millisecondsFromStart =
      visibleDurationInMilliseconds * positionInPercentage

    const time = viewStartDayJs.add(millisecondsFromStart)

    return time.format(timeFormat)
  }

  const handleMouseMove = (event: MouseEvent<HTMLDivElement>) => {
    setIsTimeIndicatorVisible(false)

    if (!containerRef.current) {
      return
    }

    const rect = containerRef.current.getBoundingClientRect()

    setCurrentMousePosition(event.clientX - rect.left)

    if (pendingTimeoutRef.current) {
      clearTimeout(pendingTimeoutRef.current)
    }

    pendingTimeoutRef.current = setTimeout(() => {
      setIsTimeIndicatorVisible(true)

      pendingTimeoutRef.current = null
    }, TIME_INDICATION_TIMEOUT)
  }

  const handleMouseOut = () => {
    if (pendingTimeoutRef.current) {
      clearTimeout(pendingTimeoutRef.current)
    }

    setIsTimeIndicatorVisible(false)
    pendingTimeoutRef.current = null
  }

  return (
    <Container
      ref={containerRef}
      onMouseMove={handleMouseMove}
      onMouseOut={handleMouseOut}
      className={className}
    >
      {grid.map((timeIndicator) => {
        if (
          breakpoints.some((breakpoint) =>
            dayjs.utc(breakpoint).isSame(timeIndicator),
          )
        ) {
          return null
        }

        const positionPercentage =
          (dayjs.utc(timeIndicator).diff(viewStartDayJs) /
            visibleDurationInMilliseconds) *
          100

        return (
          <TimeIndicator
            key={timeIndicator.getTime()}
            $left={`${positionPercentage}%`}
          />
        )
      })}
      {breakpoints.map((breakpoint) => {
        const positionPercentage =
          (dayjs.utc(breakpoint).diff(viewStartDayJs) /
            visibleDurationInMilliseconds) *
          100

        return (
          <Breakpoint
            key={breakpoint.getTime()}
            $left={`${positionPercentage}%`}
          >
            {dayjs.utc(breakpoint).format(dateFormat)}
          </Breakpoint>
        )
      })}
      {isTimeIndicatorVisible && (
        <TimeIndicatorLabel $left={`${currentMousePosition}px`}>
          {getTimeFromXPosition(currentMousePosition)}
        </TimeIndicatorLabel>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 30px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[200]};
`

const Breakpoint = styled.div<{ $left: string }>`
  position: absolute;
  left: ${({ $left }) => $left};
  height: 100%;
  transform: translate(-50%, 0);
  font-size: 0.625rem;
  color: ${({ theme }) => theme.palette.grey[400]};
  white-space: nowrap;

  &:after {
    position: absolute;
    left: 50%;
    content: '';
    border-left: 1px solid ${({ theme }) => theme.palette.grey[400]};
    bottom: 3px;
    height: 6px;
  }
`

const TimeIndicator = styled.div<{ $left: string }>`
  position: absolute;
  left: ${({ $left }) => $left};
  transform: translate(-50%, 0);
  height: 100%;

  &:after {
    position: absolute;
    left: 50%;
    content: '';
    border-left: 1px solid ${({ theme }) => theme.palette.grey[200]};
    bottom: 2px;
    height: 4px;
  }
`

const TimeIndicatorLabel = styled.div<TimeIndicatorLabelProps>`
  position: absolute;
  top: 0;
  transform: translate(-10%, 0);
  left: ${({ $left }) => `calc(${$left} + 0.5rem)`};
  color: ${({ theme }) => theme.palette.common.white};
  background: ${({ color, theme }) => color ?? theme.palette.grey[500]};
  padding: 0.2rem 0.5rem;
  font-size: 0.75rem;
  border-radius: 2px;
  margin-top: 2px;
`

export default Timeline
