import dayjs, { OpUnitType } from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { SIMPLE_DATE_FORMAT, SIMPLE_TIME_FORMAT } from '@shared/constants'
import { ScheduleDetailDto } from '@shared/dto/schedule.dto'
import { LegTypes } from '@shared/enums'
import { getLegTurnaroundTimeInMinutes } from '@shared/utils/legUtils'
import { MarketingLegConversionMenuPosition } from '@app/components/molecules/Schedule/MarketingLegExtensionMenu'

import {
  getMarketplaceExtensionArrivalDateBoundary,
  getMarketplaceExtensionDepartureDateBoundary,
} from '@shared/utils/marketplaceExtensionUtils'

import {
  getPrecedingScheduleItem,
  getSucceedingScheduleItem,
} from '@shared/utils/scheduleUtils'

dayjs.extend(utc)

export enum ScheduleDensity {
  Seconds = 'Seconds',
  FiveSeconds = 'FiveSeconds',
  FifteenSeconds = 'FifteenSeconds',
  ThirtySeconds = 'MinuteHalfs',
  Minutes = 'Minutes',
  FiveMinutes = 'FiveMinutes',
  FifteenMinutes = 'FifteenMinutes',
  ThirtyMinutes = 'ThirtyMinutes',
  Hours = 'Hours',
  ThreeHours = 'ThreeHours',
  SixHours = 'SixHours',
  TwelveHours = 'TwelveHours',
  Days = 'Days',
  ThreeDays = 'ThreeDays',
  Weeks = 'Week',
  TwoWeeks = 'TwoWeeks',
  Months = 'Months',
  YearQuarters = 'YearQuarters',
  YearHalfs = 'YearHalfs',
  Years = 'Years',
}

export const scheduleDensityToMillisecondsMap = {
  [ScheduleDensity.Seconds]: 1_000,
  [ScheduleDensity.FiveSeconds]: 5_000,
  [ScheduleDensity.FifteenSeconds]: 15_000,
  [ScheduleDensity.ThirtySeconds]: 30_000,
  [ScheduleDensity.Minutes]: 60_000,
  [ScheduleDensity.FiveMinutes]: 300_000,
  [ScheduleDensity.FifteenMinutes]: 900_000,
  [ScheduleDensity.ThirtyMinutes]: 1_800_000,
  [ScheduleDensity.Hours]: 3_600_000,
  [ScheduleDensity.ThreeHours]: 10_800_000,
  [ScheduleDensity.SixHours]: 21_600_000,
  [ScheduleDensity.TwelveHours]: 43_200_000,
  [ScheduleDensity.Days]: 86_400_000,
  [ScheduleDensity.ThreeDays]: 259_200_000,
  [ScheduleDensity.Weeks]: 604_800_000,
  [ScheduleDensity.TwoWeeks]: 1_209_600_000,
  [ScheduleDensity.Months]: 2_592_000_000,
  [ScheduleDensity.YearQuarters]: 7_776_000_000,
  [ScheduleDensity.YearHalfs]: 15_552_000_000,
  [ScheduleDensity.Years]: 3.1556926 * Math.pow(10, 10),
}

// density of timeline itself (not only the breakpoints)
export const scheduleDensityToGridStepMap = {
  [ScheduleDensity.Seconds]: 250,
  [ScheduleDensity.FiveSeconds]:
    scheduleDensityToMillisecondsMap[ScheduleDensity.Seconds],
  [ScheduleDensity.FifteenSeconds]:
    scheduleDensityToMillisecondsMap[ScheduleDensity.Seconds],
  [ScheduleDensity.ThirtySeconds]:
    scheduleDensityToMillisecondsMap[ScheduleDensity.FiveSeconds],
  [ScheduleDensity.Minutes]:
    scheduleDensityToMillisecondsMap[ScheduleDensity.FifteenSeconds],
  [ScheduleDensity.FiveMinutes]:
    scheduleDensityToMillisecondsMap[ScheduleDensity.Minutes],
  [ScheduleDensity.FifteenMinutes]:
    scheduleDensityToMillisecondsMap[ScheduleDensity.Minutes],
  [ScheduleDensity.ThirtyMinutes]:
    scheduleDensityToMillisecondsMap[ScheduleDensity.FiveMinutes],
  [ScheduleDensity.Hours]:
    scheduleDensityToMillisecondsMap[ScheduleDensity.FifteenMinutes],
  [ScheduleDensity.ThreeHours]:
    scheduleDensityToMillisecondsMap[ScheduleDensity.Hours],
  [ScheduleDensity.SixHours]:
    scheduleDensityToMillisecondsMap[ScheduleDensity.Hours],
  [ScheduleDensity.TwelveHours]:
    scheduleDensityToMillisecondsMap[ScheduleDensity.Hours],
  [ScheduleDensity.Days]:
    scheduleDensityToMillisecondsMap[ScheduleDensity.SixHours],
  [ScheduleDensity.ThreeDays]:
    scheduleDensityToMillisecondsMap[ScheduleDensity.Days],
  [ScheduleDensity.Weeks]:
    scheduleDensityToMillisecondsMap[ScheduleDensity.Days],
  [ScheduleDensity.TwoWeeks]:
    scheduleDensityToMillisecondsMap[ScheduleDensity.Days],
  [ScheduleDensity.Months]:
    scheduleDensityToMillisecondsMap[ScheduleDensity.Weeks],
  [ScheduleDensity.YearQuarters]:
    scheduleDensityToMillisecondsMap[ScheduleDensity.Months],
  [ScheduleDensity.YearHalfs]:
    scheduleDensityToMillisecondsMap[ScheduleDensity.Months],
  [ScheduleDensity.Years]:
    scheduleDensityToMillisecondsMap[ScheduleDensity.YearQuarters],
}

const scheduleDensityToDayJSStartOfMap: Record<ScheduleDensity, OpUnitType> = {
  [ScheduleDensity.Seconds]: 'minutes',
  [ScheduleDensity.FiveSeconds]: 'minutes',
  [ScheduleDensity.FifteenSeconds]: 'minute',
  [ScheduleDensity.ThirtySeconds]: 'minute',
  [ScheduleDensity.Minutes]: 'hour',
  [ScheduleDensity.FiveMinutes]: 'hour',
  [ScheduleDensity.FifteenMinutes]: 'hour',
  [ScheduleDensity.ThirtyMinutes]: 'hour',
  [ScheduleDensity.Hours]: 'day',
  [ScheduleDensity.ThreeHours]: 'day',
  [ScheduleDensity.SixHours]: 'day',
  [ScheduleDensity.TwelveHours]: 'day',
  [ScheduleDensity.Days]: 'week',
  [ScheduleDensity.ThreeDays]: 'week',
  [ScheduleDensity.Weeks]: 'month',
  [ScheduleDensity.TwoWeeks]: 'month',
  [ScheduleDensity.Months]: 'year',
  [ScheduleDensity.YearQuarters]: 'year',
  [ScheduleDensity.YearHalfs]: 'year',
  [ScheduleDensity.Years]: 'year',
}

export const getDateFormatByScheduleDensity = (
  density: ScheduleDensity,
  options: { timeFormat: string; dateFormat: string } = {
    timeFormat: SIMPLE_TIME_FORMAT,
    dateFormat: SIMPLE_DATE_FORMAT,
  },
) => {
  const { timeFormat, dateFormat } = options

  switch (density) {
    case ScheduleDensity.Seconds:
    case ScheduleDensity.FiveSeconds:
    case ScheduleDensity.FifteenSeconds:
    case ScheduleDensity.ThirtySeconds:
    case ScheduleDensity.Minutes:
    case ScheduleDensity.FiveMinutes:
    case ScheduleDensity.FifteenMinutes:
    case ScheduleDensity.ThirtyMinutes:
      return timeFormat

    case ScheduleDensity.Days:
    case ScheduleDensity.ThreeDays:
    case ScheduleDensity.Weeks:
    case ScheduleDensity.TwoWeeks:
    case ScheduleDensity.Months:
    case ScheduleDensity.YearQuarters:
    case ScheduleDensity.YearHalfs:
    case ScheduleDensity.Years:
      return dateFormat

    case ScheduleDensity.Hours:
    case ScheduleDensity.ThreeHours:
    case ScheduleDensity.SixHours:
    case ScheduleDensity.TwelveHours:
    default:
      return `${dateFormat} ${timeFormat}`
  }
}

export const getScheduleBreakpointsDensity = (
  viewStart: Date,
  viewEnd: Date,
  maximumBreakpointsPerView = 8,
): ScheduleDensity => {
  const displayedDurationInMilliseconds = dayjs.utc(viewEnd).diff(viewStart)

  const density = Object.entries(scheduleDensityToMillisecondsMap).find(
    ([, duration]) =>
      displayedDurationInMilliseconds < maximumBreakpointsPerView * duration,
  )

  return (density?.[0] as ScheduleDensity) ?? ScheduleDensity.Years
}

export const getMarketingLegExtensionInfo = (
  schedule: ScheduleDetailDto[],
  currentIndex: number,
  onConvertToMarketingLeg: (
    id: number,
    dates: { departureDate?: Date; arrivalDate?: Date },
  ) => void,
  { viewStart, viewEnd }: { viewStart: Date; viewEnd: Date },
) => {
  const currentScheduleItem = schedule[currentIndex]

  if (
    currentScheduleItem?.type !== LegTypes.Empty ||
    !currentScheduleItem?.aircraft
  ) {
    return null
  }

  const precedingScheduleItem = getPrecedingScheduleItem(
    currentScheduleItem,
    schedule,
  )

  const succeedingScheduleItem = getSucceedingScheduleItem(
    currentScheduleItem,
    schedule,
  )

  const departureDateLeftBoundary =
    getMarketplaceExtensionDepartureDateBoundary(
      currentScheduleItem,
      precedingScheduleItem ?? null,
      currentScheduleItem.aircraft,
    )

  const arrivalDateRightBoundary = getMarketplaceExtensionArrivalDateBoundary(
    currentScheduleItem,
    succeedingScheduleItem ?? null,
    currentScheduleItem.aircraft,
  )

  const isMaximumDepartureExtensionBeforeDeparture = dayjs
    .utc(departureDateLeftBoundary)
    .isBefore(currentScheduleItem.departure_date)

  const isMinimumDepartureExtensionInView = dayjs
    .utc(viewStart)
    .isBefore(
      dayjs
        .utc(currentScheduleItem.departure_date)
        .subtract(
          getLegTurnaroundTimeInMinutes(
            currentScheduleItem.type,
            currentScheduleItem.aircraft,
          ),
          'minutes',
        ),
    )

  if (
    isMaximumDepartureExtensionBeforeDeparture &&
    isMinimumDepartureExtensionInView
  ) {
    return {
      position: MarketingLegConversionMenuPosition.Start,
      onConvertToMarketingLeg: () =>
        onConvertToMarketingLeg(currentScheduleItem.id, {
          arrivalDate: dayjs.utc(currentScheduleItem.arrival_date).toDate(),
        }),
    }
  }

  const isMaximumArrivalExtensionAfterArrival = dayjs
    .utc(arrivalDateRightBoundary)
    .isAfter(currentScheduleItem.arrival_date)

  const isMinimumArrivalExtensionInView = dayjs
    .utc(viewEnd)
    .isAfter(
      dayjs
        .utc(currentScheduleItem.arrival_date)
        .add(
          getLegTurnaroundTimeInMinutes(
            succeedingScheduleItem
              ? succeedingScheduleItem.type
              : LegTypes.Occupied,
            currentScheduleItem.aircraft,
          ),
          'minutes',
        ),
    )

  if (
    isMaximumArrivalExtensionAfterArrival &&
    isMinimumArrivalExtensionInView
  ) {
    return {
      position: MarketingLegConversionMenuPosition.End,
      onConvertToMarketingLeg: () =>
        onConvertToMarketingLeg(currentScheduleItem.id, {
          departureDate: dayjs.utc(currentScheduleItem.departure_date).toDate(),
        }),
    }
  }

  return null
}

export const getScheduleSteps = (
  start: Date,
  end: Date,
  density: ScheduleDensity,
  map: Record<ScheduleDensity, number>,
  steps: Date[] = [],
): Date[] => {
  if (!steps.length) {
    const startUnit = scheduleDensityToDayJSStartOfMap[density]

    const firstBreakpoint = dayjs.utc(start).startOf(startUnit)

    return getScheduleSteps(start, end, density, map, [
      firstBreakpoint.toDate(),
    ])
  }

  const step = map[density]

  const lastItem = dayjs.utc(steps.concat().pop())
  const nextItem = dayjs.utc(lastItem).add(step)

  if (dayjs.utc(start).isAfter(lastItem)) {
    return getScheduleSteps(start, end, density, map, [
      lastItem.add(step).toDate(),
    ])
  }

  return nextItem.isAfter(end)
    ? steps
    : getScheduleSteps(
        start,
        end,
        density,
        map,
        steps.concat(nextItem.toDate()),
      )
}
