import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import ChatMessage from '@app/components/molecules/ChatMessage/ChatMessage'
import Typography from '@app/components/atoms/Typography/Typography'
import LoadingSpinner from '@app/components/atoms/LoadingSpinner/LoadingSpinner'

import ChatForm, {
  SubmitChatMessageData,
} from '@app/components/organisms/Chat/ChatForm'

import { ChatMessageDto } from '@shared/dto/chat.dto'
import { OfferDetailDto } from '@shared/dto/requests.dto'

interface ChatProps {
  messages: ChatMessageDto[] | null
  isLoading: boolean
  error: unknown
  onSubmitMessage: (data: SubmitChatMessageData) => void
  disabled?: boolean
  selectedOffer?: OfferDetailDto
  className?: string
}

const Chat = ({
  messages,
  isLoading,
  error,
  onSubmitMessage,
  disabled,
  selectedOffer,
  className,
}: ChatProps) => {
  const { t } = useTranslation()
  const messagesEndRef = useRef<HTMLDivElement>(null)
  const messagesContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    messagesContainerRef.current?.scrollTo({
      top: messagesContainerRef.current?.scrollHeight,
    })
  }, [messages?.length, messagesContainerRef.current])

  if (error) {
    return <InfoMessage>{t('organisms.Chat.error')}</InfoMessage>
  }

  return (
    <Container className={className}>
      <Heading variant="boldText">{t('organisms.Chat.heading')}</Heading>
      {isLoading && <LoadingSpinner loading={isLoading} />}
      {!isLoading && !messages?.length && (
        <InfoMessage>{t('organisms.Chat.noMessages')}</InfoMessage>
      )}
      {!isLoading && messages && (
        <>
          <MessagesContainer ref={messagesContainerRef}>
            {messages.map((message) => (
              <StyledChatMessage key={message.id} message={message} />
            ))}
            <div ref={messagesEndRef} />
          </MessagesContainer>
          <StyledChatForm
            onSubmit={onSubmitMessage}
            disabled={disabled}
            selectedOffer={selectedOffer}
          />
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem;
`

const Heading = styled(Typography)`
  flex: 0 0 auto;
  margin-bottom: 1.5rem;
`

const MessagesContainer = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  margin-bottom: 1rem;
  padding: 0.2rem;
  white-space: pre-wrap;
`

const StyledChatMessage = styled(ChatMessage)`
  margin-bottom: 2rem;
`

const StyledChatForm = styled(ChatForm)`
  flex: 0 0 auto;
`

const InfoMessage = styled(Typography).attrs({ variant: 'subtitle' })`
  text-align: center;
  margin: 5rem 0;
`

export default Chat
