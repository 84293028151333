import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { ScheduleDetailDto } from '@shared/dto/schedule.dto'
import { OutageFormData } from '@app/components/organisms/OutageForm/OutageForm'
import { getCombinedDate } from '@shared/utils/dateUtils'

import {
  getISOStringIgnoringTimezone,
  getLocalDateIgnoringTimezone,
} from '@app/utils/dateUtils'

import * as DTOs from '@shared/dto'

dayjs.extend(utc)

export const transformScheduleItemToOutageFormData = (
  schedule: ScheduleDetailDto | null,
): OutageFormData | object => {
  if (!schedule || !schedule.aircraft) {
    return {}
  }

  const fromDateTime = getLocalDateIgnoringTimezone(schedule.departure_date)
  const toDateTime = getLocalDateIgnoringTimezone(schedule.arrival_date)

  return {
    label: schedule.label ?? '',
    fromDate: fromDateTime,
    fromTime: fromDateTime,
    toDate: toDateTime,
    toTime: toDateTime,
    aircraft: schedule.aircraft,
  }
}

export const transformOutageFormDataToCreateOutageDto = (
  formData: OutageFormData,
): DTOs.CreateOutageDto => {
  const fromDateTime = getCombinedDate(formData.fromDate, formData.fromTime)

  const toDateTime = getCombinedDate(formData.toDate, formData.toTime)

  return {
    label: formData.label,
    departure_date: getISOStringIgnoringTimezone(fromDateTime),
    arrival_date: getISOStringIgnoringTimezone(toDateTime),
    aircraft_id: formData.aircraft?.id,
  }
}
