import React from 'react'
import { useTheme } from 'styled-components'

import Badge, { BadgeProps } from '@app/components/atoms/Badge/Badge'

type DeclinedBadgeProps = Partial<BadgeProps>

const DeclinedBadge = ({
  color,
  label = 'declined',
  ...props
}: DeclinedBadgeProps): JSX.Element => {
  const theme = useTheme()

  return <Badge color={color ?? theme.colors.brown} label={label} {...props} />
}

export default DeclinedBadge
