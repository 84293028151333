import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import Box from '@material-ui/core/Box'

import MenuOpenIcon from '@material-ui/icons/MenuOpen'
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed'

import Typography from '@app/components/atoms/Typography/Typography'
import Tooltip from '@app/components/atoms/Tooltip/Tooltip'
import AirportTooltip from '@app/components/molecules/AirportTooltip/AirportTooltip'

import useGetAirportIdentificationCode from '@app/hooks/useGetAirportIdentificationCode'
import useGetFormattedDateBasedOnUserDisplaySettings from '@app/hooks/useGetFormattedDateBasedOnUserDisplaySettings'
import { useActiveUser } from '@app/hooks/useActiveUser'

import { TripInfo } from '@shared/dto/requests.dto'
import { DisplayTimeTypes } from '@shared/enums'
import { useAirportNote } from '@app/hooks/useAirportNote'

interface TripInfoCellProps {
  tripInfo: TripInfo[] | null
  similarRequestExists?: boolean
}

const TripInfoCell = ({
  tripInfo,
  similarRequestExists,
}: TripInfoCellProps) => {
  const { t } = useTranslation()
  const getAirportCode = useGetAirportIdentificationCode()
  const getDate = useGetFormattedDateBasedOnUserDisplaySettings()

  const getAirportNote = useAirportNote()

  const itinerary = useMemo(() => {
    if (!tripInfo?.length) {
      return ''
    }

    return tripInfo.map((legInfo) => {
      const departureAirportCode = getAirportCode({
        icao_code: legInfo.leg_departure_airport_icao,
        iata_code: legInfo.leg_departure_airport_iata,
      })

      const arrivalAirportCode = getAirportCode({
        icao_code: legInfo.leg_arrival_airport_icao,
        iata_code: legInfo.leg_arrival_airport_iata,
      })

      const { dateTime: departureDateTime } = getDate(
        legInfo.leg_departure_date,
        legInfo.leg_departure_airport_timezone,
      )

      const { dateTime: arrivalDateTime } = getDate(
        legInfo.leg_arrival_date,
        legInfo.leg_arrival_airport_timezone,
      )

      const passengerCount = legInfo.leg_passenger_count

      return (
        <Box display="flex">
          <Box width="40px">{departureAirportCode}</Box>

          <Box pr="10px">{departureDateTime}</Box>

          <span>-</span>

          <Box boxSizing="content-box" width="40px" pl="10px">
            {arrivalAirportCode}
          </Box>

          <Box>{arrivalDateTime}</Box>

          <Box boxSizing="content-box" width="30px" pl="10px" pr="5px">
            ({passengerCount} {t('general.passengersCount')})
          </Box>
        </Box>
      )
    })
  }, [tripInfo])

  const airportCodes = useMemo(() => {
    if (!tripInfo?.length) {
      return null
    }

    const firstLeg = tripInfo[0]
    const lastLeg = tripInfo[tripInfo.length - 1]

    if (tripInfo.length === 1) {
      const departureCode = getAirportCode({
        icao_code: firstLeg.leg_departure_airport_icao,
        iata_code: firstLeg.leg_departure_airport_iata,
      })

      const arrivalCode = getAirportCode({
        icao_code: firstLeg.leg_arrival_airport_icao,
        iata_code: firstLeg.leg_arrival_airport_iata,
      })
      return (
        <AirportsContainer>
          <Tooltip
            title={
              <AirportTooltip
                airportIcao={firstLeg.leg_departure_airport_icao}
                airportIata={firstLeg.leg_departure_airport_iata}
                airportCountry={firstLeg.leg_departure_airport_country}
                airportCity={firstLeg.leg_departure_airport_city}
                airportRunways={firstLeg.leg_departure_airport_runways}
                timezoneOffsetInMinutes={
                  firstLeg.leg_departure_airport_timezone_offset_in_minutes
                }
                note={getAirportNote(firstLeg.leg_departure_airport_icao)}
              />
            }
          >
            <div>{departureCode}</div>
          </Tooltip>
          <AirportSeparatorContainer>✈</AirportSeparatorContainer>
          <Tooltip
            title={
              <AirportTooltip
                airportIcao={firstLeg.leg_arrival_airport_icao}
                airportIata={firstLeg.leg_arrival_airport_iata}
                airportCountry={firstLeg.leg_arrival_airport_country}
                airportCity={firstLeg.leg_arrival_airport_city}
                airportRunways={firstLeg.leg_arrival_airport_runways}
                timezoneOffsetInMinutes={
                  firstLeg.leg_arrival_airport_timezone_offset_in_minutes
                }
                note={getAirportNote(firstLeg.leg_arrival_airport_icao)}
              />
            }
          >
            <div>{arrivalCode}</div>
          </Tooltip>
        </AirportsContainer>
      )
    }

    const firstLegDepartureCode = getAirportCode({
      icao_code: firstLeg.leg_departure_airport_icao,
      iata_code: firstLeg.leg_departure_airport_iata,
    })

    const firstLegArrivalCode = getAirportCode({
      icao_code: firstLeg.leg_arrival_airport_icao,
      iata_code: firstLeg.leg_arrival_airport_iata,
    })

    const lastLegDepartureCode = getAirportCode({
      icao_code: lastLeg.leg_departure_airport_icao,
      iata_code: lastLeg.leg_departure_airport_iata,
    })

    const lastLegArrivalCode = getAirportCode({
      icao_code: lastLeg.leg_arrival_airport_icao,
      iata_code: lastLeg.leg_arrival_airport_iata,
    })

    if (tripInfo.length === 2 && firstLegArrivalCode === lastLegDepartureCode) {
      return (
        <AirportsContainer>
          <Tooltip
            title={
              <AirportTooltip
                airportIcao={firstLeg.leg_departure_airport_icao}
                airportIata={firstLeg.leg_departure_airport_iata}
                airportCountry={firstLeg.leg_departure_airport_country}
                airportCity={firstLeg.leg_departure_airport_city}
                airportRunways={firstLeg.leg_departure_airport_runways}
                timezoneOffsetInMinutes={
                  firstLeg.leg_departure_airport_timezone_offset_in_minutes
                }
                note={getAirportNote(firstLeg.leg_departure_airport_icao)}
              />
            }
          >
            <div>{firstLegDepartureCode}</div>
          </Tooltip>
          <AirportSeparatorContainer>✈</AirportSeparatorContainer>
          <Tooltip
            title={
              <AirportTooltip
                airportIcao={firstLeg.leg_arrival_airport_icao}
                airportIata={firstLeg.leg_arrival_airport_iata}
                airportCountry={firstLeg.leg_arrival_airport_country}
                airportCity={firstLeg.leg_arrival_airport_city}
                airportRunways={firstLeg.leg_arrival_airport_runways}
                timezoneOffsetInMinutes={
                  firstLeg.leg_arrival_airport_timezone_offset_in_minutes
                }
                note={getAirportNote(firstLeg.leg_arrival_airport_icao)}
              />
            }
          >
            <div>{firstLegArrivalCode}</div>
          </Tooltip>
          <AirportSeparatorContainer>✈</AirportSeparatorContainer>
          <Tooltip
            title={
              <AirportTooltip
                airportIcao={lastLeg.leg_arrival_airport_icao}
                airportIata={lastLeg.leg_arrival_airport_iata}
                airportCountry={lastLeg.leg_arrival_airport_country}
                airportCity={lastLeg.leg_arrival_airport_city}
                airportRunways={lastLeg.leg_arrival_airport_runways}
                timezoneOffsetInMinutes={
                  lastLeg.leg_arrival_airport_timezone_offset_in_minutes
                }
                note={getAirportNote(lastLeg.leg_arrival_airport_icao)}
              />
            }
          >
            <div>{lastLegArrivalCode}</div>
          </Tooltip>
        </AirportsContainer>
      )
    }

    return (
      <AirportsContainer>
        <Tooltip
          title={
            <AirportTooltip
              airportIcao={firstLeg.leg_departure_airport_icao}
              airportIata={firstLeg.leg_departure_airport_iata}
              airportCountry={firstLeg.leg_departure_airport_country}
              airportCity={firstLeg.leg_departure_airport_city}
              airportRunways={firstLeg.leg_departure_airport_runways}
              timezoneOffsetInMinutes={
                firstLeg.leg_departure_airport_timezone_offset_in_minutes
              }
              note={getAirportNote(firstLeg.leg_departure_airport_icao)}
            />
          }
        >
          <div>{firstLegDepartureCode}</div>
        </Tooltip>
        <AirportSeparatorContainer>✈</AirportSeparatorContainer>
        <Tooltip
          title={
            <AirportTooltip
              airportIcao={firstLeg.leg_arrival_airport_icao}
              airportIata={firstLeg.leg_arrival_airport_iata}
              airportCountry={firstLeg.leg_arrival_airport_country}
              airportCity={firstLeg.leg_arrival_airport_city}
              airportRunways={firstLeg.leg_arrival_airport_runways}
              timezoneOffsetInMinutes={
                firstLeg.leg_arrival_airport_timezone_offset_in_minutes
              }
              note={getAirportNote(firstLeg.leg_arrival_airport_icao)}
            />
          }
        >
          <div>{firstLegArrivalCode}</div>
        </Tooltip>
        <AirportSeparatorContainer>
          {tripInfo.length === 2 ? '-' : `- +${tripInfo.length - 2} -`}
        </AirportSeparatorContainer>
        <Tooltip
          title={
            <AirportTooltip
              airportIcao={lastLeg.leg_departure_airport_icao}
              airportIata={lastLeg.leg_departure_airport_iata}
              airportCountry={lastLeg.leg_departure_airport_country}
              airportCity={lastLeg.leg_departure_airport_city}
              airportRunways={lastLeg.leg_departure_airport_runways}
              timezoneOffsetInMinutes={
                lastLeg.leg_departure_airport_timezone_offset_in_minutes
              }
              note={getAirportNote(lastLeg.leg_departure_airport_icao)}
            />
          }
        >
          <div>{lastLegDepartureCode}</div>
        </Tooltip>
        <AirportSeparatorContainer>✈</AirportSeparatorContainer>
        <Tooltip
          title={
            <AirportTooltip
              airportIcao={lastLeg.leg_arrival_airport_icao}
              airportIata={lastLeg.leg_arrival_airport_iata}
              airportCountry={lastLeg.leg_arrival_airport_country}
              airportCity={lastLeg.leg_arrival_airport_city}
              airportRunways={lastLeg.leg_arrival_airport_runways}
              timezoneOffsetInMinutes={
                lastLeg.leg_arrival_airport_timezone_offset_in_minutes
              }
              note={getAirportNote(lastLeg.leg_arrival_airport_icao)}
            />
          }
        >
          <div>{lastLegArrivalCode}</div>
        </Tooltip>
      </AirportsContainer>
    )
  }, [tripInfo])

  if (!tripInfo?.length) {
    return <>-</>
  }

  return (
    <Box display="flex" alignItems="center">
      <Box mr={1}>
        <StyledTooltip title={itinerary}>
          <StyledMenuOpenIcon />
        </StyledTooltip>
      </Box>

      <Container>
        <TripCellDate tripInfo={tripInfo} />
        <AirportCodesTypography component="div">
          {airportCodes}
        </AirportCodesTypography>
      </Container>

      {similarRequestExists && (
        <Box ml={1}>
          <StyledDynamicFeedIcon />
        </Box>
      )}
    </Box>
  )
}

interface TripCellDateProps {
  tripInfo: TripInfoCellProps['tripInfo']
  className?: string
}
export function TripCellDate({ tripInfo, className }: TripCellDateProps) {
  const { t } = useTranslation()
  const getDate = useGetFormattedDateBasedOnUserDisplaySettings()
  const getReverseDate = useGetFormattedDateBasedOnUserDisplaySettings(true)
  const activeUser = useActiveUser()

  if (!tripInfo || tripInfo.length == 0) {
    return <div className={className}>-</div>
  }

  return (
    <div>
      {tripInfo.map((legInfo, index) => {
        const isFirstItem = index === 0
        const isLastItem = index === tripInfo.length - 1

        if (tripInfo.length > 2 && !isFirstItem && !isLastItem) {
          return null
        }

        const { dateTime: departureDateTime, date: departureDate } = getDate(
          legInfo.leg_departure_date,
          legInfo.leg_departure_airport_timezone,
        )

        const {
          dateTime: reverseDepartureDateTime,
          date: reverseDepartureDate,
        } = getReverseDate(
          legInfo.leg_departure_date,
          legInfo.leg_departure_airport_timezone,
        )

        const targetDate =
          tripInfo.length > 1 ? departureDate : departureDateTime

        const reverseTargetDate =
          tripInfo.length > 1 ? reverseDepartureDate : reverseDepartureDateTime

        const title =
          activeUser?.display_time_type === DisplayTimeTypes.AirportLocalTime
            ? t('general.tooltips.UTCDate', { date: reverseTargetDate })
            : t('general.tooltips.LocalDate', { date: reverseTargetDate })

        if (
          tripInfo.length > 1 &&
          isLastItem &&
          dayjs(tripInfo[0].leg_departure_date).isSame(
            legInfo.leg_departure_date,
            'day',
          )
        ) {
          return null
        }

        return (
          <DateTypography key={index} title={title} className={className}>
            {targetDate}
          </DateTypography>
        )
      })}
    </div>
  )
}

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`

const DateTypography = styled(Typography).attrs({
  variant: 'content',
})`
  display: inline;

  &:not(:last-child):after {
    content: ' - ';
  }
`

const AirportCodesTypography = styled(Typography).attrs({
  variant: 'subtitle',
})``

const AirportsContainer = styled.div`
  display: flex;
`

const AirportSeparatorContainer = styled.div`
  padding: 0 0.25rem;
`

const StyledMenuOpenIcon = styled(MenuOpenIcon)`
  color: ${({ theme }) => theme.palette.grey[500]};
`

const StyledDynamicFeedIcon = styled(DynamicFeedIcon)`
  color: ${({ theme }) => theme.palette.grey[500]};
`

const StyledTooltip = styled(Tooltip)`
  // @todo [Tech] Set globally in Tooltip
  .Tooltip__tooltip {
    max-width: none;
  }
`

export default TripInfoCell
