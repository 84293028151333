import React from 'react'
import { useTheme } from 'styled-components'

import Badge, { BadgeProps } from '@app/components/atoms/Badge/Badge'

type QuotedBadgeProps = Partial<BadgeProps>

const QuotedBadge = ({
  color,
  label = 'quoted',
  ...props
}: QuotedBadgeProps): JSX.Element => {
  const theme = useTheme()

  return <Badge color={color ?? theme.colors.purple} label={label} {...props} />
}

export default QuotedBadge
