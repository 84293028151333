import React, { ReactNode } from 'react'
import styled from 'styled-components'

import Box from '@material-ui/core/Box'

import Typography from '@app/components/atoms/Typography/Typography'

interface OfferSummaryItemProps {
  icon: ReactNode
  title: string
  value: ReactNode
}

const OfferSummaryItem = ({ icon, value, title }: OfferSummaryItemProps) => {
  return (
    <Box display="flex" alignItems="center">
      <Box mr={2}>{icon}</Box>
      <Box display="flex" flexDirection="column">
        <TitleTypography variant="content">
          {title.split(' ').map((part, index) => (
            <TitlePart key={index}>{part}</TitlePart>
          ))}
        </TitleTypography>
        <ContentTypography>{value}</ContentTypography>
      </Box>
    </Box>
  )
}

const TitlePart = styled.span`
  display: block;
`

const TitleTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[400]};
  font-size: 0.875rem;
  line-height: 1.25;
`

const ContentTypography = styled(Typography)`
  font-size: 1.5rem;
`

export default OfferSummaryItem
