import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

export const useGetFormattedDuration = () => {
  const { t } = useTranslation()

  return (durationInMinutes: number) => {
    if (!Number.isFinite(durationInMinutes)) {
      return
    }

    const target = dayjs.duration({ minutes: durationInMinutes })

    const hours = Math.floor(target.asHours())

    const minutes = Math.floor(target.subtract(hours, 'hour').asMinutes())

    const fullHours = Math.floor(target.asHours())
    const fullMinutes = Math.floor(target.asMinutes())

    const formatTwoDigits = (value: number) => String(value).padStart(2, '0')

    return t('hooks.useFormattedDuration.durationWithoutDays', {
      hours: formatTwoDigits(hours),
      minutes: formatTwoDigits(minutes),
      fullHours: formatTwoDigits(fullHours),
      fullMinutes: formatTwoDigits(fullMinutes),
    })
  }
}
