import React from 'react'
import styled from 'styled-components'

const NoBookingsIcon = (
  props: Omit<React.SVGProps<SVGSVGElement>, 'ref'>,
): JSX.Element => {
  return (
    <StyledSvg
      viewBox="0 0 57 57"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M47.5 20h-45C1.125 20 0 21.2 0 22.667V28h50v-5.333C50 21.2 48.875 20 47.5 20zM0 54.333C0 55.8 1.125 57 2.5 57h45c1.375 0 2.5-1.2 2.5-2.667V33H0v21.333zM26.313 49h18.375c.734 0 1.312.88 1.312 2s-.578 2-1.313 2H26.314C25.578 53 25 52.12 25 51s.578-2 1.313-2zm-11.48 0h7.334c1.026 0 1.833.88 1.833 2s-.807 2-1.833 2h-7.334C13.807 53 13 52.12 13 51s.807-2 1.833-2zm5.854-7h23.625c.946 0 1.688.88 1.688 2s-.742 2-1.688 2H20.689C19.742 46 19 45.12 19 44s.742-2 1.688-2zM7.834 42h7.334c1.026 0 1.833.88 1.833 2s-.807 2-1.833 2H7.833C6.807 46 6 45.12 6 44s.807-2 1.833-2zm29-6h7.334c1.026 0 1.833.88 1.833 2s-.807 2-1.833 2h-7.334C35.807 40 35 39.12 35 38s.807-2 1.833-2zm-13 0h7.334c1.026 0 1.833.88 1.833 2s-.807 2-1.833 2h-7.334C22.807 40 22 39.12 22 38s.807-2 1.833-2z"
          fill="#E0E0E0"
        />
        <path
          d="M47 0c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10S52.52 0 47 0zm-3 18l2-7h-4l8-9-2 7h4l-8 9z"
          fill="currentColor"
        />
      </g>
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  color: ${({ theme }) => theme.palette.grey[200]};
`

export default NoBookingsIcon
