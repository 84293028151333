import React from 'react'
import styled from 'styled-components'

import BaseSwitch, {
  SwitchProps as BaseSwitchProps,
} from '@material-ui/core/Switch'

import { prop } from '@app/utils/css'

type Sizes = 'normal' | 'large'

interface SwitchProps extends Omit<BaseSwitchProps, 'size' | 'color'> {
  size?: Sizes
  color?: string
}

interface StyledSwitchProps extends SwitchProps {
  $scale: number
  $color?: string
}

const SIZES_TO_SCALE_MAP = {
  normal: 1.125,
  large: 2,
}

const Switch = ({
  size = 'normal',
  color,
  ...props
}: SwitchProps): JSX.Element => {
  return (
    <StyledBaseSwitch
      $scale={SIZES_TO_SCALE_MAP[size]}
      $color={color}
      classes={{
        checked: 'Switch__checked',
        switchBase: 'Switch__base',
        track: 'Switch__track',
        thumb: 'Switch__thumb',
      }}
      {...props}
    />
  )
}

const StyledBaseSwitch = styled(BaseSwitch)<StyledSwitchProps>`
  display: flex;
  height: ${prop('$scale')}rem;
  padding: 0;
  width: ${({ $scale }) => $scale * 2}rem;

  & .Switch__base {
    color: ${({ theme, $color }) => $color ?? theme.palette.primary.main};
    padding: ${({ $scale }) => $scale * 0.1}rem;
  }

  & .Switch__checked {
    color: ${({ theme }) => theme.palette.common.white};
    transform: translateX(${prop('$scale')}rem);
  }

  & .Switch__thumb {
    box-shadow: none;
    height: ${({ $scale }) => $scale * 0.8}rem;
    width: ${({ $scale }) => $scale * 0.8}rem;
  }

  & .Switch__track {
    background-color: ${({ theme }) => theme.palette.common.white};
    border: 1px solid ${({ theme }) => theme.palette.grey[600]};
    border-radius: ${prop('$scale')}rem;
    box-sizing: border-box;
    opacity: 1;
  }

  & .Switch__checked + .Switch__track {
    border-radius: ${prop('$scale')}rem;
    box-sizing: border-box;
    opacity: 1;

    border: 1px solid
      ${({ theme, $color }) => $color ?? theme.palette.primary.main};

    background-color: ${({ theme, $color }) =>
      $color ?? theme.palette.primary.main};
  }
`

export default Switch
