import React from 'react'
import { useTheme } from 'styled-components'

import Badge, { BadgeProps } from '@app/components/atoms/Badge/Badge'

type ProcessingBadgeProps = Partial<BadgeProps>

const ProcessingBadge = ({
  color,
  label = 'processing',
  ...props
}: ProcessingBadgeProps): JSX.Element => {
  const theme = useTheme()

  return <Badge color={color ?? theme.colors.grey} label={label} {...props} />
}

export default ProcessingBadge
