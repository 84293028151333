import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import { DisplayTimeTypes } from '@shared/enums'
import { getCombinedDate } from '@shared/utils/dateUtils'

dayjs.extend(utc)
dayjs.extend(timezone)

export const getCombinedLocalDate = (date: Date, time: Date) => {
  const startOfDay = dayjs(date).startOf('day')

  const hours = dayjs(time).hour()
  const minutes = dayjs(time).minute()

  return startOfDay.hour(hours).minute(minutes).toDate()
}

export const getLocalDateIgnoringTimezone = (date: Date | string): Date => {
  const input = typeof date === 'string' ? new Date(date) : date

  const regex = /(.+?)\.\d{3}\w/

  const nextDate = input.toISOString().replace(regex, '$1')

  return new Date(nextDate)
}

export const getISOStringIgnoringTimezone = (date: Date): string => {
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    ),
  ).toISOString()
}

export const getTimezoneAwareDateFromUTC = (
  date: Date,
  timezone: string,
  timeDisplay: DisplayTimeTypes,
) => {
  if (timeDisplay === DisplayTimeTypes.UTC) {
    return date
  }

  const offset = dayjs.utc(date).tz(timezone).utcOffset()

  return dayjs.utc(date).add(offset, 'minutes').toDate()
}

export const getUTCFromTimezoneAwareDate = (
  date: Date,
  time: Date,
  timezone: string,
  timeDisplay: DisplayTimeTypes,
) => {
  const combinedDate = getCombinedDate(new Date(date), new Date(time))

  if (timeDisplay === DisplayTimeTypes.UTC) {
    return getISOStringIgnoringTimezone(combinedDate)
  }

  const offset = dayjs.utc(combinedDate).tz(timezone).utcOffset()

  return dayjs
    .utc(getISOStringIgnoringTimezone(combinedDate))
    .subtract(offset, 'minutes')
    .toISOString()
}
