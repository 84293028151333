import React from 'react'
import { useTranslation } from 'react-i18next'
import Flatpickr from 'react-flatpickr'
import styled from 'styled-components'

import Box from '@material-ui/core/Box'
import ScheduleIcon from '@material-ui/icons/Schedule'
import ClearIcon from '@material-ui/icons/Clear'

import InputEndAdornment from '@app/components/atoms/InputEndAdornment/InputEndAdornment'

import { useDateAndTimeFormats } from '@app/hooks/useDateAndTimeFormats'

import 'flatpickr/dist/themes/material_blue.css'

const TWENTY_FOUR_HOURS_TIME_FORMAT = 'H:i'
const TWELVE_HOURS_TIME_FORMAT = 'h:i K'
const DEFAULT_HOUR_INCREMENT = 1
const DEFAULT_MINUTE_INCREMENT = 5
const DEFAULT_HOUR = 12

interface TimePickerProps {
  value: Date | null
  onChange?: (date: Date | null) => Promise<void> | void
  onBlur?: () => void
  label?: string
  error?: boolean
  warning?: boolean
  className?: string
  allowInput?: boolean
  time_24hr?: boolean
  dateFormat?: string
  hourIncrement?: number
  minuteIncrement?: number
  name?: string
  disabled?: boolean
  placeholder?: string
  'data-testid'?: string
  defaultHour?: number
}

const TimePicker = ({
  name,
  value,
  onChange,
  onBlur,
  label,
  error,
  warning,
  className,
  disabled,
  placeholder,
  defaultHour = DEFAULT_HOUR,
  allowInput = false,
  time_24hr,
  dateFormat,
  hourIncrement = DEFAULT_HOUR_INCREMENT,
  minuteIncrement = DEFAULT_MINUTE_INCREMENT,
  ...props
}: TimePickerProps) => {
  const { t } = useTranslation()
  const { isTime24hFormat } = useDateAndTimeFormats()

  const defaultDateFormat = isTime24hFormat
    ? TWENTY_FOUR_HOURS_TIME_FORMAT
    : TWELVE_HOURS_TIME_FORMAT

  const defaultPlaceholder = t('atoms.TimePicker.defaultPlaceholder')

  return (
    <Box width={1} display="flex" flexDirection="column" className={className}>
      {label && <Label>{label}</Label>}
      <Box position="relative">
        <StyledPicker
          {...props}
          name={name}
          $error={error}
          $warning={warning}
          value={value ?? undefined}
          onChange={(dates: Date[]) => onChange?.(dates[0])}
          onClose={disabled ? () => {} : onBlur}
          disabled={disabled}
          placeholder={placeholder ?? defaultPlaceholder}
          options={{
            enableTime: true,
            noCalendar: true,
            dateFormat: dateFormat ?? defaultDateFormat,
            time_24hr: time_24hr ?? isTime24hFormat,
            allowInput,
            hourIncrement,
            minuteIncrement,
            defaultHour,
          }}
        />
        <StyledIconButton
          $isClearButton={Boolean(value) && !disabled}
          onClick={(event) => {
            if (value && !disabled) {
              event.stopPropagation()
              onChange?.(null)
            }
          }}
        >
          {value && !disabled ? <ClearIcon /> : <ScheduleIcon />}
        </StyledIconButton>
      </Box>
    </Box>
  )
}

const Label = styled.label`
  font-size: 0.8rem;
  margin-bottom: 0.3rem;
`

const StyledPicker = styled(Flatpickr)<{
  $error?: boolean
  $warning?: boolean
}>`
  // @todo Reuse variants from TextField
  font-size: 0.875rem;
  padding: 0.5rem;
  color: ${({ theme }) => theme.palette.grey[800]};
  width: 100%;
  height: 2.375rem;
  border-radius: 3px;
  border: 1px solid
    ${({ $error, $warning, theme }) => {
      if ($error) {
        return theme.palette.error.main
      }

      if ($warning) {
        return theme.palette.warning.main
      }

      return theme.palette.grey[600]
    }};

  ${({ disabled, theme }) => {
    if (disabled) {
      return `
        background: ${theme.palette.grey[100]};
        color: rgba(0, 0, 0, 0.38);
      `
    }
  }}

  &::placeholder {
    color: ${({ theme }) => theme.palette.grey[300]};
  }

  &:focus,
  &:focus-visible,
  &:active,
  &.active {
    border: 1px solid
      ${({ $error, theme }) =>
        $error ? theme.colors.red : theme.palette.primary.main};
  }
`

const StyledIconButton = styled(InputEndAdornment)<{ $isClearButton: boolean }>`
  position: absolute;
  right: 0.6rem;
  top: 50%;
  padding: 0;
  transform: translate(0, -50%);
  pointer-events: ${({ $isClearButton }) =>
    $isClearButton ? 'initial' : 'none'};
`

export default TimePicker
