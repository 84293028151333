import { RootState } from '@app/store'

export const selectIsPostContactPersonLoading = (store: RootState) =>
  store.api.contactPersons.isPostContactPersonLoading

export const selectPostContactPersonError = (store: RootState) =>
  store.api.contactPersons.postContactPersonError

export const selectIsPatchContactPersonLoading = (store: RootState) =>
  store.api.contactPersons.isPatchContactPersonLoading

export const selectPatchContactPersonError = (store: RootState) =>
  store.api.contactPersons.patchContactPersonError

export const selectCreatedContactPersonId = (store: RootState) =>
  store.api.contactPersons.createdContactPersonId
