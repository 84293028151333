import React from 'react'

import styled from 'styled-components'
import { Extras } from '@shared/interfaces/Leg'

type LegTypeTooltipProps = {
  extras?: Extras | null
  isCustomRoute?: boolean
}
const LegTypeTooltip = ({ extras, isCustomRoute }: LegTypeTooltipProps) => {
  if (!extras) {
    return null
  }

  const tooltipLabel =
    extras.airway_id !== null && isCustomRoute
      ? 'Flight time was loaded from your “Custom flight routes”, but originates from calculation based on route and weather'
      : extras.airway_id !== null && !isCustomRoute
        ? 'Flight time was calculated based on route and weather'
        : extras.airway_id === null && isCustomRoute
          ? 'Flight time was loaded from your “Custom flight routes” database'
          : 'Flight time was calculated'

  return (
    <Container>
      <Label>{tooltipLabel}</Label>
    </Container>
  )
}

const Container = styled.div`
  font-size: 0.9rem;
  line-height: 1.2;
`

const Label = styled.div`
  display: inline-block;
`

export default LegTypeTooltip
