import { CellProps } from 'react-table'
import { PartialRequestDto } from '@shared/dto/requests.dto'
import Box from '@material-ui/core/Box'
import Typography from '@app/components/atoms/Typography/Typography'
import React from 'react'

export const RequestedByCell = ({
  cell,
}: CellProps<PartialRequestDto, undefined>) => (
  <Box
    display="flex"
    alignItems="flex-start"
    justifyContent="center"
    flexDirection="column"
  >
    <Typography variant="content">
      {cell.row.original.contact_person_name}
    </Typography>
    <Typography variant="subtitle">{cell.row.original.client_name}</Typography>
  </Box>
)
