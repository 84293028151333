import React, { MutableRefObject, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import dayjs from 'dayjs'

import Button from '@app/components/atoms/Button/Button'
import ErrorTooltip from '@app/components/atoms/Tooltip/ErrorTooltip'
import TextField from '@app/components/atoms/TextField/TextField'
import SingleDatePicker from '@app/components/atoms/SingleDatePicker/SingleDatePicker'
import TimePicker from '@app/components/atoms/TimePicker/TimePicker'

import AircraftPicker, {
  AircraftPickerVariants,
} from '@app/components/atoms/AircraftPicker/AircraftPicker'

import { AircraftDetailDto } from '@shared/dto/aircraft.dto'
import { useYupValidationResolver } from '@app/hooks/useYupValidationResolver'
import useOutageFormValidationSchema from '@app/components/organisms/OutageForm/useOutageFormValidationSchema'
import { ScheduleDetailDto } from '@shared/dto/schedule.dto'
import { getCombinedDate } from '@shared/utils/dateUtils'

import * as DTOs from '@shared/dto'

import {
  transformOutageFormDataToCreateOutageDto,
  transformScheduleItemToOutageFormData,
} from '@app/components/organisms/OutageForm/OutageForm.transformer'

export interface OutageFormData {
  label: string
  aircraft: Omit<AircraftDetailDto, 'base_airport'>
  fromDate: Date
  fromTime: Date
  toDate: Date
  toTime: Date
}

interface OutageFormProps {
  onSubmit: (values: DTOs.CreateOutageDto) => void
  scheduleItem?: ScheduleDetailDto
  submitRef?: MutableRefObject<(() => void) | (() => Promise<void>) | undefined>
}

const OutageForm = ({ onSubmit, scheduleItem, submitRef }: OutageFormProps) => {
  const { t } = useTranslation()
  const validationSchema = useOutageFormValidationSchema()
  const validationResolver = useYupValidationResolver(validationSchema)

  const defaultValues = transformScheduleItemToOutageFormData(
    scheduleItem ?? null,
  )

  const { handleSubmit, control, formState, watch } = useForm<OutageFormData>({
    resolver: validationResolver,
    defaultValues,
  })

  const { fromDate, fromTime, toDate, toTime } = watch()

  const duration = useMemo(() => {
    if (!fromDate || !fromTime || !toDate || !toTime) {
      return '-'
    }

    const fromDateTime = getCombinedDate(new Date(fromDate), new Date(fromTime))
    const toDateTime = getCombinedDate(new Date(toDate), new Date(toTime))

    const diffInMinutes = dayjs(toDateTime).diff(fromDateTime, 'minutes')

    if (diffInMinutes < 0) {
      return '-'
    }

    const hours = Math.floor(diffInMinutes / 60)
      .toString()
      .padStart(2, '0')
    const minutes = (diffInMinutes % 60).toString().padStart(2, '0')

    return t('organisms.OutageForm.durationFormat', { hours, minutes })
  }, [fromDate, fromTime, toDate, toTime])

  const handleSubmitClick = (values: OutageFormData) => {
    const transformedValues = transformOutageFormDataToCreateOutageDto(values)

    onSubmit(transformedValues)
  }

  useEffect(() => {
    if (submitRef) {
      submitRef.current = handleSubmit(handleSubmitClick)
    }
  }, [])

  return (
    <form onSubmit={handleSubmit(handleSubmitClick)}>
      <Row>
        <Controller<OutageFormData, 'label'>
          name="label"
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => {
            return (
              <StyledErrorTooltip title={formState.errors.label?.message}>
                <StyledTextField
                  size="normal"
                  label={t('organisms.OutageForm.label')}
                  placeholder={t('organisms.OutageForm.label')}
                  name={name}
                  value={value ?? ''}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={Boolean(formState.errors.label)}
                  fullWidth
                />
              </StyledErrorTooltip>
            )
          }}
        />
        <Controller<OutageFormData, 'aircraft'>
          name="aircraft"
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => {
            return (
              <ErrorTooltip title={formState.errors.aircraft?.message}>
                <SelectContainer>
                  <AircraftPicker
                    name={name}
                    label={t('organisms.OutageForm.aircraft')}
                    variant={AircraftPickerVariants.Condensed}
                    value={value}
                    onChange={(event, value) => onChange(value)}
                    onBlur={onBlur}
                    error={Boolean(formState.errors.aircraft)}
                    disabled={!!scheduleItem}
                  />
                </SelectContainer>
              </ErrorTooltip>
            )
          }}
        />
      </Row>
      <DatesSection>
        <Controller<OutageFormData, 'fromDate'>
          name="fromDate"
          control={control}
          render={({ field: { value, name, onChange } }) => {
            return (
              <ErrorTooltip title={formState.errors.fromDate?.message}>
                <DatePickerContainer>
                  <SingleDatePicker
                    size="normal"
                    value={value ?? null}
                    onChange={onChange}
                    placeholder={t('organisms.OutageForm.fromDate')}
                    TextFieldProps={{
                      name,
                      error: Boolean(formState.errors.fromDate),
                      label: t('organisms.OutageForm.fromDate'),
                    }}
                    disablePast={false}
                  />
                </DatePickerContainer>
              </ErrorTooltip>
            )
          }}
        />
        <Controller<OutageFormData, 'fromTime'>
          name="fromTime"
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => {
            return (
              <ErrorTooltip title={formState.errors.fromTime?.message}>
                <DatePickerContainer>
                  <TimePicker
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={Boolean(formState.errors.fromTime)}
                  />
                </DatePickerContainer>
              </ErrorTooltip>
            )
          }}
        />
        <Controller<OutageFormData, 'toDate'>
          name="toDate"
          control={control}
          render={({ field: { value, name, onChange } }) => {
            return (
              <ErrorTooltip title={formState.errors.toDate?.message}>
                <DatePickerContainer>
                  <SingleDatePicker
                    size="normal"
                    value={value ?? null}
                    onChange={onChange}
                    placeholder={t('organisms.OutageForm.toDate')}
                    TextFieldProps={{
                      name,
                      error: Boolean(formState.errors.toDate),
                      label: t('organisms.OutageForm.toDate'),
                    }}
                    disablePast={false}
                  />
                </DatePickerContainer>
              </ErrorTooltip>
            )
          }}
        />
        <Controller<OutageFormData, 'toTime'>
          name="toTime"
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => {
            return (
              <ErrorTooltip title={formState.errors.toTime?.message}>
                <DatePickerContainer>
                  <TimePicker
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={Boolean(formState.errors.toTime)}
                  />
                </DatePickerContainer>
              </ErrorTooltip>
            )
          }}
        />
        <DurationField
          size="normal"
          label={t('organisms.OutageForm.durationLabel')}
          value={duration}
          disabled
        />
      </DatesSection>
      {!submitRef && (
        <ButtonsContainer>
          <Button type="submit">{t('organisms.OutageForm.save')}</Button>
        </ButtonsContainer>
      )}
    </form>
  )
}

const Row = styled.div`
  display: flex;
  margin-bottom: 1rem;
  align-items: flex-end;

  & > * {
    margin-right: 0.5rem;
  }

  & > *:last-child {
    margin-right: 0;
  }
`

const DatesSection = styled.div`
  display: flex;
  margin-bottom: 1rem;
  align-items: flex-end;

  & > * {
    margin-right: 0.5rem;
  }

  & > *:last-child {
    margin-right: 0;
  }
`

const StyledTextField = styled(TextField)`
  flex-grow: 1;
`

const DatePickerContainer = styled.div`
  width: 8rem;
`

const SelectContainer = styled.div`
  width: 13.5rem;
`

const DurationField = styled(TextField)`
  width: 5rem;
`

const StyledErrorTooltip = styled(ErrorTooltip)`
  flex-grow: 1;
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export default OutageForm
