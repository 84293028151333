import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { getCombinedDate } from '@shared/utils/dateUtils'

dayjs.extend(utc)

const testFromDateLaterThanToDate: yup.TestFunction = (value, { parent }) => {
  if (
    !parent.fromDate ||
    !parent.fromTime ||
    !parent.toDate ||
    !parent.toTime
  ) {
    return true
  }

  const fromDateTime = getCombinedDate(
    new Date(parent.fromDate),
    new Date(parent.fromTime),
  )

  const toDateTime = getCombinedDate(
    new Date(parent.toDate),
    new Date(parent.toTime),
  )

  return dayjs.utc(fromDateTime).isBefore(dayjs.utc(toDateTime))
}

const useOutageFormValidationSchema = () => {
  const { t } = useTranslation()

  return yup.object().shape({
    label: yup
      .string()
      .required(t('organisms.OutageForm.labelRequired'))
      .typeError(t('organisms.OutageForm.labelRequired')),
    aircraft: yup
      .object()
      .required(t('organisms.OutageForm.aircraftRequired'))
      .typeError(t('organisms.OutageForm.aircraftRequired')),
    fromDate: yup
      .date()
      .required(t('organisms.OutageForm.fromDateRequired'))
      .typeError(t('organisms.OutageForm.fromDateRequired'))
      .test(
        'fromLaterThanTo',
        t('organisms.OutageForm.fromDateLaterThanToDate'),
        testFromDateLaterThanToDate,
      ),
    fromTime: yup
      .date()
      .required(t('organisms.OutageForm.fromTimeRequired'))
      .typeError(t('organisms.OutageForm.fromTimeRequired'))
      .test(
        'fromLaterThanTo',
        t('organisms.OutageForm.fromDateLaterThanToDate'),
        testFromDateLaterThanToDate,
      ),
    toDate: yup
      .date()
      .required(t('organisms.OutageForm.toDateRequired'))
      .typeError(t('organisms.OutageForm.toDateRequired'))
      .test(
        'toLaterThanTo',
        t('organisms.OutageForm.fromDateLaterThanToDate'),
        testFromDateLaterThanToDate,
      ),
    toTime: yup
      .date()
      .required(t('organisms.OutageForm.toTimeRequired'))
      .typeError(t('organisms.OutageForm.toTimeRequired'))
      .test(
        'fromLaterThanTo',
        t('organisms.OutageForm.fromDateLaterThanToDate'),
        testFromDateLaterThanToDate,
      ),
  })
}

export default useOutageFormValidationSchema
