import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import produce from 'immer'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import {
  FieldError,
  FieldErrors,
  useFieldArray,
  useForm,
} from 'react-hook-form'

import Box from '@material-ui/core/Box'

import Button from '@app/components/atoms/Button/Button'
import ControlledLegFieldGroup from '@app/components/organisms/LegEditorForm/LegFieldGroup/ControlledLegFieldGroup'

import {
  DisplayTimeTypes,
  LegTypes,
  OfferStatuses,
  ScheduleSource,
} from '@shared/enums'
import { AirportDetailDto } from '@shared/dto/airports.dto'
import { AircraftDetailDto } from '@shared/dto/aircraft.dto'
import { BaseLegDetailDto, OfferDto } from '@shared/dto/requests.dto'
import { LegComputationRequest } from '@shared/interfaces/Computation'
import { ScheduleDetailDto } from '@shared/dto/schedule.dto'
import { getCombinedLocalDate } from '@app/utils/dateUtils'
import useLegEditorWarningsSchema from '@app/components/organisms/LegEditorForm/useLegEditorWarningsSchema'
import { getAvinodeCompareUrl } from '@app/utils/avinodeUtils'

import styled from 'styled-components'

import useLegEditorValidationSchema, {
  LEGS_ARRIVAL_AIRPORT_CONTINUITY_ERROR_TYPE,
  LEGS_DEPARTURE_AIRPORT_CONTINUITY_ERROR_TYPE,
} from '@app/components/organisms/LegEditorForm/useLegEditorValidationSchema'

import {
  getEarliestCombinedDepartureDateISOString,
  getEditorDefaultValues,
  getLatestCombinedArrivalDateISOString,
  getLegEditorTransformedData,
} from '@app/components/organisms/LegEditorForm/LegEditorForm.utils'

import {
  getFieldErrorsFromException,
  useYupValidationResolver,
} from '@app/hooks/useYupValidationResolver'
import { LegFormData } from '@shared/interfaces/Leg'
import { CustomRouteDetailDto } from '@shared/dto/customRoutes.dto'

dayjs.extend(utc)

const GATSBY_BASE_AVINODE_URL = process.env.GATSBY_BASE_AVINODE_URL

export interface LegEditorFormData {
  legs: LegFormData[]
}

export interface LegEditorProps {
  avinodeId?: string
  offer: OfferDto
  legs: BaseLegDetailDto[]
  aircraft: AircraftDetailDto
  className?: string
  onIsDirtyChange?: (isDirty: boolean) => void | Promise<void>
  readonly?: boolean
  loading?: boolean
  areCommonButtonsVisible?: boolean
  timeDisplay?: DisplayTimeTypes
  schedule: ScheduleDetailDto[]
  removedLegs: BaseLegDetailDto[]
  onRequestTimeBoundariesChange: (from: string, to: string) => void
  onLegEditorErrorsChange?: (
    errors: FieldErrors<LegEditorFormData>,
  ) => Promise<void> | void
  onSubmit: (
    values: { requests: LegComputationRequest[] },
    warnings?: Record<string, FieldError>,
  ) => void
  isBookedChange: boolean
  onShowMapButtonClick?: () => void
  offerRelatedCustomRoutes: CustomRouteDetailDto[] | null
}

const offerValidationStatuses: OfferStatuses[] = [
  OfferStatuses.New,
  OfferStatuses.Unhandled,
  OfferStatuses.Draft,
  OfferStatuses.Quoted,
  OfferStatuses.Booked,
  OfferStatuses.BookedCancelled,
]

const LegEditorForm = ({
  avinodeId,
  offer,
  legs,
  aircraft,
  onSubmit,
  className,
  onIsDirtyChange,
  readonly,
  loading,
  schedule,
  removedLegs,
  onRequestTimeBoundariesChange,
  onLegEditorErrorsChange,
  timeDisplay = DisplayTimeTypes.UTC,
  areCommonButtonsVisible = true,
  isBookedChange = false,
  onShowMapButtonClick,
  offerRelatedCustomRoutes,
}: LegEditorProps): JSX.Element => {
  const { t } = useTranslation()
  const [warnings, setWarnings] = useState({})

  const validationSchema = useLegEditorValidationSchema({
    schedule: schedule.filter((s) => s.source === ScheduleSource.STRAFOS),
    removedLegs,
    timeDisplay,
  })

  const warningsSchema = useLegEditorWarningsSchema({
    schedule: schedule.filter((s) => s.source === ScheduleSource.STRAFOS),
    removedLegs,
    timeDisplay,
  })

  const validationResolver = useYupValidationResolver(validationSchema, {
    warningsSchema,
    warnings,
    setWarnings,
  })

  const defaultValues = getEditorDefaultValues(legs, aircraft, { timeDisplay })

  const { handleSubmit, control, formState, reset, trigger, watch, setValue } =
    useForm<LegEditorFormData>({
      defaultValues,

      resolver: offerValidationStatuses.includes(offer.status)
        ? validationResolver
        : undefined,
    })

  const { fields, append, remove, insert, update } = useFieldArray({
    control,
    name: 'legs',
  })

  const legValues: BaseLegDetailDto[] = watch('legs')

  const earliestDepartureDateTime =
    getEarliestCombinedDepartureDateISOString(legValues)

  const latestArrivalDateTime = getLatestCombinedArrivalDateISOString(legValues)

  const getErrorsForCurrentLeg = (
    errors: FieldErrors<LegEditorFormData>,
    index: number,
  ) => {
    const baseLegPath = `legs[${index}]`

    const getIsFieldError = (value: object): value is FieldError =>
      !!(value as FieldError).message

    return Object.entries(errors).reduce<Record<string, FieldError>>(
      (acc, [key, value]) => {
        if (!getIsFieldError(value)) {
          return acc
        }

        const isErrorForWholeRow = key === baseLegPath
        const isErrorRelatedToDepartureAirport = [
          LEGS_DEPARTURE_AIRPORT_CONTINUITY_ERROR_TYPE,
        ].includes(value.type)

        const isErrorRelatedToArrivalAirport = [
          LEGS_ARRIVAL_AIRPORT_CONTINUITY_ERROR_TYPE,
        ].includes(value.type)

        const getSanitizedErrorKey = (key: string) => {
          if (isErrorForWholeRow && isErrorRelatedToDepartureAirport) {
            return 'departureAirport'
          }

          if (isErrorForWholeRow && isErrorRelatedToArrivalAirport) {
            return 'arrivalAirport'
          }

          return key.replace(`${baseLegPath}.`, '')
        }

        const sanitizedKey = getSanitizedErrorKey(key)

        return {
          ...acc,
          [sanitizedKey]: value,
        }
      },
      {},
    )
  }

  const handleSplitLegClick = (index: number, airport: AirportDetailDto) => {
    const splitField = produce<Partial<(typeof fields)[0]>>(
      fields[index],
      (draft) => {
        delete draft.id
      },
    )

    insert(index + 1, {
      ...splitField,
      departureAirport: airport,
      departureDate: splitField.arrivalDate,
      departureTime: splitField.arrivalTime,
      arrivalDate: null,
      arrivalTime: null,
      isSplitLeg: true,
    })
  }

  const updateLegFromCalculations = (index: number, leg: LegFormData) => {
    update(index, leg)
  }

  const handleSplitLegByTechStop = (
    index: number,
    firstLeg: LegFormData,
    secondLeg: LegFormData,
  ) => {
    const keys = Object.keys(firstLeg)
    for (const key of keys) {
      setValue(`legs.${index}.${key}`, firstLeg[key])
    }

    insert(index + 1, { ...secondLeg })
  }

  const handleAddNewLeg = () => {
    const lastItem = fields.length ? fields[fields.length - 1] : null

    const getDepartureDateTime = (lastItem: LegFormData) => {
      if (!lastItem.arrivalDate || !lastItem.arrivalTime) {
        return null
      }

      const departureDateTime = getCombinedLocalDate(
        lastItem.arrivalDate,
        lastItem.arrivalTime,
      )

      return dayjs(departureDateTime)
        .add(
          lastItem.aircraft?.turnaround_before_empty_leg_in_minutes ?? 0,
          'minutes',
        )
        .toDate()
    }

    const departureDateTime = lastItem ? getDepartureDateTime(lastItem) : null

    append({
      type: LegTypes.Empty,
      departureDate: departureDateTime ?? null,
      departureTime: departureDateTime ?? null,
      departureAirport: lastItem?.arrivalAirport ?? null,
      arrivalDate: null,
      arrivalTime: null,
      arrivalAirport: null,
      passengerCount: 0,
      aircraft: aircraft,
    })
  }

  const [isInBookedChangeMode, setIsInBookedChangeMode] =
    useState<boolean>(false)
  const handleChangeClick = () => {
    setIsInBookedChangeMode(true)
  }

  const handleSubmitClick = async (values: LegEditorFormData) => {
    const transformedValues = getLegEditorTransformedData(values, {
      timeDisplay,
    })

    try {
      await warningsSchema.validate(values, { abortEarly: false })

      onSubmit(transformedValues)
      setIsInBookedChangeMode(false)
    } catch (error) {
      const warnings = getFieldErrorsFromException(error)

      onSubmit(transformedValues, warnings)
    }
  }

  // @todo Jakub Menda - Why does this return different result than formState.isDirty ???
  const isChanged = Object.keys(formState.dirtyFields).length > 0

  const onCompareButtonClick = () => {
    if (!avinodeId) {
      console.warn(
        `'onCompareButtonClick' should only be invoked when 'avinodeId' is present instead got '${avinodeId}'`,
      )

      return
    }

    window.open(
      getAvinodeCompareUrl(GATSBY_BASE_AVINODE_URL as string, avinodeId),
      '_blank',
    )
  }

  // Looks for inserts because of split leg
  // The reason why it's not event driven is that react-hook-form only allows one useFieldArray action call per render
  // see https://react-hook-form.com/api/usefieldarray
  useEffect(() => {
    if (readonly) {
      return
    }

    fields.forEach((field, index) => {
      if (index + 1 === fields.length) {
        return
      }

      const isNextLegSplitLeg = fields[index + 1].isSplitLeg

      const isNextLegDepartureAirportDifferentFromCurrentArrival =
        fields[index + 1].departureAirport?.id &&
        field.arrivalAirport?.id !== fields[index + 1].departureAirport?.id

      if (
        isNextLegSplitLeg &&
        isNextLegDepartureAirportDifferentFromCurrentArrival
      ) {
        const updatedField = produce<Partial<(typeof fields)[0]>>(
          fields[index],
          (draft) => {
            delete draft.id
            draft.arrivalDate = null
            draft.arrivalTime = null
            draft.arrivalAirport = fields[index + 1].departureAirport
          },
        )

        update(index, updatedField)
      }
    })
  }, [fields.map((field) => field.id).join(), readonly])

  useEffect(() => {
    reset(getEditorDefaultValues(legs, aircraft, { timeDisplay }))
  }, [legs, timeDisplay])

  useEffect(() => {
    setTimeout(() => {
      trigger()
    }, 0)
  }, [legs, schedule])

  useEffect(() => {
    onIsDirtyChange?.(isChanged)
  }, [isChanged])

  useEffect(() => {
    if (
      !earliestDepartureDateTime ||
      !latestArrivalDateTime ||
      dayjs(latestArrivalDateTime).isBefore(earliestDepartureDateTime)
    ) {
      return
    }

    const from = dayjs
      .utc(earliestDepartureDateTime)
      .subtract(aircraft.time_in_destination_in_minutes, 'minutes')
      .toISOString()

    const to = dayjs
      .utc(latestArrivalDateTime)
      .add(aircraft.time_in_destination_in_minutes, 'minutes')
      .toISOString()

    onRequestTimeBoundariesChange(from, to)
  }, [earliestDepartureDateTime, latestArrivalDateTime])

  useEffect(() => {
    onLegEditorErrorsChange?.(formState.errors)
  }, [formState.errors])

  return (
    <div className={className}>
      <form onSubmit={handleSubmit(handleSubmitClick)}>
        {fields.map((field, index) => (
          <ControlledLegFieldGroup
            offer={offer}
            key={field.id}
            index={index}
            control={control}
            readonly={
              legValues[index].type === LegTypes.Removed ||
              (isBookedChange ? !isInBookedChangeMode : readonly)
            }
            extras={field.extras}
            errors={getErrorsForCurrentLeg(formState.errors, index)}
            warnings={getErrorsForCurrentLeg(warnings, index)}
            isDirty={!!formState.dirtyFields?.legs?.[index]}
            trigger={trigger}
            onDeleteClick={() => remove(index)}
            onSplitLegClick={(airport) => handleSplitLegClick(index, airport)}
            leg={legValues[index]}
            onlyDateTimeChange={isBookedChange}
            handleSplitLegByTechStop={handleSplitLegByTechStop}
            updateLegFromCalculations={updateLegFromCalculations}
            offerRelatedCustomRoutes={offerRelatedCustomRoutes}
          />
        ))}

        <Box display="flex" justifyContent="space-between">
          <Box display="flex">
            {avinodeId && (
              <Button inverted onClick={onCompareButtonClick}>
                {t('organisms.LegEditorForm.compareWithAvinodeButtonLabel')}
              </Button>
            )}
            {!!onShowMapButtonClick && (
              <StyledButton
                $isAlone={!avinodeId}
                inverted
                onClick={onShowMapButtonClick}
              >
                {t('organisms.LegEditorForm.showmapButtonlabel')}
              </StyledButton>
            )}
          </Box>

          <Box display="flex">
            {isBookedChange && (
              <>
                <Box mr="1rem">
                  <Button
                    inverted
                    disabled={isInBookedChangeMode}
                    loading={loading}
                    onClick={handleChangeClick}
                    data-testid="LegEditorForm__change-button"
                  >
                    {t('organisms.LegEditorForm.change')}
                  </Button>
                </Box>

                <Box mr="1rem">
                  <Button
                    loading={loading}
                    disabled={!isChanged || fields.length === 0}
                    type="submit"
                    data-testid="LegEditorForm__change_confirm"
                  >
                    {t('organisms.LegEditorForm.change_confirm')}
                  </Button>
                </Box>

                <Button
                  loading={loading}
                  disabled={
                    isInBookedChangeMode
                      ? false
                      : !isChanged || fields.length === 0
                  }
                  onClick={() => {
                    if (isChanged) {
                      reset(
                        getEditorDefaultValues(legs, aircraft, { timeDisplay }),
                      )
                    }
                    setIsInBookedChangeMode(false)
                  }}
                  data-testid="LegEditorForm__change_cancel"
                >
                  {t('organisms.LegEditorForm.change_cancel')}
                </Button>
              </>
            )}

            {areCommonButtonsVisible && (
              <>
                <Box mr="1rem">
                  <Button
                    inverted
                    disabled={readonly}
                    onClick={handleAddNewLeg}
                    data-testid="LegEditorForm__add-leg-button"
                  >
                    {t('organisms.LegEditorForm.addNewLeg')}
                  </Button>
                </Box>

                <Box mr="1rem">
                  <Button
                    loading={loading}
                    disabled={!isChanged || fields.length === 0}
                    type="submit"
                    data-testid="LegEditorForm__submit-button"
                  >
                    {t('organisms.LegEditorForm.submit')}
                  </Button>
                </Box>

                <Button
                  disabled={!isChanged || fields.length === 0}
                  onClick={() =>
                    reset(
                      getEditorDefaultValues(legs, aircraft, { timeDisplay }),
                    )
                  }
                  data-testid="LegEditorForm__reset-button"
                >
                  {t('organisms.LegEditorForm.reset')}
                </Button>
              </>
            )}
          </Box>
        </Box>
      </form>
    </div>
  )
}

const StyledButton = styled(Button)<{ $isAlone: boolean }>`
  margin-left: ${({ $isAlone }) => ($isAlone ? '0' : '16px')};
`

export default LegEditorForm
