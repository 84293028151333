export enum OtherCostUnits {
  FlatFee = 'flat-fee',
  Hourly = 'hourly',
}

export type OtherCost = {
  unit: OtherCostUnits
  value: number
  label: string
}

export type OtherCosts = OtherCost[]
