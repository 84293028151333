import L from 'leaflet'

// workaround around gatsby prerendered HTML where leaflet need 'window'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AirportIcon: any = () =>
  !!L && !!L.Icon
    ? new L.Icon.Default({
        imagePath: '/images/',
      })
    : {}
