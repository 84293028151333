import React, {
  ChangeEvent,
  PropsWithChildren,
  ReactElement,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import { Control, FieldError } from 'react-hook-form'

import IconButton from '@material-ui/core/IconButton'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import FindReplace from '@material-ui/icons/FindReplace'

import Checkbox from '@app/components/atoms/Checkbox/Checkbox'

import ConfirmationDialog from '@app/components/molecules/ConfirmationDialog/ConfirmationDialog'
import Box from '@material-ui/core/Box'
import Typography from '@app/components/atoms/Typography/Typography'
import TextField from '@app/components/atoms/TextField/TextField'
import LegInfo from '@app/components/molecules/LegInfo/LegInfo'
import Select from '@app/components/atoms/Select/Select'
import RangeIndicator from '@app/components/atoms/RangeIndicator/RangeIndicator'
import Dialog from '@app/components/atoms/Dialog/Dialog'
import LegEditorContextMenu from '@app/components/organisms/LegEditorForm/LegEditorContextMenu/LegEditorContextMenu'
import SingleDatePicker from '@app/components/atoms/SingleDatePicker/SingleDatePicker'
import TimePicker from '@app/components/atoms/TimePicker/TimePicker'
import ErrorTooltip from '@app/components/atoms/Tooltip/ErrorTooltip'

import { DisplayTimeTypes, LegTypes, OfferStatuses } from '@shared/enums'
import { AirportDetailDto } from '@shared/dto/airports.dto'
import { AircraftDetailDto } from '@shared/dto/aircraft.dto'
import { getAircraftFlightRange } from '@shared/utils/getAircraftFlightRange'
import { OfferDto } from '@shared/dto/requests.dto'
import { getLegColor } from '@app/utils/stylingUtils'
import { useGetLegTypeText } from '@app/hooks/useGetLegTypeText'
import { useActiveUser } from '@app/hooks/useActiveUser'
import useGetFormattedDateBasedOnUserDisplaySettings from '@app/hooks/useGetFormattedDateBasedOnUserDisplaySettings'

import AirportPicker, {
  AirportBase,
  AirportPickerProps,
  AirportPickerVariants,
} from '@app/components/atoms/AirportPicker/AirportPicker'

import { getUTCFromTimezoneAwareDate } from '@app/utils/dateUtils'
import { Extras, LegFormData } from '@shared/interfaces/Leg'
import { AviaPagesMenu } from '@app/components/organisms/LegEditorForm/LegFlightRecalculate/AviaPagesMenu'
import {
  DatePickerContainer,
  LegPointContainer,
  LegPointRow,
} from '@app/components/organisms/LegEditorForm/components/Wrappers'
import { LegEditorFormData } from '@app/components/organisms/LegEditorForm/LegEditorForm'
import { CustomRouteDetailDto } from '@shared/dto/customRoutes.dto'
import { getArrivalDate } from '@app/components/organisms/LegEditorForm/LegEditorForm.utils'
import { useSelector } from 'react-redux'
import { selectSelectedOperator } from '@app/store/core/userOperators/userOperators.selectors'
import Tooltip from '@app/components/atoms/Tooltip/Tooltip'
import BaseTooltip from '@material-ui/core/Tooltip'
import AirportTooltip from '@app/components/molecules/AirportTooltip/AirportTooltip'
import { useAirportNote } from '@app/hooks/useAirportNote'

import ErrorIcon from '@material-ui/icons/Error'
import { icons } from '@app/components/pages/Fleet/Warnings/types'

interface FieldProps<ValueType = string | number | object> {
  name?: string
  value: ValueType
  onChange?: (nextValue: ValueType) => void
  onBlur?: () => void
  isDeleted?: boolean
}

export interface LegFieldGroupProps {
  groupType: 'cancelled' | 'optimized' | 'calculated'
  fields: {
    type: FieldProps<LegTypes>
    departureDate: FieldProps<Date | null>
    departureTime: FieldProps<Date | null>
    departureAirport: FieldProps<AirportBase | null | undefined>
    arrivalDate: FieldProps<Date | null>
    arrivalTime: FieldProps<Date | null>
    arrivalAirport: FieldProps<AirportBase | null | undefined>
    passengerCount: FieldProps<number>
    aircraft: FieldProps<AircraftDetailDto | null>
  }
  leg: LegFormData
  index: number
  extras?: Extras | null
  isDirty?: boolean
  isDeleted?: boolean
  readonly?: boolean
  offer: OfferDto
  control?: Control<LegEditorFormData>
  onDeleteClick?: (props: LegFieldGroupProps) => void
  onSplitLegClick?: (airport: AirportDetailDto) => void
  onIgnoreLegFromOptimizationSwitch?: (removeLegId: number) => void
  offerRelatedCustomRoutes: CustomRouteDetailDto[] | null
  warnings?: {
    [key: string]: FieldError
  }
  errors?: {
    [key: string]: FieldError
  }
  onlyDateTimeChange: boolean
  handleSplitLegByTechStop: (
    index: number,
    firstLeg: LegFormData,
    secondLeg: LegFormData,
  ) => void
  updateLegFromCalculations: (index: number, leg: LegFormData) => void
}

const LegFieldGroup = (props: LegFieldGroupProps): JSX.Element => {
  const {
    groupType,
    fields: {
      departureDate,
      departureTime,
      departureAirport,
      arrivalDate,
      arrivalTime,
      arrivalAirport,
      passengerCount,
      aircraft,
      type,
    },
    leg,
    offer,
    index,
    extras,
    isDirty,
    isDeleted,
    errors,
    warnings,
    readonly,
    onDeleteClick,
    onSplitLegClick,
    onIgnoreLegFromOptimizationSwitch,
    onlyDateTimeChange = false,
    handleSplitLegByTechStop,
    updateLegFromCalculations,
    offerRelatedCustomRoutes,
  } = props

  const { t } = useTranslation()
  const theme = useTheme()
  const getLegTypeText = useGetLegTypeText()
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false)
  const [isOptimizationModalOpen, setIsOptimizationModalOpen] = useState(false)
  const activeUser = useActiveUser()
  const getReverseDate = useGetFormattedDateBasedOnUserDisplaySettings(true)
  const [infoModalOpen, setInfoModalOpen] = useState(false)
  const operator = useSelector(selectSelectedOperator)

  const aircraftFlightRange = getAircraftFlightRange(
    passengerCount.value,
    aircraft.value?.flight_range,
  )
  const ignoreOptimisation =
    offer.status !== OfferStatuses.New &&
    offer.status !== OfferStatuses.Unhandled &&
    offer.status !== OfferStatuses.Draft &&
    offer.status !== OfferStatuses.Quoted &&
    offer.status !== OfferStatuses.Booked &&
    offer.status !== OfferStatuses.BookedCancelled

  const isContextMenuAvailable =
    onlyDateTimeChange &&
    type.value !== LegTypes.Empty &&
    type.value !== LegTypes.Ferry

  const [departureDateChanged, setDepartureDateChanged] = useState(false)
  const [departureTimeChanged, setDepartureTimeChanged] = useState(false)

  const legTypeText = getLegTypeText(type.value)
  useMemo(() => {
    if (isDeleted) {
      return t('molecules.LegFieldGroup.LegInfoIsDeletedTooltip')
    }

    if (isDirty) {
      return t('molecules.LegFieldGroup.LegInfoIsDirtyTooltip')
    }
  }, [isDirty, isDeleted])
  const handleDepartureAirportChange: AirportPickerProps['onChange'] = (
    event,
    value,
  ) => {
    if (typeof value === 'string' || Array.isArray(value)) {
      return
    }

    departureAirport.onChange?.(value)
    arrivalTime.onChange?.(null)
    arrivalDate.onChange?.(null)
  }

  const handleArrivalAirportChange: AirportPickerProps['onChange'] = (
    event,
    value,
  ) => {
    if (typeof value === 'string' || Array.isArray(value)) {
      return
    }

    arrivalAirport.onChange?.(value)
    arrivalTime.onChange?.(null)
    arrivalDate.onChange?.(null)
  }

  const openInfoModalState = () => {
    setInfoModalOpen(true)
  }

  const closeInfoModalState = () => {
    setInfoModalOpen(false)
  }

  const setMakeFerryMakeEmpty = (curLegType: LegTypes) => {
    if (curLegType === LegTypes.Empty) {
      type.onChange?.(LegTypes.Ferry)
    } else {
      type.onChange?.(LegTypes.Empty)
      passengerCount.onChange?.(0)
    }

    setIsContextMenuOpen(false)
    closeInfoModalState()
  }

  const shouldOpenWarning = (offerStatus: OfferStatuses) => {
    return [
      OfferStatuses.Quoted,
      OfferStatuses.Booked,
      OfferStatuses.BookedCancelled,
    ].includes(offerStatus)
  }

  const handlePassengerCountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const nextPassengerCount =
      event.target.value === '' ? NaN : Number(event.target.value)

    passengerCount.onChange?.(nextPassengerCount)

    if (nextPassengerCount === 0 && type.value === LegTypes.Occupied) {
      type.onChange?.(LegTypes.Empty)
    }

    if (nextPassengerCount > 0 && type.value !== LegTypes.Occupied) {
      type?.onChange?.(LegTypes.Occupied)
    }
  }

  const handleDepartureDateChange = (nextValue: Date | null) => {
    departureDate.onChange?.(nextValue)
    setDepartureDateChanged(true)

    if (!nextValue) {
      return
    }

    if (departureTimeChanged) {
      arrivalDate.onChange?.(null)
    } else {
      if (!nextValue || !arrivalDate.value || !arrivalTime.value) {
        return
      }

      const nextArrivalDate = getArrivalDate(
        nextValue,
        nextValue,
        leg.departureAirport?.timezone ?? '',
        leg.arrivalAirport?.timezone ?? '',
        activeUser?.display_time_type ?? DisplayTimeTypes.UTC,
        leg?.extras?.duration_in_minutes ?? 0,
      )

      arrivalDate.onChange?.(nextArrivalDate)
    }
  }

  const handleDepartureTimeChange = (nextValue: Date | null) => {
    departureTime.onChange?.(nextValue)
    setDepartureTimeChanged(true)

    if (departureDateChanged) {
      arrivalDate.onChange?.(null)
    } else {
      if (
        !nextValue ||
        !departureDate.value ||
        !arrivalDate.value ||
        !arrivalTime.value
      ) {
        return
      }

      const nextArrivalDate = getArrivalDate(
        nextValue,
        nextValue,
        leg?.departureAirport?.timezone ?? '',
        leg.arrivalAirport?.timezone ?? '',
        activeUser?.display_time_type ?? DisplayTimeTypes.UTC,
        leg?.extras?.duration_in_minutes ?? 0,
      )

      arrivalTime.onChange?.(nextArrivalDate)
      arrivalDate.onChange?.(nextArrivalDate)
    }
  }

  const handleDeleteClick = () => {
    onDeleteClick?.(props)

    setIsContextMenuOpen(false)
  }

  const handleSplitLeg = (airport: AirportDetailDto) => {
    onSplitLegClick?.(airport)

    setIsContextMenuOpen(false)
  }

  const getReverseDateTooltip = (
    date: Date | null,
    time: Date | null,
    airport: LegFormData['departureAirport'] | LegFormData['arrivalAirport'],
    displayTimeType: DisplayTimeTypes = DisplayTimeTypes.UTC,
  ) => {
    if (!date || !time || !airport) {
      return undefined
    }

    const reverseDateTime = getReverseDate(
      getUTCFromTimezoneAwareDate(
        date,
        time,
        airport.timezone,
        displayTimeType,
      ),
      airport.timezone,
    ).dateTime

    return displayTimeType === DisplayTimeTypes.AirportLocalTime
      ? t('general.tooltips.UTCDate', { date: reverseDateTime })
      : t('general.tooltips.LocalDate', { date: reverseDateTime })
  }

  const departureDateTooltip = getReverseDateTooltip(
    departureDate.value,
    departureTime.value,
    props.leg?.departureAirport,
    activeUser?.display_time_type ?? undefined,
  )

  const arrivalDateTooltip = getReverseDateTooltip(
    arrivalDate.value,
    arrivalTime.value,
    props.leg?.arrivalAirport,
    activeUser?.display_time_type ?? undefined,
  )

  const isCancellationLeg =
    type.value === LegTypes.Removed &&
    extras?.remove_leg?.offer_id === offer?.cancellation_offer_id

  const isRebookingLeg =
    type.value === LegTypes.Removed &&
    extras?.remove_leg?.offer_id === offer?.rebooking_offer_id

  const isLegRemovedFromActiveOffer = !extras
    ? // @todo What to do if 'extras' don't exist ???
      // @todo When can 'extras' not exist ???
      type.value === LegTypes.Removed
    : type.value === LegTypes.Removed && !extras.remove_leg_id

  const isOptimizationAvailable =
    (offer.status === OfferStatuses.New ||
      offer.status === OfferStatuses.Unhandled ||
      offer.status === OfferStatuses.Draft) &&
    type.value !== LegTypes.Removed

  const relevantCustomRoute = offerRelatedCustomRoutes?.find(
    (customRoute) =>
      (aircraft?.value?.id === null ||
        aircraft?.value?.id === customRoute.aircraft_id) &&
      leg.departureAirport?.id === customRoute.departure_airport_id &&
      leg.arrivalAirport?.id === customRoute.arrival_airport_id,
  )

  return (
    <Container>
      <LegPointContainer>
        <LegPointRow>
          <HeadingContainer>
            <Heading $legType={type.value} $offerStatus={offer.status}>
              {t('molecules.LegFieldGroup.heading', { index: index + 1 })}
            </Heading>
            <LegType>{legTypeText}</LegType>
          </HeadingContainer>
          <FieldWithError
            error={errors?.departureAirport}
            warning={warnings?.departureAirport}
          >
            <AirportWithTooltip airport={leg.departureAirport}>
              <div>
                <StyledAirportPicker
                  variant={AirportPickerVariants.Condensed}
                  value={departureAirport.value ?? null}
                  onChange={handleDepartureAirportChange}
                  onBlur={departureAirport.onBlur}
                  disabled={onlyDateTimeChange || readonly}
                  error={!!errors?.departureAirport}
                  warning={!!warnings?.departureAirport}
                  inputProps={{
                    'data-testid': `LegEditorForm-LegFieldGroup[${index}]__departure-airport-input`,
                  }}
                />
              </div>
            </AirportWithTooltip>
          </FieldWithError>
        </LegPointRow>
        <LegPointRow title={departureDateTooltip}>
          <FieldWithError
            error={errors?.departureDate}
            warning={warnings?.departureDate}
          >
            <DatePickerContainer>
              <SingleDatePicker
                size="normal"
                TextFieldProps={{
                  name: departureDate.name,
                  error: !!errors?.departureDate,
                  warning: !!warnings?.departureDate,
                  disabled: readonly,
                  inputProps: {
                    'data-testid': `LegEditorForm-LegFieldGroup[${index}]__departure-date-input`,
                  },
                }}
                value={departureDate.value}
                onChange={handleDepartureDateChange}
                disabled={readonly}
                disablePast={false}
              />
            </DatePickerContainer>
          </FieldWithError>
          <FieldWithError
            error={errors?.departureTime}
            warning={warnings?.departureTime}
          >
            <div>
              <StyledTimePicker
                name={String(departureTime.name)}
                value={departureTime.value}
                onChange={handleDepartureTimeChange}
                onBlur={departureTime.onBlur}
                disabled={readonly}
                error={!!errors?.departureTime}
                warning={!!warnings?.departureDate}
                data-testid={`LegEditorForm-LegFieldGroup[${index}]__departure-time-input`}
              />
            </div>
          </FieldWithError>
        </LegPointRow>
      </LegPointContainer>
      <LegInfoContainer>
        <LegInfo
          extras={extras}
          disabled={Boolean(isDirty)}
          relevantCustomRoute={relevantCustomRoute}
        />
      </LegInfoContainer>
      <LegPointContainer>
        <LegPointRow>
          <FieldWithError
            error={errors?.arrivalAirport}
            warning={warnings?.arrivalAirport}
          >
            <Box display="flex" flexDirection="row" alignItems="center">
              <AirportWithTooltip airport={leg.arrivalAirport}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="row"
                >
                  <StyledAirportPicker
                    variant={AirportPickerVariants.Condensed}
                    value={arrivalAirport.value ?? null}
                    onChange={handleArrivalAirportChange}
                    onBlur={arrivalAirport.onBlur}
                    disabled={onlyDateTimeChange || readonly}
                    error={!!errors?.arrivalAirport}
                    warning={!!warnings?.arrivalAirport}
                    inputProps={{
                      'data-testid': `LegEditorForm-LegFieldGroup[${index}]__arrival-airport-input`,
                    }}
                  />
                </Box>
              </AirportWithTooltip>
              {leg.isCabotage && (
                <Tooltip title={t('pages.Request.cabotage.tooltip')}>
                  <ErrorIcon color="error" />
                </Tooltip>
              )}

              {leg.warnings && leg.warnings.length > 0 && (
                <>
                  {leg.warnings.map((curWarning, index) => (
                    <Tooltip
                      key={`leg-warning-${index}`}
                      title={curWarning.note}
                    >
                      <div style={{ color: curWarning.color }}>
                        {
                          icons.find((icon) => icon.value === curWarning.icon)
                            ?.component
                        }
                      </div>
                    </Tooltip>
                  ))}
                </>
              )}
            </Box>
          </FieldWithError>
        </LegPointRow>
        <LegPointRow title={arrivalDateTooltip}>
          <FieldWithError
            error={errors?.arrivalDate}
            warning={warnings?.arrivalDate}
          >
            <DatePickerContainer>
              <SingleDatePicker
                size="normal"
                TextFieldProps={{
                  name: arrivalDate.name,
                  error: !!errors?.arrivalDate,
                  warning: !!warnings?.arrivalDate,
                  disabled: readonly,
                  inputProps: {
                    'data-testid': `LegEditorForm-LegFieldGroup[${index}]__arrival-date-input`,
                  },
                }}
                value={arrivalDate.value}
                onChange={arrivalDate.onChange}
                disabled={readonly}
                disablePast={false}
              />
            </DatePickerContainer>
          </FieldWithError>
          <FieldWithError
            error={errors?.arrivalTime}
            warning={warnings?.arrivalTime}
          >
            <div>
              <StyledTimePicker
                name={String(arrivalTime.name)}
                value={arrivalTime.value}
                onChange={arrivalTime.onChange}
                onBlur={arrivalTime.onBlur}
                disabled={readonly}
                error={!!errors?.arrivalTime}
                warning={!!warnings?.arrivalDate}
                data-testid={`LegEditorForm-LegFieldGroup[${index}]__arrival-time-input`}
              />
            </div>
          </FieldWithError>
        </LegPointRow>
      </LegPointContainer>
      <PaxFieldContainer>
        <FieldWithError
          error={errors?.passengerCount}
          warning={warnings?.passengerCount}
        >
          <PaxField
            label={t('molecules.LegFieldGroup.passengerCountLabel')}
            type="number"
            name={String(passengerCount.name)}
            value={passengerCount.value}
            onChange={handlePassengerCountChange}
            onBlur={passengerCount.onBlur}
            disabled={onlyDateTimeChange || readonly}
            error={!!errors?.passengerCount}
            warning={!!warnings?.passengerCount}
            inputProps={{
              min: 0,
              'data-testid': `LegEditorForm-LegFieldGroup[${index}]__passenger-count-input`,
            }}
          />
        </FieldWithError>
      </PaxFieldContainer>
      <AircraftSelectContainer>
        <LabelContainer>
          <AircraftSelectLabel>
            {t('molecules.LegFieldGroup.aircraftSelectLabel')}
          </AircraftSelectLabel>
          {!!aircraftFlightRange && !!extras?.distance_in_nautical_miles && (
            <RangeIndicator
              max={aircraftFlightRange.flight_range_in_nautical_miles}
              value={extras.distance_in_nautical_miles}
              isDisabled={onlyDateTimeChange || readonly}
              tooltipText={t('molecules.LegFieldGroup.rangeIndicatorTooltip', {
                percentage: Math.ceil(
                  (extras.distance_in_nautical_miles /
                    aircraftFlightRange.flight_range_in_nautical_miles) *
                    100,
                ),
              })}
            />
          )}
        </LabelContainer>
        <AircraftSelect<AircraftDetailDto | null, boolean, boolean, boolean>
          disabled
          value={aircraft.value}
          options={[aircraft.value]}
          onBlur={aircraft.onBlur}
          getOptionLabel={(row: AircraftDetailDto | null) =>
            row?.registration_code ?? ''
          }
          inputProps={{
            'data-testid': `LegEditorForm-LegFieldGroup[${index}]__aircraft-input`,
          }}
        />
      </AircraftSelectContainer>
      <StyledSideButtons>
        {isOptimizationAvailable && (
          <BaseTooltip
            title={
              !operator?.is_aviapages_calculator_enabled
                ? t('molecules.LegFieldGroup.flightTimeOptimizerNotEnabled')
                : t('molecules.LegFieldGroup.flightTimeOptimizerEnabled')
            }
            placement="top"
          >
            <span>
              <StyledIconButton
                onClick={() => setIsOptimizationModalOpen(true)}
                disabled={!operator?.is_aviapages_calculator_enabled ?? false}
              >
                <FindReplace />
              </StyledIconButton>
            </span>
          </BaseTooltip>
        )}
        {type.value === LegTypes.Removed &&
        Boolean(extras?.remove_leg_id) &&
        onIgnoreLegFromOptimizationSwitch &&
        groupType === 'optimized' ? (
          <StyledCheckbox
            onChange={() => {
              onIgnoreLegFromOptimizationSwitch(extras!.remove_leg_id!)
            }}
          />
        ) : (
          <StyledTooltip
            interactive
            open={isContextMenuOpen}
            onClose={() => setIsContextMenuOpen(false)}
            backgroundColor={theme.palette.common.white}
            borderColor={theme.palette.grey[300]}
            placement="right-start"
            title={
              <LegEditorContextMenu
                onDeleteClick={onDeleteClick ? handleDeleteClick : undefined}
                onSplitLegClick={onSplitLegClick ? handleSplitLeg : undefined}
                onMakeFerryClick={
                  type.value === LegTypes.Empty
                    ? shouldOpenWarning(offer.status)
                      ? openInfoModalState
                      : () => setMakeFerryMakeEmpty(type.value)
                    : undefined
                }
                onMakeEmptyClick={
                  type.value === LegTypes.Ferry
                    ? shouldOpenWarning(offer.status)
                      ? openInfoModalState
                      : () => setMakeFerryMakeEmpty(type.value)
                    : undefined
                }
              />
            }
          >
            <StyledIconButton
              disabled={
                isContextMenuAvailable ||
                isCancellationLeg ||
                isRebookingLeg ||
                isLegRemovedFromActiveOffer ||
                ignoreOptimisation
              }
              onClick={() => setIsContextMenuOpen(true)}
              data-testid={`LegEditorForm-LegFieldGroup[${index}]__context-menu-icon`}
            >
              <MoreHorizIcon />
            </StyledIconButton>
          </StyledTooltip>
        )}
      </StyledSideButtons>

      <ConfirmationDialog
        onClose={() => closeInfoModalState()}
        onCancel={() => closeInfoModalState()}
        onConfirm={() => {
          setMakeFerryMakeEmpty(type.value)
        }}
        open={infoModalOpen}
        dialogContent={
          <Box>
            <Typography variant="formTitle">
              {t('molecules.LegEditorContextMenu.makeEmptyFerryWarningHeader')}
            </Typography>
            <Typography>
              {t('molecules.LegEditorContextMenu.makeEmptyFerryWarningBody')}
            </Typography>
          </Box>
        }
      />
      <StyledDialog
        open={isOptimizationModalOpen}
        onClose={() => setIsOptimizationModalOpen(false)}
        disableEnforceFocus
      >
        <AviaPagesMenu
          leg={leg}
          closeMenu={() => setIsOptimizationModalOpen(false)}
          control={props.control}
          index={props.index}
          handleSplitLegByTechStop={handleSplitLegByTechStop}
          updateLegFromCalculations={updateLegFromCalculations}
        />
      </StyledDialog>
    </Container>
  )
}

const AirportWithTooltip = ({
  airport,
  children,
}: PropsWithChildren<{ airport?: Partial<AirportDetailDto> | null }>) => {
  const getAirportNote = useAirportNote()

  const extras = useMemo(() => {
    if ('extras' in (airport ?? {})) {
      const legExtra = airport as unknown as AirportDetailDto
      return legExtra.extras
    }
    return undefined
  }, [airport])

  if (
    !airport ||
    !extras ||
    !airport.icao_code ||
    !airport.iata_code ||
    !airport.country ||
    !airport.city ||
    typeof airport.timezone_offset_in_minutes !== 'number'
  ) {
    return <>{children}</>
  }

  return (
    <Tooltip
      title={
        <AirportTooltip
          airportIcao={airport.icao_code}
          airportIata={airport.iata_code}
          airportCountry={airport.country}
          airportCity={airport.city}
          airportRunways={extras?.runways}
          timezoneOffsetInMinutes={airport.timezone_offset_in_minutes}
          note={getAirportNote(airport.icao_code)}
        />
      }
    >
      {children as ReactElement}
    </Tooltip>
  )
}

const FieldWithError = ({
  error,
  warning,
  children,
}: {
  error?: FieldError
  warning?: FieldError
  children: ReactElement
}): JSX.Element => {
  const theme = useTheme()

  return (
    <ErrorTooltip
      backgroundColor={
        error ? theme.palette.error.main : theme.palette.warning.main
      }
      title={error?.message ?? warning?.message}
    >
      {children}
    </ErrorTooltip>
  )
}
const StyledDialog = styled(Dialog)`
  .Dialog__paper {
    padding: 0;
    min-width: 50rem;
    min-height: 20rem;
    max-width: none;
    display: flex;
    flex-direction: column;
  }
`

const StyledSideButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 6px 0;
`

const StyledTooltip = styled(Tooltip)``

const Container = styled.div`
  display: flex;
  padding: 1rem 0;
`

const LegInfoContainer = styled.div`
  flex-grow: 1;
`

const HeadingContainer = styled.div`
  display: flex;
  padding: 2px 4px;
  width: 8rem;
  line-height: 1rem;
`

const Heading = styled.span<{
  $offerStatus: OfferStatuses
  $legType: LegTypes
}>`
    font-weight: bold;
    display: inline-block;
    flex-shrink: 0;

    &:after {
        content: '';
        display: inline-block;
        width: 100%;
        height: 4px;
        border-radius: 2px;
        position: relative;
        bottom: 0.4rem;
        background: ${({ theme, $offerStatus, $legType }) =>
          getLegColor(theme, $legType, $offerStatus)};
`

const StyledAirportPicker = styled(AirportPicker)`
  padding: 2px 4px;
  width: 6rem;
  box-sizing: border-box;

  &.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot,
  .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: 0;
  }
`

const StyledTimePicker = styled(TimePicker)`
  padding: 2px 4px;
  width: 6rem;
`

const LegType = styled.span`
  margin-left: 5px;
  color: ${({ theme }) => theme.palette.grey[400]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const PaxFieldContainer = styled.div`
  align-self: flex-end;
`

const PaxField = styled(TextField)`
  padding: 2px 6px;
  width: 5rem;
`

const AircraftSelectContainer = styled.div`
  width: 8rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
`

const AircraftSelect = styled(Select)`
  padding: 2px 6px;

  &.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot,
  &.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: 0;
  }
` as typeof Select

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`

const AircraftSelectLabel = styled.label`
  padding: 2px 6px 0 6px;
  color: ${({ theme }) => theme.palette.grey[800]};
`

const StyledIconButton = styled(IconButton)`
  padding: 0.25rem;
`
const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 8px;
`

export default LegFieldGroup
