import { kmsToNauticalMiles } from '@shared/utils/aviaCalculationsUtils'
import dayjs from 'dayjs'
import { SEMANTIC_DURATION_FORMAT } from '@shared/constants'
import React from 'react'
import styled from 'styled-components'
import FlightRouteIcon from '@app/components/atoms/FlightRouteIcon/FlightRouteIcon'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'

interface Props {
  distanceInKm: number
  time: number
}

export const LegTimeDistanceLegend = ({ distanceInKm, time }: Props) => {
  const { t } = useTranslation()

  const distanceUnit = 'NM'
  const distanceInNm = kmsToNauticalMiles(distanceInKm)

  return (
    <Container>
      <StyledTypography>
        {dayjs().startOf('day').minute(time).format(SEMANTIC_DURATION_FORMAT)}
      </StyledTypography>
      <IconContainer>
        <StyledFlightRouteIcon disabled={false} timeAndDistance />
      </IconContainer>
      <StyledTypography>
        {t('atoms.LegInfo.flightDistance', {
          distance: distanceInNm,
          unit: distanceUnit,
        })}
      </StyledTypography>
    </Container>
  )
}

const Container = styled.div`
  min-height: 100%;
  min-width: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
  cursor: pointer;
`

const StyledTypography = styled(Typography)`
  align-self: center;
  white-space: nowrap;
`

const StyledFlightRouteIcon = styled(FlightRouteIcon)`
  align-self: stretch;
`

const IconContainer = styled.div`
  width: 100%;
`
