import { RootState } from '@app/store'

export const selectIsPostClientLoading = (store: RootState) =>
  store.api.clients.isPostClientLoading

export const selectPostClientError = (store: RootState) =>
  store.api.clients.postClientError

export const selectCreatedClientId = (store: RootState) =>
  store.api.clients.createdClientId
