import { LegDetailDto } from '@shared/dto/requests.dto'
import type { LatLngTuple } from 'leaflet'

export const ROOT_SELECTOR = 'requestmap-root'

export interface Destination {
  latlng: LatLngTuple
  icao: string
  name: string
}

export interface LegData {
  direction: 'left' | 'right'
  from: Destination
  to: Destination
  text: string
  legId: LegDetailDto['id']
}
