import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import Button from '@app/components/atoms/Button/Button'
import Select from '@app/components/atoms/Select/Select'
import Typography from '@app/components/atoms/Typography/Typography'
import { TextFieldInputLabel } from '@app/components/atoms/TextField/TextField'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import { UserDto } from '@shared/dto/user.dto'
import { UpdateUserDisplaySettingsDto } from '@app/utils/api/types'

import {
  DisplayAirportIdentifications,
  DisplayDateFormats,
  DisplayTimeTypes,
  DisplayTimeFormats,
  DisplayWeekStartDays,
} from '@shared/enums'

import {
  transformFormValuesToUser,
  transformUserToFormValues,
} from '@app/components/organisms/UserPanel/UserSettingsForm/UserSettingsForm.transformer'
import { useSelector } from 'react-redux'
import { selectSelectedOperator } from '@app/store/core/userOperators/userOperators.selectors'
import Switch from '@app/components/atoms/Switch/Switch'

interface SelectOption<T = string> {
  id: T
  label: string
}

export interface UserSettingsFormData {
  currency: string | null
  saveFilters: boolean
  weekStart: DisplayWeekStartDays | null
  dateFormat: DisplayDateFormats | null
  timeFormat: DisplayTimeFormats | null
  timeDisplay: DisplayTimeTypes | null
  airportsIdentification: DisplayAirportIdentifications | null
}

interface SettingsTabContentProps {
  user: UserDto
  isLoading: boolean
  onSubmit: (userSettings: UpdateUserDisplaySettingsDto) => void
  onError?: () => void
}

const UserSettingsForm = ({
  user,
  isLoading,
  onSubmit,
  onError,
}: SettingsTabContentProps): JSX.Element => {
  const { t } = useTranslation()

  const { handleSubmit, control, formState, reset } =
    useForm<UserSettingsFormData>({
      defaultValues: transformUserToFormValues(user),
    })

  const selectedOperator = useSelector(selectSelectedOperator)

  const currencyOptions = [
    {
      id: selectedOperator!.currency!.id.toString(),
      label: `${selectedOperator?.currency?.name} (${selectedOperator?.currency?.symbol})`,
    },
  ]

  const weekStartOptions = Object.values(DisplayWeekStartDays).map(
    (option) => ({
      id: option,
      // t('enums.weekStartDays.Monday')
      // t('enums.weekStartDays.Sunday')
      label: t(`enums.weekStartDays.${option}`),
    }),
  )

  const dateFormatOptions = Object.entries(DisplayDateFormats).map(
    ([name, format]) => ({
      id: format,
      // t('enums.dateFormats.Czech')
      // t('enums.dateFormats.British')
      // t('enums.dateFormats.American')
      label: t(`enums.dateFormats.${name}`, {
        formattedDate: dayjs().format(format),
        interpolation: { escapeValue: false },
      }),
    }),
  )

  const timeFormatOptions = Object.entries(DisplayTimeFormats).map(
    ([name, format]) => ({
      id: format,
      // t('enums.timeFormats.TwelveHours')
      // t('enums.timeFormats.TwentyFourHours')
      label: t(`enums.timeFormats.${name}`, {
        formattedTime: dayjs().format(format),
      }),
    }),
  )

  const timeDisplayOptions = Object.values(DisplayTimeTypes).map((option) => ({
    id: option,
    // t('enums.timeDisplay.AirportLocalTime')
    // t('enums.timeDisplay.UTC')
    label: t(`enums.timeDisplay.${option}`),
  }))

  const airportIdentificationOptions = Object.values(
    DisplayAirportIdentifications,
  ).map((option) => ({
    id: option,
    // t('enums.airportIdentifications.icao')
    // t('enums.airportIdentifications.iata')
    label: t(`enums.airportIdentifications.${option}`),
  }))

  useEffect(() => {
    reset(transformUserToFormValues(user))
  }, [user])

  return (
    <Box display="flex" justifyContent="space-between" height={1} width={1}>
      <StyledForm
        onSubmit={handleSubmit(
          (formValues) => onSubmit(transformFormValuesToUser(formValues)),
          onError,
        )}
      >
        <Box>
          <Typography variant="formTitle">
            {t('organisms.UserPanel.UserSettingsForm.heading')}
          </Typography>
          <Box display="flex" flexWrap="nowrap">
            <Box display="flex" flexDirection="column">
              <Box my={1}>
                <Controller
                  control={control}
                  name="currency"
                  render={({ field: { name, onChange, onBlur } }) => (
                    <StyledSmallSelect<SelectOption, false, true, false>
                      name={name}
                      label={t(
                        'organisms.UserPanel.UserSettingsForm.currencyLabel',
                      )}
                      value={currencyOptions[0]}
                      options={currencyOptions}
                      getOptionLabel={(option) => option.label}
                      disabled
                      onChange={(event, option) => {
                        event.stopPropagation()
                        onChange(option?.id)
                      }}
                      onBlur={onBlur}
                      disableClearable
                    />
                  )}
                />
              </Box>
              <Box my={1} display="flex" alignItems="center">
                <Controller
                  control={control}
                  name="saveFilters"
                  render={({ field: { name, value, onChange, onBlur } }) => (
                    <Switch
                      data-testid="UserSettings__filter-saved-switch"
                      name={name}
                      checked={value}
                      onChange={(event, option) => {
                        event.stopPropagation()
                        onChange(option)
                      }}
                      onBlur={onBlur}
                    />
                  )}
                />
                <Box width={8} />
                <Typography>
                  {t('organisms.UserPanel.UserSettingsForm.cacheFilters')}
                </Typography>
              </Box>
            </Box>
            <Box mx={3.812}>
              <Grid container direction="column">
                <Box my={1}>
                  <Controller
                    control={control}
                    name="weekStart"
                    render={({ field: { name, value, onChange, onBlur } }) => (
                      <StyledSmallSelect<SelectOption, false, true, false>
                        name={name}
                        label={t(
                          'organisms.UserPanel.UserSettingsForm.weekStartLabel',
                        )}
                        value={weekStartOptions.find(
                          (option) => option.id === value,
                        )}
                        options={weekStartOptions}
                        getOptionLabel={(option) => option.label}
                        disabled={weekStartOptions.length < 2}
                        onChange={(event, option) => {
                          event.stopPropagation()
                          onChange(option?.id)
                        }}
                        onBlur={onBlur}
                        disableClearable
                      />
                    )}
                  />
                </Box>
                <Box my={1}>
                  <Controller
                    control={control}
                    name="dateFormat"
                    render={({ field: { name, value, onChange, onBlur } }) => (
                      <StyledSelect<SelectOption, false, true, false>
                        name={name}
                        label={t(
                          'organisms.UserPanel.UserSettingsForm.dateFormatLabel',
                        )}
                        value={dateFormatOptions.find(
                          (option) => option.id === value,
                        )}
                        options={dateFormatOptions}
                        getOptionLabel={(option) => option.label}
                        disabled={dateFormatOptions.length < 2}
                        onChange={(event, option) => {
                          event.stopPropagation()
                          onChange(option?.id)
                        }}
                        onBlur={onBlur}
                        disableClearable
                      />
                    )}
                  />
                </Box>
                <Box my={1}>
                  <Controller
                    control={control}
                    name="timeFormat"
                    render={({ field: { name, value, onChange, onBlur } }) => (
                      <StyledSelect<SelectOption, false, true, false>
                        name={name}
                        label={t(
                          'organisms.UserPanel.UserSettingsForm.timeFormatLabel',
                        )}
                        value={timeFormatOptions.find(
                          (option) => option.id === value,
                        )}
                        options={timeFormatOptions}
                        getOptionLabel={(option) => option.label}
                        disabled={timeFormatOptions.length < 2}
                        onChange={(event, option) => {
                          event.stopPropagation()
                          onChange(option?.id)
                        }}
                        onBlur={onBlur}
                        disableClearable
                      />
                    )}
                  />
                </Box>
                <Box width={1} my={1}>
                  <Controller
                    control={control}
                    name="timeDisplay"
                    render={({ field: { name, value, onChange, onBlur } }) => (
                      <StyledSelect<SelectOption, false, true, false>
                        name={name}
                        fullWidth
                        label={t(
                          'organisms.UserPanel.UserSettingsForm.timeDisplayLabel',
                        )}
                        value={timeDisplayOptions.find(
                          (option) => option.id === value,
                        )}
                        options={timeDisplayOptions}
                        getOptionLabel={(option) => option.label}
                        disabled={timeDisplayOptions.length < 2}
                        onChange={(event, option) => {
                          event.stopPropagation()
                          onChange(option?.id)
                        }}
                        onBlur={onBlur}
                        disableClearable
                      />
                    )}
                  />
                </Box>
                <Box width={1} my={1}>
                  <Controller
                    control={control}
                    name="airportsIdentification"
                    render={({ field: { name, value, onChange, onBlur } }) => (
                      <StyledSmallSelect<SelectOption, false, true, false>
                        name={name}
                        fullWidth
                        label={t(
                          'organisms.UserPanel.UserSettingsForm.airportIdentificationLabel',
                        )}
                        value={airportIdentificationOptions.find(
                          (option) => option.id === value,
                        )}
                        options={airportIdentificationOptions}
                        getOptionLabel={(option) => option.label}
                        disabled={airportIdentificationOptions.length < 2}
                        onChange={(event, option) => {
                          event.stopPropagation()
                          onChange(option?.id)
                        }}
                        onBlur={onBlur}
                        disableClearable
                      />
                    )}
                  />
                </Box>
              </Grid>
            </Box>
          </Box>
        </Box>
        <StyledButton
          inverted
          type="submit"
          loading={isLoading}
          disabled={!formState.isDirty}
        >
          {t('organisms.UserPanel.UserSettingsForm.submit')}
        </StyledButton>
      </StyledForm>
    </Box>
  )
}

const StyledForm = styled.form`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding-top: 2.5rem;
`

const StyledSelect = styled(Select)`
  & ${TextFieldInputLabel} {
    color: ${({ theme }) => theme.palette.grey[600]};
  }

  width: 12rem;
` as typeof Select

const StyledSmallSelect = styled(StyledSelect)`
  width: 8rem;
  padding-right: 0;
` as typeof Select

const StyledButton = styled(Button)`
  margin-bottom: 2rem;
  width: 6rem;
`

export default UserSettingsForm
