import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Tooltip from '@app/components/atoms/Tooltip/Tooltip'
import { theme } from '@app/theme'

interface ColorsMap {
  default: string
  disabled?: string
  [key: number]: string
}

interface RangeIndicatorProps {
  isDisabled?: boolean
  max: number
  value: number
  tooltipText?: string
  colorsMap?: ColorsMap
}

enum UsedPercentageBreakpoints {
  Error = 95,
  Warning = 70,
}

const DEFAULT_COLORS_MAP = {
  default: theme.palette.grey[600],
  disabled: theme.palette.grey[600],
  [UsedPercentageBreakpoints.Warning]: theme.colors.orange,
  [UsedPercentageBreakpoints.Error]: theme.colors.red,
}

const getColorFromProvidedMap = (
  usedPercentage: number,
  isDisabled: boolean,
  colorsMap: ColorsMap,
) => {
  if (isDisabled && colorsMap.disabled) {
    return colorsMap.disabled
  }

  const relevantBreakpointKey = Math.max(
    ...Object.keys(colorsMap)
      .map(Number)
      .filter((k) => k < usedPercentage),
  )

  return colorsMap[relevantBreakpointKey] ?? colorsMap.default
}

const RangeIndicator = ({
  value,
  max,
  isDisabled,
  tooltipText,
  colorsMap = DEFAULT_COLORS_MAP,
}: RangeIndicatorProps): JSX.Element => {
  const { t } = useTranslation()

  const usedPercentage = (value / max) * 100

  const backgroundColor = getColorFromProvidedMap(
    usedPercentage,
    Boolean(isDisabled),
    colorsMap,
  )

  const defaultTooltipText = t('atoms.RangeIndicator.tooltipText', {
    usedPercentage: Math.ceil(usedPercentage),
  })

  return (
    <Container>
      <Tooltip
        backgroundColor={backgroundColor}
        title={tooltipText ?? (defaultTooltipText as string)}
      >
        <RangeContainer>
          <Range />
          <UsedRange
            $isDisabled={isDisabled}
            $usedPercentage={Math.min(usedPercentage, 100)}
            $colorsMap={colorsMap}
          />
        </RangeContainer>
      </Tooltip>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
`

const RangeContainer = styled.div`
  position: relative;
  width: 100%;
`

const Range = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background: ${({ theme }) => theme.palette.grey[200]};
`

const UsedRange = styled(Range)<{
  $isDisabled?: boolean
  $usedPercentage: number
  $colorsMap: ColorsMap
}>`
  background: ${({ $isDisabled, $usedPercentage, $colorsMap }) =>
    getColorFromProvidedMap($usedPercentage, Boolean($isDisabled), $colorsMap)};

  width: ${({ $usedPercentage }) => $usedPercentage}%;
`

export default RangeIndicator
