import React, { useCallback, useMemo, useRef, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, lighten, Menu, MenuItem } from '@material-ui/core'

import Button from '@app/components/atoms/Button/Button'

import UserNoteDialog, {
  UserNoteDialogVariant,
} from '@app/components/organisms/UserNoteDialog/UserNoteDialog'

import {
  addOfferDocumentId,
  getRequestDetailAction,
  updateOfferStatusAction,
} from '@app/store/pages/requests/requestDetail/requestDetail.actions'
import { offerTransitions } from '@shared/utils/offerTransitions'
import { OfferDto } from '@shared/dto/requests.dto'
import {
  DocumentFileType,
  DocumentType,
  OfferFlags,
  OfferStatuses,
  OfferTypes,
} from '@shared/enums'
import { assertUnreachable } from '@shared/utils/assertUnreachable'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { api } from '@app/utils/api/api'
import { useSnackbar } from 'notistack'
import { AxiosError } from 'axios'
import { selectUserInfo } from '@app/store/core/userInfo/userInfo.selectors'
import ConfirmationDialog from '@app/components/molecules/ConfirmationDialog/ConfirmationDialog'
import Typography from '@app/components/atoms/Typography/Typography'
import TextField from '@app/components/atoms/TextField/TextField'
import { selectSelectedOperator } from '@app/store/core/userOperators/userOperators.selectors'

//
//
// CharterOffer = 'charter_offer',
// OwnerApproval = 'owner_approval',
// Invoice = 'invoice',
//
//
const printOptions = [
  ['Charter Offer / Contract', DocumentType.CharterOffer],
  ['Owner Approval', DocumentType.OwnerApproval],
  ['Proforma Invoice / Invoice', DocumentType.Invoice],
] satisfies [label: string, type: DocumentType][]

interface OfferDetailTabPanelActionsProps {
  offer: OfferDto
  onResetButtonClick: () => void | Promise<void>
  onSaveButtonClick: () => void | Promise<void>
  onCancelButtonClick: () => void | Promise<void>
  onRebookingButtonClick: () => void | Promise<void>
  isRebookingButtonVisible?: boolean
  isRebookingButtonDisabled?: boolean
  isRebookingButtonLoading?: boolean
  areTransitionButtonsDisabled?: boolean
  isSaveButtonDisabled?: boolean
  areTransitionButtonsLoading?: boolean
  isSaveButtonLoading?: boolean
  areTransitionButtonsVisible?: boolean
  isSaveButtonVisible?: boolean
  isResetButtonVisible?: boolean
  isRejectButtonVisible?: boolean
  isRejectButtonDisabled?: boolean
  isRejectButtonLoading?: boolean
  isCancelButtonVisible?: boolean
  isCancelButtonDisabled?: boolean
  isCancelButtonLoading?: boolean
  isDeclineButtonDisabled?: boolean
  isDeclineButtonLoading?: boolean
  isDeclineButtonVisible?: boolean
  isReserveQuotationVisible?: boolean
  className?: string
  cancellationFee: number
  requestId: number
}

const OfferDetailTabPanelActions = ({
  onResetButtonClick,
  onSaveButtonClick,
  onCancelButtonClick,
  onRebookingButtonClick,
  isSaveButtonDisabled,
  isSaveButtonLoading,
  offer,
  className,
  isRebookingButtonDisabled,
  isRebookingButtonLoading,
  isRebookingButtonVisible,
  isResetButtonVisible,
  isRejectButtonVisible,
  isRejectButtonDisabled,
  isRejectButtonLoading,
  isCancelButtonVisible,
  isCancelButtonDisabled,
  isCancelButtonLoading,
  cancellationFee,
  isDeclineButtonDisabled,
  isDeclineButtonLoading,
  isDeclineButtonVisible = true,
  areTransitionButtonsDisabled,
  areTransitionButtonsLoading,
  areTransitionButtonsVisible = true,
  isSaveButtonVisible = true,
  isReserveQuotationVisible,
  requestId,
}: OfferDetailTabPanelActionsProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const theme = useTheme()
  const [showPrintMenu, setShowPrintMenu] = useState(false)
  const printMenuBtn = useRef<HTMLDivElement>(null)
  const userInfo = useSelector(selectUserInfo)
  const operator = useSelector(selectSelectedOperator)

  const { enqueueSnackbar } = useSnackbar()

  const [userNoteDialogVariant, setUserNoteDialogVariant] =
    useState<UserNoteDialogVariant | null>(null)

  const nextStates = offerTransitions[offer.status]

  const userNoteDialogDefaultValues = useMemo(() => {
    switch (userNoteDialogVariant) {
      case UserNoteDialogVariant.Quotation:
        return { note: offer.aircraft.quotation_message_template ?? '' }

      case UserNoteDialogVariant.Booking:
        return { note: offer.aircraft.booking_message_template ?? '' }

      case UserNoteDialogVariant.Decline:
        return { note: offer.aircraft.decline_message_template ?? '' }

      default:
        return { note: '' }
    }
  }, [userNoteDialogVariant, offer])

  const onButtonClick = (status: OfferStatuses) => {
    dispatch(updateOfferStatusAction(offer.id, status))
  }

  const onUserNoteDialogSubmit = (
    activeVariant: UserNoteDialogVariant,
    note?: string,
    shouldSendEmail?: boolean,
    shouldSendToAvinode?: boolean,
  ) => {
    switch (activeVariant) {
      case UserNoteDialogVariant.Decline:
        dispatch(
          updateOfferStatusAction(offer.id, OfferStatuses.Declined, note, {
            shouldSendToAvinode,
          }),
        )

        return

      case UserNoteDialogVariant.Quotation:
        dispatch(
          updateOfferStatusAction(offer.id, OfferStatuses.Quoted, note, {
            cancellationFee,
            shouldSendEmail,
            shouldSendToAvinode,
          }),
        )

        return

      case UserNoteDialogVariant.Booking: {
        let nextStatus: OfferStatuses | null = null

        switch (offer.type) {
          case OfferTypes.Cancellation:
            nextStatus = OfferStatuses.BookedCancelled

            break

          case OfferTypes.Regular:
          case OfferTypes.Rebooking:
            nextStatus = OfferStatuses.Booked

            break

          default:
            assertUnreachable(offer.type)
        }

        dispatch(
          updateOfferStatusAction(offer.id, nextStatus, note, {
            shouldSendEmail,
            shouldSendToAvinode,
          }),
        )

        return
      }

      default:
        assertUnreachable(activeVariant)
    }
  }

  const [reservationHours, setReservationHours] = useState<number>(
    operator?.reservationHours || 24,
  )

  const queryClient = useQueryClient()
  const [showReservationModal, setShowReservationModal] = useState(false)

  const handleGenerateDocument = useCallback(
    async (document: DocumentType, file: DocumentFileType) => {
      enqueueSnackbar(t('pages.requests.documents.generateStart'), {
        variant: 'info',
      })
      try {
        const q = await queryClient.fetchQuery({
          queryKey: ['offer-document'],
          queryFn: () =>
            api.generateOfferDocument({
              offer_id: offer.id,
              type: document,
              fileType: file,
              user: userInfo,
            }),
        })
        dispatch(addOfferDocumentId(q.data.id, file))
      } catch (e) {
        const err = e as AxiosError
        enqueueSnackbar(
          <div>
            {t('pages.requests.documents.generateError')}
            <div>{err?.response?.data?.message || 'Unknown Error'}</div>
          </div>,
          {
            variant: 'error',
          },
        )
      }
    },
    [queryClient, enqueueSnackbar],
  )

  const isCancellationActive = offer.type === OfferTypes.Cancellation

  const { mutate: reserveM, isLoading: isReserveMutationLoading } = useMutation(
    async () => {
      const date = new Date()
      date.setHours(date.getHours() + reservationHours)
      return api.reserveOffer(offer.id, date)
    },
    {
      mutationKey: ['request', 'reservation'],
      onSuccess: () => {
        enqueueSnackbar(t('pages.requests.reservation.successMessage'), {
          variant: 'success',
        })
      },
      onError: () => {
        enqueueSnackbar(t('pages.requests.reservation.errorMessage'), {
          variant: 'error',
        })
      },
      onSettled: () => {
        dispatch(getRequestDetailAction(requestId, operator?.id))
      },
    },
  )

  const { mutate: deleteReservationM, isLoading: isDeletingReservation } =
    useMutation(
      async () => {
        return api.deleteOfferReservation(offer.id)
      },
      {
        mutationKey: ['request', 'delete-reservation'],
        onSuccess: () => {
          enqueueSnackbar(t('pages.requests.unreservation.successMessage'), {
            variant: 'success',
          })
        },
        onError: () => {
          enqueueSnackbar(t('pages.requests.unreservation.errorMessage'), {
            variant: 'error',
          })
        },
        onSettled: () => {
          dispatch(getRequestDetailAction(requestId, operator?.id))
        },
      },
    )

  const isReserveButtonDisabled =
    !nextStates.includes(OfferStatuses.Booked) ||
    !!offer.cancellation_offer_id ||
    isReserveMutationLoading

  const isReserved = !!offer.reserved

  return (
    <StyledContainer className={className} ref={printMenuBtn}>
      {isReserveQuotationVisible &&
        !isReserved &&
        offer.flag !== OfferFlags.Conflict && (
          <StyledButton
            loading={isReserveMutationLoading}
            disabled={isReserveButtonDisabled}
            onClick={() => setShowReservationModal(true)}
            data-testid="OfferDetailTabPanelActions__reserve-button"
          >
            {t('organisms.OfferDetailTabPanelActions.reserve')}
          </StyledButton>
        )}
      {isReserveQuotationVisible && isReserved && (
        <StyledButton
          loading={isReserveMutationLoading}
          disabled={isReserveButtonDisabled}
          onClick={() => deleteReservationM()}
          data-testid="OfferDetailTabPanelActions__unreserve-button"
          $background={theme.colors.red}
        >
          {t('organisms.OfferDetailTabPanelActions.unreserve')}
        </StyledButton>
      )}
      {isResetButtonVisible && (
        <StyledButton
          loading={isDeletingReservation}
          disabled={isDeletingReservation}
          onClick={onResetButtonClick}
          data-testid="OfferDetailTabPanelActions__reset-button"
        >
          {t('organisms.OfferDetailTabPanelActions.reset')}
        </StyledButton>
      )}

      {isSaveButtonVisible && (
        <>
          <StyledButton
            loading={isSaveButtonLoading}
            disabled={isSaveButtonDisabled}
            onClick={onSaveButtonClick}
            data-testid="OfferDetailTabPanelActions__save-button"
          >
            {t('organisms.OfferDetailTabPanelActions.save')}
          </StyledButton>
        </>
      )}

      {isRebookingButtonVisible && (
        <>
          <StyledButton
            loading={isRebookingButtonLoading}
            disabled={isRebookingButtonDisabled}
            onClick={onRebookingButtonClick}
            data-testid="OfferDetailTabPanelActions__rebooking-button"
          >
            {t('organisms.OfferDetailTabPanelActions.rebooking')}
          </StyledButton>
        </>
      )}

      {isCancelButtonVisible && (
        <>
          <StyledButton
            loading={isCancelButtonLoading}
            disabled={isCancelButtonDisabled}
            onClick={onCancelButtonClick}
            data-testid="OfferDetailTabPanelActions__cancel-button"
          >
            {t('organisms.OfferDetailTabPanelActions.cancel')}
          </StyledButton>
        </>
      )}

      {areTransitionButtonsVisible && (
        <>
          {nextStates.includes(OfferStatuses.New) && (
            <StyledButton
              loading={areTransitionButtonsLoading}
              disabled={areTransitionButtonsDisabled}
              onClick={() => onButtonClick(OfferStatuses.New)}
              data-testid="OfferDetailTabPanelActions__new-button"
            >
              {t('organisms.OfferDetailTabPanelActions.new')}
            </StyledButton>
          )}

          {nextStates.includes(OfferStatuses.Unhandled) && (
            <StyledButton
              loading={areTransitionButtonsLoading}
              disabled={areTransitionButtonsDisabled}
              onClick={() => onButtonClick(OfferStatuses.Unhandled)}
              data-testid="OfferDetailTabPanelActions__unhandled-button"
            >
              {t('organisms.OfferDetailTabPanelActions.unhandled')}
            </StyledButton>
          )}

          {nextStates.includes(OfferStatuses.Draft) && (
            <StyledButton
              loading={areTransitionButtonsLoading}
              disabled={areTransitionButtonsDisabled}
              onClick={() => onButtonClick(OfferStatuses.Draft)}
              data-testid="OfferDetailTabPanelActions__draft-button"
            >
              {t('organisms.OfferDetailTabPanelActions.draft')}
            </StyledButton>
          )}

          {nextStates.includes(OfferStatuses.Quoted) && (
            <StyledButton
              loading={areTransitionButtonsLoading}
              disabled={areTransitionButtonsDisabled}
              onClick={() =>
                setUserNoteDialogVariant(UserNoteDialogVariant.Quotation)
              }
              data-testid="OfferDetailTabPanelActions__quoted-button"
            >
              {isCancellationActive
                ? t('organisms.OfferDetailTabPanelActions.quote_cancellation')
                : t('organisms.OfferDetailTabPanelActions.quoted')}
            </StyledButton>
          )}

          {nextStates.includes(OfferStatuses.Booked) &&
            !offer.cancellation_offer_id && (
              <StyledButton
                loading={areTransitionButtonsLoading}
                disabled={areTransitionButtonsDisabled}
                onClick={() =>
                  setUserNoteDialogVariant(UserNoteDialogVariant.Booking)
                }
                data-testid="OfferDetailTabPanelActions__booked-button"
                $background={theme.colors.green}
              >
                {t('organisms.OfferDetailTabPanelActions.booked')}
              </StyledButton>
            )}

          {nextStates.includes(OfferStatuses.BookedCancelled) &&
            offer.cancellation_offer_id && (
              <StyledButton
                loading={areTransitionButtonsLoading}
                disabled={areTransitionButtonsDisabled}
                onClick={() =>
                  setUserNoteDialogVariant(UserNoteDialogVariant.Booking)
                }
                data-testid="OfferDetailTabPanelActions__booked-cancelled-button"
                $background={theme.colors.green}
              >
                {t('organisms.OfferDetailTabPanelActions.booked-cancelled')}
              </StyledButton>
            )}

          {nextStates.includes(OfferStatuses.Cancelled) && (
            <StyledButton
              loading={areTransitionButtonsLoading}
              disabled={areTransitionButtonsDisabled}
              onClick={() => onButtonClick(OfferStatuses.Cancelled)}
              data-testid="OfferDetailTabPanelActions__cancelled-button"
            >
              {t('organisms.OfferDetailTabPanelActions.cancelled')}
            </StyledButton>
          )}
        </>
      )}

      {isDeclineButtonVisible && (
        <StyledButton
          loading={isDeclineButtonLoading}
          disabled={isDeclineButtonDisabled}
          onClick={() =>
            setUserNoteDialogVariant(UserNoteDialogVariant.Decline)
          }
          data-testid="OfferDetailTabPanelActions__declined-button"
          $background={theme.colors.brown}
        >
          {isCancellationActive
            ? t('organisms.OfferDetailTabPanelActions.decline_cancellation')
            : t('organisms.OfferDetailTabPanelActions.declined')}
        </StyledButton>
      )}

      {isRejectButtonVisible && (
        <StyledButton
          loading={isRejectButtonLoading}
          disabled={isRejectButtonDisabled}
          onClick={() => onButtonClick(OfferStatuses.Rejected)}
          data-testid="OfferDetailTabPanelActions__rejected-button"
          $background={theme.colors.red}
        >
          {t('organisms.OfferDetailTabPanelActions.rejected')}
        </StyledButton>
      )}

      <StyledButton
        onClick={() => setShowPrintMenu((state) => !state)}
        data-testid="OfferDetailTabPanelActions__print-button"
      >
        {t('organisms.OfferDetailTabPanelActions.print')}
      </StyledButton>

      <Menu
        anchorEl={printMenuBtn.current}
        open={showPrintMenu}
        onClose={() => setShowPrintMenu(false)}
        anchorPosition={{
          top: 50,
          left: 0,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {printOptions.map(([option, value], i, arr) => (
          <div key={`${option}-${i}`}>
            <StyledMenuItem
              onClick={() => {
                handleGenerateDocument(value, DocumentFileType.DOCx)
                setShowPrintMenu(false)
              }}
            >
              <StyledIcon src="/images/icons/wordicon.png" />
              {option} - DOCx
            </StyledMenuItem>
            <StyledMenuItem
              onClick={() => {
                handleGenerateDocument(value, DocumentFileType.PDF)
                setShowPrintMenu(false)
              }}
            >
              <StyledIcon src="/images/icons/pdficon.png" />
              {option} - PDF
            </StyledMenuItem>
            {i + 1 !== arr.length && <hr />}
          </div>
        ))}
      </Menu>

      {/* @todo [Tech] Fix animation - use open prop instead of condition */}
      {userNoteDialogVariant && (
        <UserNoteDialog
          open
          onSubmit={onUserNoteDialogSubmit}
          onClose={() => setUserNoteDialogVariant(null)}
          defaultValues={userNoteDialogDefaultValues}
          variant={userNoteDialogVariant}
        />
      )}
      <ConfirmationDialog
        onClose={() => {
          setShowReservationModal(false)
        }}
        onCancel={() => {
          setShowReservationModal(false)
        }}
        onConfirm={() => {
          setShowReservationModal(false)
          reserveM()
        }}
        confirmButtonText={t('organisms.OfferDetailTabPanelActions.reserve')}
        open={showReservationModal}
        dialogContent={
          <Box minWidth={'400px'}>
            <Typography variant="formTitle">
              {t('organisms.OfferDetailTabPanelActions.reserve')}
            </Typography>
            <ReservationInputContainer>
              <TextField
                label={t(
                  'organisms.OperatorSettingsForm.reservationHoursLabel',
                )}
                type="number"
                value={reservationHours}
                onChange={(e) => {
                  setReservationHours(Number(e.target.value) || 24)
                }}
              />
            </ReservationInputContainer>
          </Box>
        }
      />
    </StyledContainer>
  )
}

const StyledButton = styled(Button)<{ $background?: string }>`
  background: ${({ $background }) => $background};

  &:hover {
    background: ${({ $background }) =>
      $background && lighten($background, 0.1)};
  }
`

const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  > * {
    margin: 0.5rem;
  }
`

const ReservationInputContainer = styled.div`
  display: flex;
  justify-content: center;

  > * {
    margin: 0.5rem;
  }
`

const StyledMenuItem = styled(MenuItem)`
  display: flex;
`

const StyledIcon = styled.img`
  width: auto;
  max-height: 1.2rem;
  margin-right: 0.5rem;
`

export default OfferDetailTabPanelActions
