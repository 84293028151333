import React from 'react'
import { useTheme } from 'styled-components'

import Badge, { BadgeProps } from '@app/components/atoms/Badge/Badge'

type BookedCancelledBadgeProps = Partial<BadgeProps>

const BookedCancelledBadge = ({
  color,
  label = 'booked-cancelled',
  ...props
}: BookedCancelledBadgeProps): JSX.Element => {
  const theme = useTheme()

  return <Badge color={color ?? theme.colors.grey} label={label} {...props} />
}

export default BookedCancelledBadge
