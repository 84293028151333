import React from 'react'
import { useTheme } from 'styled-components'

import Badge, { BadgeProps } from '@app/components/atoms/Badge/Badge'

type CancelledBadgeProps = Partial<BadgeProps>

const CancelledBadge = ({
  color,
  label = 'cancelled',
  ...props
}: CancelledBadgeProps): JSX.Element => {
  const theme = useTheme()

  return <Badge color={color ?? theme.colors.grey} label={label} {...props} />
}

export default CancelledBadge
