import React, { ReactElement } from 'react'
import NumberFormat from 'react-number-format'

import { useSelector } from 'react-redux'
import { selectSelectedOperator } from '@app/store/core/userOperators/userOperators.selectors'

type PartialEvent = {
  target: {
    name: string
    value: string
  }
}

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void
  onChange: (event: PartialEvent, value?: number) => void
  name: string
}

const PriceFormatter = (props: NumberFormatCustomProps): ReactElement => {
  const { inputRef, onChange, ...rest } = props

  const selectedOperator = useSelector(selectSelectedOperator)

  return (
    <NumberFormat
      {...rest}
      suffix={` ${selectedOperator?.currency?.symbol}`}
      thousandSeparator=" "
      isNumericString
      getInputRef={inputRef}
      onValueChange={(values) => {
        const potentialEvent: PartialEvent = {
          target: {
            name: props.name,
            value: values.value,
          },
        }

        onChange(potentialEvent, values.floatValue)
      }}
    />
  )
}

// @see https://v4.mui.com/components/text-fields/#integration-with-3rd-party-input-libraries
// eslint-disable-next-line
export default PriceFormatter as any
