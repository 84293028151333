import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'

import Dialog from '@app/components/atoms/Dialog/Dialog'
import Typography from '@app/components/atoms/Typography/Typography'

import CreateAirportFeeForm, {
  CreateAirportFeeFormProps,
} from '@app/components/pages/Airports/AirportFeesTab/CreateAirportFeeForm'

export type CopyAirportFeeDialogProps = {
  isOpen: boolean
  onClose?: () => void | Promise<void>
  onChange: CreateAirportFeeFormProps['onChange']
  onSubmit: CreateAirportFeeFormProps['onSubmit']
  data: CreateAirportFeeFormProps['data']
}

const CopyAirportFeeDialog = ({
  isOpen,
  onClose,
  onChange,
  onSubmit,
  data,
}: CopyAirportFeeDialogProps) => {
  const { t } = useTranslation()

  return (
    <StyledDialog
      classes={{ paper: 'Dialog__paper' }}
      open={isOpen}
      onClose={onClose}
      keepMounted
    >
      <HeaderContainer>
        <Typography variant="dialogHeading">
          {t('CopyAirportFeeDialog.title')}
        </Typography>
      </HeaderContainer>

      <Box p="2rem">
        <CreateAirportFeeForm
          onChange={onChange}
          onSubmit={onSubmit}
          data={data}
        />
      </Box>
    </StyledDialog>
  )
}

const StyledDialog = styled(Dialog)`
  .Dialog__paper {
    padding: 0;
    min-width: 50rem;
    min-height: 20rem;
    max-width: none;
    display: flex;
    flex-direction: column;
  }
`

const HeaderContainer = styled.div`
  background: ${({ theme }) => theme.palette.grey[100]};
  margin-bottom: 1rem;
  padding: 2rem 2rem 0 2rem;
`

export default CopyAirportFeeDialog
