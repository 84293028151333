import React from 'react'
import styled from 'styled-components'

import BookmarkIcon from '@material-ui/icons/Bookmark'
import Box from '@material-ui/core/Box'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

import Typography, {
  TypographyProps as BaseTypographyProps,
} from '@app/components/atoms/Typography/Typography'

import { lighten, darken } from '@material-ui/core/styles'

import { prop } from '@app/utils/css'

export enum BadgeVariants {
  Regular = 'regular',
  WithBookmark = 'with-bookmark',
}

export interface BadgeProps {
  color: string
  label: string
  variant?: BadgeVariants
}

interface TypographyProps extends BaseTypographyProps {
  $colorCode: string
  $isBookmarkIconVisible: boolean
}

interface BookmarkIconProps extends SvgIconProps {
  $colorCode: string
}

const Badge = ({
  color,
  label,
  variant = BadgeVariants.WithBookmark,
  ...props
}: BadgeProps): JSX.Element => {
  return (
    <Box display="flex" alignItems="center" {...props}>
      {variant === BadgeVariants.WithBookmark && (
        <StyledBookmarkIcon $colorCode={color} viewBox="5.5 17 13 4" />
      )}
      <StyledTypography
        $colorCode={color}
        $isBookmarkIconVisible={variant === BadgeVariants.WithBookmark}
      >
        {label}
      </StyledTypography>
    </Box>
  )
}

const StyledTypography = styled(Typography)<TypographyProps>`
  font-size: 0.75rem;
  padding: 0 0.5rem;
  background-color: ${({ $colorCode }) => lighten($colorCode, 0.6)};
  color: ${({ $colorCode }) => darken($colorCode, 0.25)};

  border-radius: ${({ $isBookmarkIconVisible }) =>
    $isBookmarkIconVisible ? '0 2px 2px 0' : '2px'};
`

const StyledBookmarkIcon = styled(BookmarkIcon)<BookmarkIconProps>`
  transform: rotate(90deg);
  margin-right: -0.1rem;
  color: ${prop('$colorCode')};
  height: 12px;
  width: 18px;
`

export default Badge
