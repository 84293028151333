export enum RoundingTypes {
  Up = 'up',
  Down = 'down',
  ToNearest = 'to-nearest',
  None = 'none',
}

export enum RoundingMultiples {
  Tenths = 'tenths',
  Fifties = 'fifties',
  Hundreds = 'hundreds',
  Thousands = 'thousands',
  None = 'none',
}

export enum HourlyVariableAdjustmentUnits {
  FlatFee = 'flat-fee',
  Percentage = 'percentage',
}
