import React from 'react'
import styled from 'styled-components'

import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'

import { SideMenuProps } from '@app/components/organisms/GeneralLayout/SideMenu'
import { IMenuItem } from '@app/components/organisms/GeneralLayout/GeneralLayout'
import Typography from '@app/components/atoms/Typography/Typography'

export enum SideMenuItemVariants {
  Regular = 'regular',
  Small = 'small',
}

interface MenuItemProps {
  item: IMenuItem
  onClick: SideMenuProps['onItemClick']
  active: boolean
  variant?: SideMenuItemVariants
  alertIcon?: JSX.Element
}

const SideMenuItem = ({
  item,
  onClick,
  active,
  variant = SideMenuItemVariants.Regular,
  alertIcon,
  ...props
}: MenuItemProps): JSX.Element => {
  return (
    <StyledBox
      py={variant === SideMenuItemVariants.Small ? 0 : 1}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      onClick={() => onClick(item.id)}
      $active={active}
      {...props}
    >
      {alertIcon}
      <StyledIconButton>{item.icon}</StyledIconButton>
      {item.label && <StyledTypography>{item.label}</StyledTypography>}
    </StyledBox>
  )
}

const StyledBox = styled(Box)<{ $active: boolean }>`
  border-left: 3px solid rgba(0, 0, 0, 0);
  cursor: pointer;

  ${({ $active, theme }) =>
    $active &&
    `
      background-color: rgba(255, 255, 255, 0.15);
      border-left: 3px solid ${theme.palette.common.white};
    `}

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
    border-left: 3px solid ${({ theme }) => theme.palette.common.white};
  }
`

const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 0.625rem;
`

const StyledIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.common.white};
`

export default SideMenuItem
