import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

const useClientFormValidationSchema = () => {
  const { t } = useTranslation()

  return yup.object().shape({
    companyName: yup
      .string()
      .typeError(t('organisms.ClientForm.companyNameRequired'))
      .required(t('organisms.ClientForm.companyNameRequired')),

    street: yup
      .string()
      .typeError(t('organisms.ClientForm.streetRequired'))
      .nullable(),

    city: yup
      .string()
      .typeError(t('organisms.ClientForm.cityRequired'))
      .nullable(),

    zipCode: yup
      .string()
      .typeError(t('organisms.ClientForm.zipCodeRequired'))
      .nullable(),

    country: yup
      .object()
      .typeError(t('organisms.ClientForm.countryRequired'))
      .nullable(),
  })
}

export default useClientFormValidationSchema
