import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useDeepCompareEffect } from 'react-use'
import * as yup from 'yup'

import Box from '@material-ui/core/Box'

import Typography from '@app/components/atoms/Typography/Typography'
import TextField from '@app/components/atoms/TextField/TextField'
import ErrorTooltip from '@app/components/atoms/Tooltip/ErrorTooltip'
import Button from '@app/components/atoms/Button/Button'

import { useYupValidationResolver } from '@app/hooks/useYupValidationResolver'

export type IntegrationSettings = {
  avinodeUsername: string
}

export type IntegrationsTabProps = {
  integrationSettings: IntegrationSettings
  isUpdatingIntegrationSettings?: boolean

  onIntegrationSettingsUpdate?: (
    nextIntegrationsSettings: IntegrationSettings,
  ) => void | Promise<void>
}

const useIntegrationSettingsFormValidationSchema =
  (): yup.SchemaOf<IntegrationSettings> => {
    const { t } = useTranslation()

    return yup.object({
      avinodeUsername: yup
        .string()
        .required(t('validations.valueInvalid'))
        .typeError(t('validations.valueInvalid')),
    })
  }

const IntegrationsTab = ({
  integrationSettings,
  isUpdatingIntegrationSettings,
  onIntegrationSettingsUpdate,
}: IntegrationsTabProps) => {
  const { t } = useTranslation()

  const validationSchema = useIntegrationSettingsFormValidationSchema()
  const validationResolver = useYupValidationResolver(validationSchema)

  const { formState, reset, register, handleSubmit } =
    useForm<IntegrationSettings>({
      defaultValues: integrationSettings,
      resolver: validationResolver,
    })

  useDeepCompareEffect(() => {
    reset(integrationSettings)
  }, [integrationSettings])

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height={1}
      width={1}
    >
      <StyledForm
        onSubmit={handleSubmit((values) => {
          onIntegrationSettingsUpdate?.(values)
        })}
      >
        <Box>
          <Typography variant="formTitle">
            {t('organisms.UserPanel.IntegrationsTab.avinodeSectionTitle')}
          </Typography>

          <Box maxWidth={1 / 2} mt={1} mb={2.8}>
            <ErrorTooltip title={formState.errors.avinodeUsername?.message}>
              <TextField
                label={t(
                  'organisms.UserPanel.IntegrationsTab.avinodeUsernameLabel',
                )}
                placeholder={t(
                  'organisms.UserPanel.IntegrationsTab.avinodeUsernamePlaceholder',
                )}
                fullWidth
                inputProps={{ ...register('avinodeUsername') }}
                error={Boolean(formState.errors.avinodeUsername)}
              />
            </ErrorTooltip>
          </Box>
        </Box>
        <StyledButton
          inverted
          type="submit"
          loading={isUpdatingIntegrationSettings}
        >
          {t('organisms.UserPanel.IntegrationsTab.saveButtonLabel')}
        </StyledButton>
      </StyledForm>
    </Box>
  )
}

const StyledForm = styled.form`
  display: flex;
  justify-content: space-between;
  height: 100%;
  flex-direction: column;
  padding-top: 2.5rem;
  width: 100%;
`

const StyledButton = styled(Button)`
  margin-bottom: 2rem;
  width: 5.375rem;
`

export default IntegrationsTab
