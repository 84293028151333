import React from 'react'
import { useTheme } from 'styled-components'

import Badge, { BadgeProps } from '@app/components/atoms/Badge/Badge'

type StornoBadgeProps = Partial<BadgeProps>

const StornoBadge = ({
  color,
  label = 'storno',
  ...props
}: StornoBadgeProps): JSX.Element => {
  const theme = useTheme()

  return <Badge color={color ?? theme.colors.red} label={label} {...props} />
}

export default StornoBadge
