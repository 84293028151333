import React, { ReactNode } from 'react'
import styled from 'styled-components'

import IconButton, { IconButtonProps } from '@material-ui/core/IconButton'

interface InputEndAdornmentProps {
  children: ReactNode
  onClick?: IconButtonProps['onClick']
  className?: string
}

const InputEndAdornment = ({
  children,
  onClick,
  className,
}: InputEndAdornmentProps) => {
  return (
    <StyledIconButton onClick={onClick} className={className}>
      {children}
    </StyledIconButton>
  )
}

const StyledIconButton = styled(IconButton)`
  margin-right: -6px;
  padding: 6px;

  // @todo Remove global selector
  & svg {
    width: 1.25rem;
  }
`

export default InputEndAdornment
