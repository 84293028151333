import { AircraftFlightRangeDto } from '@shared/dto/aircraft.dto'

export const getAircraftFlightRange = (
  passengerCount: number,
  flightRanges?: AircraftFlightRangeDto[],
): AircraftFlightRangeDto | null => {
  if (!flightRanges?.length) {
    return null
  }

  return flightRanges.reduce((acc, current) => {
    if (!acc || current.passenger_count === passengerCount) {
      return current
    }

    if (acc.passenger_count === passengerCount) {
      return acc
    }

    if (
      current.passenger_count > passengerCount &&
      current.passenger_count < acc.passenger_count
    ) {
      return current
    }

    if (
      current.passenger_count < passengerCount &&
      current.passenger_count > acc.passenger_count
    ) {
      return current
    }

    return acc
  }, null)
}
