import React, { useEffect, useMemo, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useTranslation } from 'react-i18next'

import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

import Timeline from '@app/components/molecules/Schedule/Timeline'
import Typography from '@app/components/atoms/Typography/Typography'
import TimeIndicator from '@app/components/molecules/Schedule/TimeIndicator'
import LoadingSpinner from '@app/components/atoms/LoadingSpinner/LoadingSpinner'
import Swimlane from '@app/components/molecules/Schedule/Swimlane'
import EmptyListMessage from '@app/components/atoms/EmptyListMessage/EmptyListMessage'

import { BaseLegDetailDto } from '@shared/dto/requests.dto'
import {
  OfferProfitAndPrice,
  ScheduleDetailDto,
} from '@shared/dto/schedule.dto'
import { OfferStatuses, ScheduleSource } from '@shared/enums'
import { ScheduleItemVariants } from '@app/components/molecules/Schedule/ScheduleItem'
import { AircraftDetailDto } from '@shared/dto/aircraft.dto'
import { useDateAndTimeFormats } from '@app/hooks/useDateAndTimeFormats'

import {
  getDateFormatByScheduleDensity,
  getScheduleBreakpointsDensity,
  getScheduleSteps,
  scheduleDensityToGridStepMap,
  scheduleDensityToMillisecondsMap,
} from '@app/components/molecules/Schedule/utils'
import { useOffers } from '@app/hooks/useOffers'

dayjs.extend(utc)

export const MAXIMUM_DISPLAYED_TIME_SPAN_IN_DAYS = 70

export interface ScheduleProps {
  schedule: ScheduleDetailDto[]
  availableAircraft: AircraftDetailDto[]
  viewStart: Date
  viewEnd: Date
  onViewChange: (viewStart: Date, viewEnd: Date) => void
  requestLegs?: BaseLegDetailDto[]
  offerStatus?: OfferStatuses
  requestTripId?: string
  movementStep?: number
  zoomStep?: number
  isZoomEnabled?: boolean
  isMovable?: boolean
  isMarketingLegsEnabled?: boolean
  isLoading?: boolean
  isReloading?: boolean
  onEditMarketingLeg?: (id: number) => void
  onRemoveMarketingLeg?: (id: number) => void
  onEditOutage?: (id: number) => void
  onRemoveOutage?: (id: number) => void
  onMarkLegAsRemoved?: (id: number) => void
  onNavigateToRequest?: (id: number) => void
  showEmptyLines?: boolean
  className?: string
  onConvertToMarketingLeg?: (
    id: number,
    dates: { departureDate?: Date; arrivalDate?: Date },
  ) => void
  sources?: ScheduleSource[]
  finalPriceAndTotalProfit?: OfferProfitAndPrice[]
}

const Schedule = ({
  schedule,
  availableAircraft,
  viewStart,
  viewEnd,
  requestLegs,
  offerStatus,
  requestTripId,
  onViewChange,
  isLoading,
  isReloading,
  onConvertToMarketingLeg,
  onEditMarketingLeg,
  onRemoveMarketingLeg,
  onEditOutage,
  onRemoveOutage,
  onMarkLegAsRemoved,
  onNavigateToRequest,
  className,
  isMarketingLegsEnabled,
  isZoomEnabled = true,
  isMovable = true,
  showEmptyLines = true,
  movementStep = 7,
  zoomStep = 7,
  sources = [ScheduleSource.STRAFOS],
}: ScheduleProps) => {
  const { t } = useTranslation()
  const { dateFormat, timeFormat } = useDateAndTimeFormats()
  const [finalPriceAndProfit, setFinalPriceAndProfit] = useState<
    OfferProfitAndPrice[]
  >([])
  const theme = useTheme()

  const aircraftMapBase = availableAircraft.reduce((acc, aircraft) => {
    return {
      ...acc,
      [aircraft.registration_code]: [],
    }
  }, {})

  const aircraftScheduleMap = schedule.reduce<
    Record<string, ScheduleDetailDto[]>
  >((acc, current) => {
    if (!current.aircraft) {
      return acc
    }

    if (
      !showEmptyLines &&
      (dayjs
        .utc(current.marketplace_arrival_date ?? current.arrival_date)
        .isBefore(viewStart) ||
        dayjs
          .utc(current.marketplace_departure_date ?? current.departure_date)
          .isAfter(viewEnd))
    ) {
      return acc
    }

    if (!acc[current.aircraft.registration_code]) {
      return acc
    }

    return {
      ...acc,
      [current.aircraft.registration_code]: [
        ...acc[current.aircraft.registration_code],
        current,
      ],
    }
  }, aircraftMapBase)

  const allOfferIds = useMemo(
    () =>
      [
        ...Object.entries(aircraftScheduleMap)
          .map((x) => x[1].map((schedule) => schedule.offer_id ?? 0))
          .flat(),
        schedule.offer_id ?? 0,
        ...(requestLegs?.map((leg) => leg.offer_id ?? 0) ?? []),
      ].filter((num) => num !== 0),
    [aircraftScheduleMap, schedule, requestLegs],
  )

  const { data, isLoading: areOffersLoading, error } = useOffers(allOfferIds)

  const visibleRequestLegs = requestLegs?.filter(
    (leg) =>
      dayjs.utc(leg.arrival_date).isAfter(viewStart) &&
      dayjs.utc(leg.departure_date).isBefore(viewEnd),
  )

  useEffect(() => {
    if (!!data && !areOffersLoading && !error && Array.isArray(data)) {
      setFinalPriceAndProfit(
        data.map((d) => ({
          offer_id: d.id,
          total_profit: d.request.total_profit ?? 0,
          final_price: d.request.final_price ?? 0,
        })),
      )
    }
  }, [data])

  const breakpointsDensity = useMemo(() => {
    return getScheduleBreakpointsDensity(viewStart, viewEnd)
  }, [viewStart, viewEnd])

  const breakpoints = useMemo(
    () =>
      getScheduleSteps(
        viewStart,
        viewEnd,
        breakpointsDensity,
        scheduleDensityToMillisecondsMap,
      ),
    [viewStart, viewEnd, breakpointsDensity],
  )

  const timelineGrid = useMemo(
    () =>
      getScheduleSteps(
        viewStart,
        viewEnd,
        breakpointsDensity,
        scheduleDensityToGridStepMap,
      ),
    [viewStart, viewEnd, breakpointsDensity],
  )

  const dateTimeFormat = useMemo(() => {
    return getDateFormatByScheduleDensity(breakpointsDensity, {
      timeFormat,
      dateFormat,
    })
  }, [breakpointsDensity])

  const handleNextClick = () => {
    const step = scheduleDensityToGridStepMap[breakpointsDensity] * movementStep

    onViewChange(
      dayjs.utc(viewStart).add(step).toDate(),
      dayjs.utc(viewEnd).add(step).toDate(),
    )
  }

  const handlePreviousClick = () => {
    const step = scheduleDensityToGridStepMap[breakpointsDensity] * movementStep

    onViewChange(
      dayjs.utc(viewStart).subtract(step).toDate(),
      dayjs.utc(viewEnd).subtract(step).toDate(),
    )
  }

  const handleZoomInClick = () => {
    const step = scheduleDensityToGridStepMap[breakpointsDensity] * zoomStep

    onViewChange(
      dayjs
        .utc(viewStart)
        .add(step / 2)
        .toDate(),
      dayjs
        .utc(viewEnd)
        .subtract(step / 2)
        .toDate(),
    )
  }

  const handleZoomOutClick = () => {
    const step = scheduleDensityToGridStepMap[breakpointsDensity] * zoomStep

    onViewChange(
      dayjs
        .utc(viewStart)
        .subtract(step / 2)
        .toDate(),
      dayjs
        .utc(viewEnd)
        .add(step / 2)
        .toDate(),
    )
  }

  if (
    dayjs.utc(viewEnd).diff(dayjs.utc(viewStart), 'days') >
    MAXIMUM_DISPLAYED_TIME_SPAN_IN_DAYS
  ) {
    return (
      <EmptyListMessageContainer>
        <EmptyListMessage
          title={t('molecules.Schedule.outOfRange', {
            days: MAXIMUM_DISPLAYED_TIME_SPAN_IN_DAYS,
          })}
        />
      </EmptyListMessageContainer>
    )
  }

  const strafosDivisionType = DivisionType.soft
  const fl3xxDivisionType =
    sources?.includes(ScheduleSource.LEON) || requestLegs
      ? DivisionType.soft
      : DivisionType.hard
  const leonDivisionType = requestLegs ? DivisionType.soft : DivisionType.hard
  const requestLegsDivisionType =
    sources?.length > 0 ? DivisionType.hard : DivisionType.soft

  return (
    <Container className={className}>
      <Labels>
        <TimelineLabelContainer />
        {Object.keys(aircraftScheduleMap).map((aircraftRegistration) => (
          <div key={aircraftRegistration}>
            {sources?.includes(ScheduleSource.STRAFOS) && (
              <SwimlaneLabelContainer
                key={aircraftRegistration + '-strafos'}
                $divisionType={strafosDivisionType}
              >
                <ScheduleLabel
                  title={aircraftRegistration}
                  variant="scheduleLabel"
                >
                  {aircraftRegistration}
                </ScheduleLabel>
                {sources?.length > 1 && (
                  <ScheduleLabel
                    title={aircraftRegistration}
                    variant="subtitle"
                  >
                    Strafos
                  </ScheduleLabel>
                )}
              </SwimlaneLabelContainer>
            )}
            {sources?.includes(ScheduleSource.FL3XX) && (
              <SwimlaneLabelContainer
                key={aircraftRegistration + '-fl3xx'}
                $divisionType={fl3xxDivisionType}
              >
                <ScheduleLabel
                  title={aircraftRegistration}
                  variant="scheduleLabel"
                >
                  {aircraftRegistration}
                </ScheduleLabel>
                {sources?.length > 1 && (
                  <ScheduleLabel
                    title={aircraftRegistration}
                    variant="subtitle"
                  >
                    FL3xx
                  </ScheduleLabel>
                )}
              </SwimlaneLabelContainer>
            )}
            {sources?.includes(ScheduleSource.LEON) && (
              <SwimlaneLabelContainer
                key={aircraftRegistration + '-leon'}
                $divisionType={leonDivisionType}
              >
                <ScheduleLabel
                  title={aircraftRegistration}
                  variant="scheduleLabel"
                >
                  {aircraftRegistration}
                </ScheduleLabel>
                {sources?.length > 1 && (
                  <ScheduleLabel
                    title={aircraftRegistration}
                    variant="subtitle"
                  >
                    Leon
                  </ScheduleLabel>
                )}
              </SwimlaneLabelContainer>
            )}
          </div>
        ))}
        {requestLegs && (
          <SwimlaneLabelContainer $divisionType={requestLegsDivisionType}>
            <ScheduleLabel title={requestTripId} variant="scheduleLabel">
              {t('molecules.Schedule.requestSwimlaneTitle')}
            </ScheduleLabel>
          </SwimlaneLabelContainer>
        )}
      </Labels>
      <Chart>
        <StyledTimeline
          viewStart={viewStart}
          viewEnd={viewEnd}
          breakpoints={breakpoints}
          dateFormat={dateTimeFormat}
          grid={timelineGrid}
        />
        {Object.entries(aircraftScheduleMap).map(
          ([aircraftRegistration, schedule]) => (
            <div key={aircraftRegistration}>
              {sources?.includes(ScheduleSource.STRAFOS) && (
                <StyledSwimlane
                  key={aircraftRegistration}
                  schedule={schedule.filter(
                    (s) => s.source === ScheduleSource.STRAFOS,
                  )}
                  viewStart={viewStart}
                  viewEnd={viewEnd}
                  breakpoints={breakpoints}
                  variant={ScheduleItemVariants.Schedule}
                  onEditOutage={onEditOutage}
                  onRemoveOutage={onRemoveOutage}
                  onNavigateToRequest={onNavigateToRequest}
                  onMarkLegAsRemoved={onMarkLegAsRemoved}
                  onConvertToMarketingLeg={
                    isMarketingLegsEnabled ? onConvertToMarketingLeg : undefined
                  }
                  onEditMarketingLeg={
                    isMarketingLegsEnabled ? onEditMarketingLeg : undefined
                  }
                  onRemoveMarketingLeg={
                    isMarketingLegsEnabled ? onRemoveMarketingLeg : undefined
                  }
                  divisionType={strafosDivisionType}
                  finalPriceAndTotalProfit={finalPriceAndProfit}
                />
              )}
              {sources?.includes(ScheduleSource.FL3XX) && (
                <StyledSwimlane
                  key={aircraftRegistration}
                  schedule={schedule.filter(
                    (s) => s.source === ScheduleSource.FL3XX,
                  )}
                  viewStart={viewStart}
                  viewEnd={viewEnd}
                  breakpoints={breakpoints}
                  variant={ScheduleItemVariants.Schedule}
                  onEditOutage={onEditOutage}
                  onRemoveOutage={onRemoveOutage}
                  onNavigateToRequest={onNavigateToRequest}
                  onMarkLegAsRemoved={onMarkLegAsRemoved}
                  onConvertToMarketingLeg={
                    isMarketingLegsEnabled ? onConvertToMarketingLeg : undefined
                  }
                  onEditMarketingLeg={
                    isMarketingLegsEnabled ? onEditMarketingLeg : undefined
                  }
                  onRemoveMarketingLeg={
                    isMarketingLegsEnabled ? onRemoveMarketingLeg : undefined
                  }
                  divisionType={fl3xxDivisionType}
                  finalPriceAndTotalProfit={finalPriceAndProfit}
                />
              )}
              {sources?.includes(ScheduleSource.LEON) && (
                <StyledSwimlane
                  key={aircraftRegistration}
                  schedule={schedule.filter(
                    (s) => s.source === ScheduleSource.LEON,
                  )}
                  viewStart={viewStart}
                  viewEnd={viewEnd}
                  breakpoints={breakpoints}
                  variant={ScheduleItemVariants.Schedule}
                  onEditOutage={onEditOutage}
                  onRemoveOutage={onRemoveOutage}
                  onNavigateToRequest={onNavigateToRequest}
                  onMarkLegAsRemoved={onMarkLegAsRemoved}
                  onConvertToMarketingLeg={
                    isMarketingLegsEnabled ? onConvertToMarketingLeg : undefined
                  }
                  onEditMarketingLeg={
                    isMarketingLegsEnabled ? onEditMarketingLeg : undefined
                  }
                  onRemoveMarketingLeg={
                    isMarketingLegsEnabled ? onRemoveMarketingLeg : undefined
                  }
                  divisionType={fl3xxDivisionType}
                  finalPriceAndTotalProfit={finalPriceAndProfit}
                />
              )}
            </div>
          ),
        )}
        {requestLegs && (
          <StyledSwimlane
            schedule={visibleRequestLegs ?? []}
            viewStart={viewStart}
            viewEnd={viewEnd}
            breakpoints={breakpoints}
            variant={ScheduleItemVariants.Request}
            requestTripId={requestTripId}
            offerStatus={offerStatus}
            divisionType={requestLegsDivisionType}
            finalPriceAndTotalProfit={finalPriceAndProfit}
          />
        )}
        <CurrentTimeIndicator
          viewEnd={viewEnd}
          viewStart={viewStart}
          color={theme.palette.error.dark}
        />
        {isMovable && (
          <>
            <PreviousIconButton onClick={handlePreviousClick}>
              <ChevronLeftIcon />
            </PreviousIconButton>
            <NextIconButton onClick={handleNextClick}>
              <ChevronRightIcon />
            </NextIconButton>
          </>
        )}
        {isZoomEnabled && (
          <ZoomControls>
            <ZoomIconButton onClick={handleZoomOutClick}>
              <StyledRemoveIcon />
            </ZoomIconButton>

            <ZoomIconButton onClick={handleZoomInClick}>
              <StyledAddIcon />
            </ZoomIconButton>

            <Box
              display="inline"
              ml="2px"
              visibility={isReloading ? 'visible' : 'hidden'}
            >
              <CircularProgress size={12} />
            </Box>
          </ZoomControls>
        )}

        {isLoading && (
          <LoadingOverlay>
            <LoadingSpinner loading size={40} />
          </LoadingOverlay>
        )}
      </Chart>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
`

const Labels = styled.div`
  flex: 0 1 6.5rem;
  max-width: 6.5rem;
`

const Chart = styled.div`
  position: relative;
  flex: 1 1 100%;
`

const StyledSwimlane = styled(Swimlane)`
  height: 3.5rem;
`

export enum DivisionType {
  soft = 'soft',
  hard = 'hard',
}

const SwimlaneLabelContainer = styled.div<{ $divisionType: DivisionType }>`
  height: 3.5rem;
  border-bottom: 1px solid
    ${({ theme, $divisionType }) =>
      theme.palette.grey[$divisionType === DivisionType.soft ? 100 : 200]};
`

const StyledTimeline = styled(Timeline)`
  height: 30px;
`

const TimelineLabelContainer = styled.div`
  height: 30px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};
`

const ScheduleLabel = styled(Typography)`
  padding: 0.5rem 0.5rem 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const CurrentTimeIndicator = styled(TimeIndicator)`
  z-index: 15;
`

const MoveIconButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
`

const NextIconButton = styled(MoveIconButton)`
  z-index: 20;
  right: 0;
`

const PreviousIconButton = styled(MoveIconButton)`
  z-index: 20;
  left: 0;
`

const ZoomControls = styled.div`
  position: absolute;
  right: 10px;
  top: 0;
  background: rgba(255, 255, 255, 0.5);
`

const ZoomIconButton = styled(IconButton)`
  padding: 0.2rem;
`

const StyledAddIcon = styled(AddIcon)`
  width: 1rem;
  height: 1rem;
`

const StyledRemoveIcon = styled(RemoveIcon)`
  width: 1rem;
  height: 1rem;
`

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`

const EmptyListMessageContainer = styled.div`
  margin: 4rem auto;
  display: flex;
  justify-content: center;
`

export default Schedule
