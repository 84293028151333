import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

const useContactPersonFormValidationSchema = () => {
  const { t } = useTranslation()

  return yup.object().shape({
    name: yup
      .string()
      .required(t('organisms.ContactPersonForm.nameRequired'))
      .typeError(t('organisms.ContactPersonForm.nameRequired')),

    email: yup
      .string()
      .required(t('organisms.ContactPersonForm.emailRequired'))
      .typeError(t('organisms.ContactPersonForm.emailRequired')),

    phone: yup
      .string()
      .nullable()
      .typeError(t('organisms.ContactPersonForm.phoneWrongType')),

    mobilePhone: yup
      .string()
      .nullable()
      .typeError(t('organisms.ContactPersonForm.mobilePhoneWrongType')),
  })
}

export default useContactPersonFormValidationSchema
