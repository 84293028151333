import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { ScheduleDetailDto } from '@shared/dto/schedule.dto'
import { PatchMarketplaceExtensionDto } from '@app/utils/api/types'
import { MarketplaceExtensionFormData } from '@app/components/organisms/MarketplaceExtensionForm/MarketplaceExtensionForm'
import { getCombinedDate } from '@shared/utils/dateUtils'

import {
  getISOStringIgnoringTimezone,
  getLocalDateIgnoringTimezone,
} from '@app/utils/dateUtils'

dayjs.extend(utc)

export const transformScheduleItemToMarketplaceExtensionFormData = (
  schedule: ScheduleDetailDto,
): MarketplaceExtensionFormData => {
  const fromDateTime = getLocalDateIgnoringTimezone(
    schedule.marketplace_departure_date ?? schedule.departure_date,
  )

  const toDateTime = getLocalDateIgnoringTimezone(
    schedule.marketplace_arrival_date ?? schedule.arrival_date,
  )

  return {
    fromDate: fromDateTime,
    fromTime: fromDateTime,
    toDate: toDateTime,
    toTime: toDateTime,
  }
}

export const transformMarketplaceExtensionFormDataToPatchMarketplaceExtensionDto =
  (formData: MarketplaceExtensionFormData): PatchMarketplaceExtensionDto => {
    const fromDateTime = getCombinedDate(
      new Date(formData.fromDate),
      new Date(formData.fromTime),
    )

    const toDateTime = getCombinedDate(
      new Date(formData.toDate),
      new Date(formData.toTime),
    )

    return {
      from: getISOStringIgnoringTimezone(fromDateTime),
      to: getISOStringIgnoringTimezone(toDateTime),
    }
  }
