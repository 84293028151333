import React from 'react'
import styled from 'styled-components'
import FlightOutlined from '@material-ui/icons/FlightOutlined'

interface FlightRouteIconProps {
  className?: string
  disabled: boolean
  airwayId?: number | null
  timeAndDistance: boolean
  isCustomRoute?: boolean
}
const FlightRouteIcon = ({
  className,
  disabled,
  airwayId,
  timeAndDistance,
}: FlightRouteIconProps) => {
  return (
    <Container className={className} disabled={disabled}>
      <Start />
      <Route />
      <StyledFlightOutlined
        airwayId={airwayId}
        timeAndDistance={timeAndDistance}
        isCustomRoute
      />
    </Container>
  )
}

const Container = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ disabled, theme }) =>
    disabled ? theme.palette.grey[200] : theme.palette.grey[800]};
`

const Start = styled.div`
  color: inherit;
  flex-grow: 0;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  border: 2px solid currentColor;
`

const Route = styled.div`
  color: inherit;
  flex-grow: 1;
  border-top: 1px dotted currentColor;
`

const StyledFlightOutlined = styled(FlightOutlined)<{
  airwayId?: number | null
  timeAndDistance: boolean
  isCustomRoute: boolean
}>`
  color: ${({ airwayId, timeAndDistance, theme, isCustomRoute }) =>
    timeAndDistance
      ? (airwayId !== null && isCustomRoute) ||
        (airwayId !== null && !isCustomRoute)
        ? '#0864fc'
        : airwayId === null && isCustomRoute
          ? '#6AA2FD'
          : theme.palette.grey[800]
      : theme.palette.grey[800]};
  transform: rotate(90deg);
  font-size: 1rem;
  position: relative;
  top: 1px;
`

export default FlightRouteIcon
