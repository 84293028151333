import React from 'react'
import { useTheme } from 'styled-components'

import Badge, { BadgeProps } from '@app/components/atoms/Badge/Badge'

type NewBadgeProps = Partial<BadgeProps>

const NewBadge = ({
  color,
  label = 'new',
  ...props
}: NewBadgeProps): JSX.Element => {
  const theme = useTheme()

  return <Badge color={color ?? theme.colors.orange} label={label} {...props} />
}

export default NewBadge
