import React, { useMemo } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import Typography from '@app/components/atoms/Typography/Typography'
import ChatMessageAvatar from '@app/components/molecules/ChatMessage/ChatMessageAvatar'
import ChatMessageBadge from '@app/components/molecules/ChatMessage/ChatMessageBadge'
import ChatMessageOfferInfo from '@app/components/molecules/ChatMessage/ChatMessageOfferInfo'

import { ChatMessageDto } from '@shared/dto/chat.dto'
import { useDateAndTimeFormats } from '@app/hooks/useDateAndTimeFormats'
import { ChatMessageTypes, OfferStatuses } from '@shared/enums'

interface ChatMessageProps {
  message: ChatMessageDto
  className?: string
}

const ChatMessage = ({ message, className }: ChatMessageProps) => {
  const { t } = useTranslation()
  const { dateTimeFormat } = useDateAndTimeFormats()

  const emailSentConfirmationMessage = useMemo(() => {
    switch (message.offer_status) {
      case OfferStatuses.Quoted:
        return t('molecules.ChatMessage.quotationEmailSent', {
          aircraft: message.offer_aircraft_registration,
        })

      case OfferStatuses.Booked:
        return t('molecules.ChatMessage.bookingEmailSent', {
          aircraft: message.offer_aircraft_registration,
        })

      default:
        t('molecules.ChatMessage.defaultEmailSent', {
          aircraft: message.offer_aircraft_registration,
        })
    }
  }, [message.offer_status])

  return (
    <Container className={className}>
      <Header>
        <StyledChatMessageAvatar message={message} />
        <DateText variant="smallDarkText">
          {dayjs(message.created_at).format(dateTimeFormat)}
        </DateText>
        <BadgeContainer>
          <ChatMessageBadge message={message} />
        </BadgeContainer>
      </Header>
      <Content>
        <Typography variant="secondary">{message.content}</Typography>
        {message.type === ChatMessageTypes.EmailSentConfirmationMessage && (
          <Typography variant="secondary">
            {emailSentConfirmationMessage}
          </Typography>
        )}
        {message.type === ChatMessageTypes.SystemMessage && (
          <ChatMessageOfferInfo message={message} />
        )}
      </Content>
    </Container>
  )
}

const Container = styled.div``

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
`

const StyledChatMessageAvatar = styled(ChatMessageAvatar)`
  margin-right: 1rem;
`

const DateText = styled(Typography)`
  flex: 1 1 auto;
`

const BadgeContainer = styled.div``

const Content = styled.div``

export default ChatMessage
