import React from 'react'
import { useTheme } from 'styled-components'

import Badge, { BadgeProps } from '@app/components/atoms/Badge/Badge'
import { LegTypes } from '@shared/enums'

type ReservedBadgeProps = Partial<BadgeProps> & {
  type?: LegTypes
}

const ReservedBadge = ({
  color,
  label = 'reserved',
  ...props
}: ReservedBadgeProps): JSX.Element => {
  const theme = useTheme()

  return <Badge color={color ?? theme.colors.purple} label={label} {...props} />
}

export default ReservedBadge
