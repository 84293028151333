import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { ScheduleDetailDto } from '@shared/dto/schedule.dto'
import { LegTypes, ScheduleSource } from '@shared/enums'

dayjs.extend(utc)

export const getPrecedingScheduleItem = (
  input: ScheduleDetailDto,
  schedule: ScheduleDetailDto[],
): ScheduleDetailDto | null => {
  const previousItem = schedule
    .concat()
    .filter(
      (scheduleItem) =>
        scheduleItem.type !== LegTypes.Removed &&
        scheduleItem.source !== ScheduleSource.FL3XX,
    )
    .sort(
      (a, b) =>
        new Date(b.departure_date).getTime() -
        new Date(a.departure_date).getTime(),
    )
    .find((scheduleItem) =>
      dayjs.utc(scheduleItem.departure_date).isBefore(input.departure_date),
    )

  return previousItem ?? null
}

export const getSucceedingScheduleItem = (
  input: ScheduleDetailDto,
  schedule: ScheduleDetailDto[],
): ScheduleDetailDto | null => {
  const previousItem = schedule
    .concat()
    .filter(
      (scheduleItem) =>
        scheduleItem.type !== LegTypes.Removed &&
        scheduleItem.source !== ScheduleSource.FL3XX,
    )
    .sort(
      (a, b) =>
        new Date(a.departure_date).getTime() -
        new Date(b.departure_date).getTime(),
    )
    .find((scheduleItem) =>
      dayjs.utc(scheduleItem.departure_date).isAfter(input.departure_date),
    )

  return previousItem ?? null
}
