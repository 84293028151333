import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import { DisplayTimeTypes } from '@shared/enums'
import { useDateAndTimeFormats } from '@app/hooks/useDateAndTimeFormats'
import { useActiveUser } from '@app/hooks/useActiveUser'

dayjs.extend(utc)
dayjs.extend(timezone)

/**
 * @todo Consider creating 'getReverseDate' hook instead
 */
const useGetFormattedDateBasedOnUserDisplaySettings = (reverse = false) => {
  const activeUser = useActiveUser()
  const formats = useDateAndTimeFormats()

  return (date: string | Date | Dayjs, timezone?: string) => {
    let result = dayjs.utc(date)

    const includeTimezone = reverse
      ? activeUser?.display_time_type === DisplayTimeTypes.UTC
      : activeUser?.display_time_type === DisplayTimeTypes.AirportLocalTime

    if (timezone && includeTimezone) {
      result = result.tz(timezone)
    }

    return {
      date: result.format(formats.dateFormat),
      time: result.format(formats.timeFormat),
      dateTime: result.format(formats.dateTimeFormat),
    }
  }
}

export default useGetFormattedDateBasedOnUserDisplaySettings
