import React from 'react'
import { useTheme } from 'styled-components'

import Tooltip, { TooltipProps } from '@app/components/atoms/Tooltip/Tooltip'

interface ErrorTooltipProps extends Omit<TooltipProps, 'title'> {
  title?: TooltipProps['title'] | null
}

const ErrorTooltip = ({
  backgroundColor,
  borderColor,
  ...props
}: ErrorTooltipProps): JSX.Element => {
  const theme = useTheme()

  return (
    <Tooltip
      {...props}
      backgroundColor={backgroundColor ?? theme.palette.error.main}
      borderColor={borderColor ?? theme.palette.error.dark}
      title={props.title ?? ''}
    />
  )
}

export default ErrorTooltip
