// @todo Add unit tests

import Big from 'big.js'

import { LegTypes, OfferTypes } from '@shared/enums'
import { LegDetailDto, OfferDetailDto } from '@shared/dto'
import { getLegOtherCosts } from '@shared/utils/price.utils'
import { assertUnreachable } from '@shared/utils/assertUnreachable'

type PartialLeg = Pick<
  LegDetailDto,
  | 'variable_cost'
  | 'profit'
  | 'airport_fee'
  | 'handling_fee'
  | 'catering_fee'
  | 'arrival_fee'
  | 'departure_fee'
  | 'passenger_count'
  | 'other_costs'
  | 'type'
  | 'remove_leg_id'
  | 'remove_leg'
  | 'fuel_cost'
  | 'duration_in_minutes'
>

interface LegFlight {
  type: LegTypes
  duration_in_minutes: number
}

// @todo Better name ??? Partial implies Pick<T, K>
type PartialOffer = {
  cancellation_offer?: OfferDetailDto
  rebooking_offer?: OfferDetailDto
  legs: PartialLeg[]
}

export const getOfferProfitLoss = (
  partialOffer: PartialOffer &
    Pick<
      OfferDetailDto,
      'type' | 'cancellation_offer_id' | 'rebooking_offer_id'
    >,
) => {
  const cancellationOffer = partialOffer.cancellation_offer
  const rebookingOffer = partialOffer.rebooking_offer

  switch (partialOffer.type) {
    case OfferTypes.Regular: {
      return Big(0)
    }

    case OfferTypes.Cancellation: {
      const targetLegs = cancellationOffer.legs.filter((cancellationLeg) => {
        const partialLegExistsInCancellationOffer = partialOffer.legs.some(
          (originalLeg) =>
            originalLeg.remove_leg?.leg_id === cancellationLeg.id,
        )

        return !partialLegExistsInCancellationOffer
      })

      return getRemovedLegsCosts(targetLegs).add(
        getOfferProfitLoss(cancellationOffer),
      )
    }

    case OfferTypes.Rebooking: {
      const targetLegs = rebookingOffer.legs.filter((rebookingLeg) => {
        const partialLegExistsInRebookingOffer = partialOffer.legs.some(
          (originalLeg) => originalLeg.remove_leg?.leg_id === rebookingLeg.id,
        )

        return !partialLegExistsInRebookingOffer
      })

      return getRemovedLegsCosts(targetLegs).add(
        getOfferProfitLoss(rebookingOffer),
      )
    }
  }
}

export const getOfferOptimizationProfit = (
  partialOffer: PartialOffer &
    Pick<
      OfferDetailDto,
      | 'type'
      | 'cancellation_offer'
      | 'cancellation_offer_id'
      | 'rebooking_offer_id'
    >,
) => {
  switch (partialOffer.type) {
    case OfferTypes.Cancellation:
      const cancellationLegs = partialOffer.legs.filter(
        (leg) =>
          leg.remove_leg?.offer_id !== partialOffer.cancellation_offer_id,
      )

      return getLegsOptimizationProfit(cancellationLegs).add(
        getOfferOptimizationProfit(partialOffer.cancellation_offer),
      )

    case OfferTypes.Rebooking:
      const rebookingLegs = partialOffer.legs.filter(
        (leg) => leg.remove_leg?.offer_id !== partialOffer.rebooking_offer_id,
      )

      return getLegsOptimizationProfit(rebookingLegs).add(
        getOfferOptimizationProfit(partialOffer.rebooking_offer),
      )

    case OfferTypes.Regular:
      return getLegsOptimizationProfit(partialOffer.legs)

    default:
      assertUnreachable(partialOffer.type)
  }
}

export const getOfferTotalProfit = (
  offer: PartialOffer &
    Pick<
      OfferDetailDto,
      | 'type'
      | 'cancellation_fee'
      | 'cancellation_offer'
      | 'cancellation_offer_id'
      | 'rebooking_offer_id'
    >,
) => {
  switch (offer.type) {
    case OfferTypes.Cancellation: {
      let totalProfit = Big(0)

      if (offer.rebooking_offer) {
        totalProfit = totalProfit.add(
          getOfferOptimizationProfit(offer.rebooking_offer),
        )
      }

      return totalProfit
        .add(getLegsProfitMargin(offer.legs))
        .add(offer.cancellation_fee ?? 0)
        .add(getOfferOptimizationProfit(offer))
        .minus(getLegsTripCosts(offer.legs))
        .minus(getRemovedLegsCosts(offer.legs))
        .minus(getOfferProfitLoss(offer))
    }

    case OfferTypes.Rebooking: {
      let totalProfit = Big(0)

      const rebookingLegs = offer.legs.filter(
        (leg) => leg.remove_leg?.offer_id !== offer.rebooking_offer_id,
      )

      totalProfit = totalProfit.add(getLegsTotalProfit(rebookingLegs))

      if (offer.rebooking_offer) {
        totalProfit = totalProfit.add(
          getOfferOptimizationProfit(offer.rebooking_offer),
        )
      }

      return totalProfit
    }
    case OfferTypes.Regular:
      return getLegsTotalProfit(offer.legs)

    default:
      assertUnreachable(offer.type)
  }
}

export const getOfferFinalPrice = (
  offer: PartialOffer &
    Pick<
      OfferDetailDto,
      'cancellation_offer_id' | 'rebooking_offer_id' | 'type'
    >,
  documentFilling?: boolean,
) => {
  switch (offer.type) {
    case OfferTypes.Cancellation:
    case OfferTypes.Rebooking:
      if (documentFilling)
        return getLegsProfitMargin(offer.legs)
          .add(getLegsTripCosts(offer.legs))
          .add(getRemovedLegsCosts(offer.legs))

      return getLegsProfitMargin(offer.legs)
        .add(getLegsTripCosts(offer.legs))
        .add(getRemovedLegsCosts(offer.legs))
        .add(getOfferProfitLoss(offer))

    case OfferTypes.Regular:
      return getLegsFinalPrice(offer.legs)

    default:
      assertUnreachable(offer.type)
  }
}

export const getIsLegRemoved = (leg: PartialLeg) =>
  leg.type === LegTypes.Removed

export const getIsThisLegRemoved = (leg: PartialLeg) =>
  Boolean(getIsLegRemoved(leg) && !leg.remove_leg_id)

export const getIsRemovingOtherLeg = (leg: PartialLeg) =>
  Boolean(getIsLegRemoved(leg) && leg.remove_leg_id)

export const getLegProfitMargin = (leg: PartialLeg): Big => Big(leg.profit)

export const getLegsProfitMargin = (legs: PartialLeg[]): Big =>
  legs
    .filter((leg) => !getIsLegRemoved(leg))
    .reduce((acc, cur) => acc.plus(getLegProfitMargin(cur)), Big(0))

export const getLegTripCosts = (leg: PartialLeg): Big => {
  return Big(leg.variable_cost)
    .plus(leg.fuel_cost || 0)
    .plus(leg.airport_fee)
    .plus(leg.handling_fee)
    .plus(leg.catering_fee)
    .plus(leg.departure_fee)
    .plus(leg.arrival_fee)
    .plus(getLegOtherCosts(leg))
}

export const getLegsTripCosts = (legs: PartialLeg[]): Big =>
  legs
    .filter((leg) => !getIsLegRemoved(leg))
    .reduce((acc, cur) => acc.plus(getLegTripCosts(cur)), Big(0))

export const getRemovedLegCost = (leg: PartialLeg): Big =>
  getIsThisLegRemoved(leg) ? getLegFinalPrice(leg) : Big(0)

export const getRemovedLegsCosts = (legs: PartialLeg[]): Big =>
  legs.reduce((acc, cur) => acc.plus(getRemovedLegCost(cur)), Big(0))

export const getLegFinalPrice = (leg: PartialLeg): Big =>
  getLegProfitMargin(leg).plus(getLegTripCosts(leg))

export const getLegsFinalPrice = (legs: PartialLeg[]): Big =>
  legs
    .filter((leg) => !getIsRemovingOtherLeg(leg))
    .reduce((acc, cur) => acc.plus(getLegFinalPrice(cur)), Big(0))

export const getLegOptimizationProfit = (leg: PartialLeg): Big =>
  getIsRemovingOtherLeg(leg) ? getLegFinalPrice(leg) : Big(0)

export const getLegsOptimizationProfit = (legs: PartialLeg[]): Big =>
  legs.reduce((acc, cur) => acc.plus(getLegOptimizationProfit(cur)), Big(0))

export const getLegTotalProfit = (leg: PartialLeg): Big => {
  if (getIsRemovingOtherLeg(leg)) {
    return getLegOptimizationProfit(leg)
  }

  if (getIsLegRemoved(leg)) {
    return Big(0)
  }

  return getLegProfitMargin(leg)
}

export const getLegsTotalProfit = (legs: PartialLeg[]): Big =>
  legs.reduce((acc, cur) => acc.plus(getLegTotalProfit(cur)), Big(0))

export const getLegsProfitRatio = (legs: PartialLeg[], cancellationFee = 0) => {
  const finalPrice = getLegsFinalPrice(legs).add(cancellationFee ?? 0)
  const totalProfit = getLegsTotalProfit(legs).add(cancellationFee ?? 0)

  const divisor = Math.max(1, finalPrice.toNumber())

  return totalProfit.div(divisor).times(100).round(2)
}

export const getLegsOccupiedFlightTime = (legs: LegFlight[]): Big => {
  const validLegTypes = [LegTypes.Occupied]

  return legs
    .filter((leg) => validLegTypes.includes(leg.type))
    .reduce((acc, cur) => acc.plus(cur.duration_in_minutes), Big(0))
}

export const getLegsEmptyFlightTime = (legs: LegFlight[]): Big => {
  const validLegTypes = [LegTypes.Empty, LegTypes.Ferry]

  return legs
    .filter((leg) => validLegTypes.includes(leg.type))
    .reduce((acc, cur) => acc.plus(cur.duration_in_minutes), Big(0))
}

export const getOfferFinalPriceForClient = (
  offer: PartialOffer &
    Pick<
      OfferDetailDto,
      | 'cancellation_offer_id'
      | 'rebooking_offer_id'
      | 'type'
      | 'cancellation_fee'
    >,
) => {
  if (
    offer.type === OfferTypes.Cancellation &&
    Number.isFinite(offer.cancellation_fee)
  ) {
    return Big(offer.cancellation_fee)
  }

  return getOfferFinalPrice(offer)
}
