import { CellProps } from 'react-table'
import { PartialRequestDto } from '@shared/dto/requests.dto'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import Tooltip from '@app/components/atoms/Tooltip/Tooltip'
import React from 'react'
import {
  OffersTooltipTitle,
  OffersTooltipTypography,
  StyledBadge,
} from '@app/components/molecules/RequestsGridView/Cells'

export const AircraftCountCell = ({
  cell,
}: CellProps<PartialRequestDto, undefined>) => {
  const { t } = useTranslation()

  const aircraftCount = Array.from(
    new Set(
      cell.row.original.offers.map(
        (offer) => offer.aircraft?.registration_code,
      ),
    ),
  ).length

  if (aircraftCount === 1) {
    return null
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Tooltip
        title={
          <Box px="0.5rem">
            <OffersTooltipTitle>
              {t('molecules.RequestsGridView.offersTooltip.title')}
            </OffersTooltipTitle>

            {cell.row.original.offers.map((offer, index) => (
              <Box>
                <Box display="flex" py="0.5rem">
                  <Box width="6.25rem">
                    <OffersTooltipTypography>
                      {t(
                        'molecules.RequestsGridView.offersTooltip.statusLabel',
                      )}
                    </OffersTooltipTypography>

                    <OffersTooltipTypography>
                      {t(
                        'molecules.RequestsGridView.offersTooltip.aircraftLabel',
                      )}
                    </OffersTooltipTypography>
                  </Box>

                  <div>
                    <OffersTooltipTypography>
                      {offer.status}
                    </OffersTooltipTypography>

                    <OffersTooltipTypography>
                      {offer.aircraft.registration_code}
                    </OffersTooltipTypography>
                  </div>
                </Box>

                {index !== cell.row.original.offers.length - 1 && <hr />}
              </Box>
            ))}
          </Box>
        }
      >
        <StyledBadge
          $count={aircraftCount}
          badgeContent={aircraftCount}
          classes={{ badge: 'Badge__Badge' }}
        />
      </Tooltip>
    </Box>
  )
}
