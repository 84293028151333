import { selectAirportNotes } from '@app/store/api/airportNotes/airportNotes.selectors'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

export const useAirportNote = () => {
  const airportNotes = useSelector(selectAirportNotes)

  return useCallback(
    (icao: string) => {
      return airportNotes.find((note) => note.airport?.icao_code === icao)?.text
    },
    [airportNotes],
  )
}
