import styled from 'styled-components'
import Price from '@app/components/atoms/Price/Price'
import Tooltip from '@app/components/atoms/Tooltip/Tooltip'
import FlagIcon from '@app/v2/components/atoms/FlagIcon/FlagIcon'
import Typography from '@app/components/atoms/Typography/Typography'
import Badge from '@material-ui/core/Badge'

export const Profit = styled(Price)`
  margin: 0 0.5rem 0 0;
`

/**
 * @todo [Tech] Native way of doing this ???
 */
export const StyledBadge = styled(Badge)<{ $count: number }>`
  .Badge__Badge {
    border: 1px solid
      ${({ $count, theme }) =>
        $count > 1 ? theme.colors.red : theme.colors.grey};

    color: ${({ $count, theme }) =>
      $count > 1 ? theme.colors.red : theme.colors.grey};
  }
`

export const OffersTooltipTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 1rem;
  font-weight: bold;
  padding: 1rem 0 0 0;
  text-align: center;
`

export const OffersTooltipTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 0.8rem;
  padding: 0 0 0.1rem 0;
`
export const StyledFlagIconWOColor = styled(FlagIcon)`
  display: block;
  font-size: 1rem;
  margin: 0 0.1rem;
`

export const StyledFlagIcon = styled(StyledFlagIconWOColor)`
  color: ${({ theme }) => theme.palette.primary.main};
  display: block;
  font-size: 1rem;
  margin: 0 0.1rem;
`

export const StyledTooltip = styled(Tooltip)<{ $isActive: boolean }>`
  visibility: ${({ $isActive }) => !$isActive && 'hidden'};
`
