import React from 'react'
import styled from 'styled-components'

import CloseIcon from '@material-ui/icons/Close'
import Chip, { ChipProps } from '@material-ui/core/Chip'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

import { lighten } from '@material-ui/core/styles'

const FilterItem = (props: ChipProps): JSX.Element => {
  return (
    <StyledChip
      {...props}
      deleteIcon={props.deleteIcon ?? <StyledCloseIcon />}
    />
  )
}

const StyledChip = styled(Chip)<ChipProps>`
  border-radius: 0.2rem;
  color: ${({ theme }) => theme.palette.grey[800]};
  font-family: ${({ theme }) => theme.font.secondary};
  font-size: 1rem;

  background-color: ${({ theme }) => lighten(theme.palette.primary.main, 0.8)};

  &:hover,
  &:focus {
    background-color: ${({ theme }) =>
      lighten(theme.palette.primary.main, 0.7)};
  }
`

const StyledCloseIcon = styled(CloseIcon)<SvgIconProps>`
  color: ${({ theme }) => theme.palette.primary.main};
  height: 1rem;
  width: 1rem;

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

export default FilterItem
