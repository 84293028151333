import React, { ChangeEvent, ReactNode } from 'react'
import styled from 'styled-components'

import BaseTabs, { TabsProps as BaseTabsProps } from '@material-ui/core/Tabs'
import BaseTab, { TabProps as BaseTabProps } from '@material-ui/core/Tab'

// @see https://github.com/mui-org/material-ui/issues/17454
export interface TabsProps extends Omit<BaseTabsProps, 'onChange'> {
  onChange: (
    event: ChangeEvent<Record<string, unknown>>,
    nextValue: number,
  ) => void
  vertical?: boolean
}

interface StyledTabsProps extends Omit<TabsProps, 'vertical'> {
  $vertical?: boolean
}

const Tabs = ({ vertical, ...props }: TabsProps): JSX.Element => (
  <StyledTabs
    $vertical={vertical}
    {...props}
    classes={{
      indicator: 'Tabs__indicator',
      scrollButtons: CLASS_NAME_SCROLL_BUTTONS,
    }}
  />
)

export interface TabProps extends BaseTabProps {
  vertical?: boolean
}

interface StyledTabProps extends Omit<TabProps, 'vertical'> {
  $vertical: boolean
}

export const CLASS_NAME_TAB_SELECTED = 'Tab__selected'
const CLASS_NAME_SCROLL_BUTTONS = 'Tabs__scrollButtons'

export const Tab = ({
  classes,
  vertical = false,
  ...props
}: TabProps): JSX.Element => (
  <StyledTab
    {...props}
    $vertical={vertical}
    classes={
      classes ?? {
        root: 'Tab__root',
        selected: CLASS_NAME_TAB_SELECTED,
      }
    }
  />
)

export interface TabPanelProps {
  value: number
  index: number
  children: ReactNode
}

const TAB_BORDER_WIDTH = '1px'
const TAB_MARGIN = '0.25rem'

export const TabPanel = ({
  value,
  index,
  children,
  ...props
}: TabPanelProps): JSX.Element | null =>
  value === index ? (
    <div {...props} data-tab-panel-index={index}>
      {children}
    </div>
  ) : null

const StyledTabs = styled(BaseTabs)<StyledTabsProps>`
  display: flex;

  & .Tabs__indicator {
    display: none;
  }

  & .${CLASS_NAME_SCROLL_BUTTONS} {
    &.Mui-disabled {
      opacity: 1;
      color: transparent;
    }

    ${({ $vertical, theme }) => {
      if ($vertical) {
        return `
            border-right: ${TAB_BORDER_WIDTH} solid ${theme.palette.grey[200]};
          `
      }

      return `
          border-bottom: ${TAB_BORDER_WIDTH} solid ${theme.palette.grey[200]};
        `
    }}
  }
`

const StyledTab = styled(BaseTab)<StyledTabProps>`
  border: ${TAB_BORDER_WIDTH} solid ${({ theme }) => theme.palette.grey[200]};
  border-radius: 2px;
  color: ${({ theme }) => theme.palette.primary.main};
  opacity: 1;
  overflow: initial;
  text-transform: none;
  min-width: 0;
  transition: border-bottom-color linear 0.25s;

  ${({ $vertical, theme }) =>
    $vertical
      ? `
        border-right: ${TAB_BORDER_WIDTH} solid ${theme.palette.grey[200]};
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin: ${TAB_MARGIN} 0;`
      : `
        border-bottom: ${TAB_BORDER_WIDTH} solid ${theme.palette.grey[200]};
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        margin: 0 ${TAB_MARGIN};`}

  &.${CLASS_NAME_TAB_SELECTED} {
    color: ${({ theme }) => theme.palette.common.black};
    font-weight: bold;

    ${({ $vertical }) =>
      $vertical
        ? `
      border-right: ${TAB_BORDER_WIDTH} solid transparent;
    `
        : `
      border-bottom: ${TAB_BORDER_WIDTH} solid transparent;
    `}
  }

  &:before {
    content: '';
    position: absolute;
    z-index: 10000;

    ${({ $vertical, theme }) =>
      $vertical
        ? `
                  height: ${TAB_MARGIN};
                  top: calc(-${TAB_MARGIN} - ${TAB_BORDER_WIDTH});
                  right: -${TAB_BORDER_WIDTH};
                  border-right: ${TAB_BORDER_WIDTH} solid ${theme.palette.grey[200]};`
        : `
                  width: ${TAB_MARGIN};
                  left: calc(-${TAB_MARGIN} - ${TAB_BORDER_WIDTH});
                  bottom: -${TAB_BORDER_WIDTH};
                  border-bottom: ${TAB_BORDER_WIDTH} solid ${theme.palette.grey[200]};`}
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 10000;

    ${({ $vertical, theme }) =>
      $vertical
        ? `
                  height: ${TAB_MARGIN};
                  bottom: calc(-${TAB_MARGIN} - ${TAB_BORDER_WIDTH});
                  right: -${TAB_BORDER_WIDTH};
                  border-right: ${TAB_BORDER_WIDTH} solid ${theme.palette.grey[200]};`
        : `
                  width: ${TAB_MARGIN};
                  right: calc(-${TAB_MARGIN} - ${TAB_BORDER_WIDTH});
                  bottom: -${TAB_BORDER_WIDTH};
                  border-bottom: ${TAB_BORDER_WIDTH} solid ${theme.palette.grey[200]};`}
  }
`

export default Tabs
