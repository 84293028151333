import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { RequestPageVariants } from '@app/components/pages/Requests/Requests'
import Select from '@app/components/atoms/Select/Select'
import SingleDatePicker from '@app/components/atoms/SingleDatePicker/SingleDatePicker'
import TextField from '@app/components/atoms/TextField/TextField'
import FilterItem from '@app/components/atoms/FilterItem/FilterItem'

import { assertUnreachable } from '@shared/utils/assertUnreachable'
import { GetRequestsListFilters } from '@app/utils/api/types'
import { OfferFlags, OfferStatuses } from '@shared/enums'
import { DEFAULT_REQUEST_DEBOUNCE_IN_MILLISECONDS } from '@app/constants'
import { selectUserInfo } from '@app/store/core/userInfo/userInfo.selectors'

import {
  getISOStringIgnoringTimezone,
  getLocalDateIgnoringTimezone,
} from '@app/utils/dateUtils'
import Switch from '@app/components/atoms/Switch/Switch'
import { ListItemText } from '@material-ui/core'
import { colors } from '@app/components/pages/Fleet/Warnings/types'
dayjs.extend(utc)

export interface RequestsFiltersProps {
  filters: GetRequestsListFilters
  onChange: (
    partialNextFilters: Partial<GetRequestsListFilters>,
    debounceInMilliseconds?: number,
  ) => void
  variant: RequestPageVariants
}

const RequestsFilters = ({
  filters,
  onChange,
  variant,
}: RequestsFiltersProps): JSX.Element => {
  const { t } = useTranslation()
  const userInfo = useSelector(selectUserInfo)

  const [warningOptions, setwarningOptions] = useState<
    { name: string; value: string }[]
  >([])

  useEffect(() => {
    const toAdd = [
      {
        name: 'Cabotage',
        value: 'cabotage',
      },
      ...colors,
    ]

    setwarningOptions(toAdd)
  }, [])

  const statusOptions = useMemo(() => {
    switch (variant) {
      case RequestPageVariants.Requests:
        return [
          { label: t('enums.OfferStatuses.new'), value: OfferStatuses.New },
          {
            label: t('enums.OfferStatuses.unhandled'),
            value: OfferStatuses.Unhandled,
          },
          { label: t('enums.OfferStatuses.draft'), value: OfferStatuses.Draft },
          {
            label: t('enums.OfferStatuses.quoted'),
            value: OfferStatuses.Quoted,
          },
          {
            label: t('enums.OfferStatuses.booked'),
            value: OfferStatuses.Booked,
          },
          {
            label: t('enums.OfferStatuses.declined'),
            value: OfferStatuses.Declined,
          },
          {
            label: t('enums.OfferStatuses.cancelled'),
            value: OfferStatuses.Cancelled,
          },
          {
            label: t('enums.OfferStatuses.rejected'),
            value: OfferStatuses.Rejected,
          },
          {
            label: t('enums.OfferStatuses.storno'),
            value: OfferStatuses.Storno,
          },
          {
            label: t('enums.OfferStatuses.bookedCancelled'),
            value: OfferStatuses.BookedCancelled,
          },
        ]

      case RequestPageVariants.Bookings:
        return [
          {
            label: t('enums.OfferStatuses.booked'),
            value: OfferStatuses.Booked,
          },
          {
            label: t('enums.OfferStatuses.bookedCancelled'),
            value: OfferStatuses.BookedCancelled,
          },
        ]

      default:
        assertUnreachable(variant)
    }
  }, [variant])

  const flagsOptions = [
    {
      label: t('enums.OfferFlags.conflict'),
      value: OfferFlags.Conflict,
    },
    {
      label: t('enums.OfferFlags.profitOpportunity'),
      value: OfferFlags.ProfitOpportunity,
    },
    {
      label: t('enums.OfferFlags.profitReduced'),
      value: OfferFlags.ProfitReduced,
    },
    {
      label: t('enums.OfferFlags.profitLost'),
      value: OfferFlags.ProfitLost,
    },
    {
      label: t('enums.OfferFlags.emptyLeg'),
      value: OfferFlags.EmptyLeg,
    },
    {
      label: t('enums.OfferFlags.newMessages'),
      value: OfferFlags.NewMessage,
    },
  ]

  return (
    <Container>
      <div>
        <StyledSelect<
          { label: string; value: OfferStatuses },
          true,
          false,
          false
        >
          label={t('molecules.RequestsFilters.statusLabel')}
          placeholder={t('molecules.RequestsFilters.statusPlaceholder')}
          options={statusOptions}
          value={statusOptions.filter(
            (option) => filters.statuses?.includes(option.value),
          )}
          renderOption={(row) => row.label}
          getOptionLabel={(row) => row.label}
          onChange={(event, nextOptions) => {
            onChange({ statuses: nextOptions?.map((option) => option.value) })
          }}
          inputProps={{
            'data-testid': 'RequestsFilters__status-input',
          }}
          multiple
        />
        <OptionsContainer>
          {filters.statuses?.map((filterValue) => {
            const option = statusOptions.find(
              (option) => option.value === filterValue,
            )

            return (
              <StyledFilterItem
                key={filterValue}
                onDelete={() =>
                  onChange({
                    statuses: filters.statuses?.filter(
                      (current) => current !== filterValue,
                    ),
                  })
                }
                label={option?.label ?? filterValue}
              />
            )
          })}
        </OptionsContainer>
      </div>

      <SingleDatePicker
        disablePast={false}
        value={
          filters.created_date_start
            ? getLocalDateIgnoringTimezone(filters.created_date_start)
            : null
        }
        onChange={(nextValue) => {
          if (!nextValue) {
            return onChange({
              created_date_start: undefined,
            })
          }

          const dateIgnoringTimezone = getISOStringIgnoringTimezone(nextValue)

          const startOfDay = dayjs
            .utc(dateIgnoringTimezone)
            .startOf('day')
            .toISOString()

          onChange({
            created_date_start: startOfDay,
          })
        }}
        placeholder={t('molecules.RequestsFilters.createdDateStartPlaceholder')}
        TextFieldProps={{
          label: t('molecules.RequestsFilters.createdDateStartLabel'),
          inputProps: {
            'data-testid': 'RequestsFilters__created_date_start-input',
          },
        }}
      />

      <SingleDatePicker
        disablePast={false}
        value={
          filters.created_date_end
            ? getLocalDateIgnoringTimezone(filters.created_date_end)
            : null
        }
        onChange={(nextValue) => {
          if (!nextValue) {
            return onChange({
              created_date_end: undefined,
            })
          }

          const dateIgnoringTimezone = getISOStringIgnoringTimezone(nextValue)

          const endOfDay = dayjs
            .utc(dateIgnoringTimezone)
            .endOf('day')
            .toISOString()

          onChange({
            created_date_end: endOfDay,
          })
        }}
        placeholder={t('molecules.RequestsFilters.createdDateEndPlaceholder')}
        TextFieldProps={{
          label: t('molecules.RequestsFilters.createdDateEndLabel'),
          inputProps: {
            'data-testid': 'RequestsFilters__created_date_end-input',
          },
        }}
      />

      {variant === RequestPageVariants.Requests && (
        <div>
          <StyledSelect<
            { label: string; value: OfferFlags },
            true,
            false,
            false
          >
            label={t('molecules.RequestsFilters.flagsLabel')}
            placeholder={t('molecules.RequestsFilters.flagsPlaceholder')}
            options={flagsOptions}
            value={flagsOptions.filter(
              (option) => filters.flags?.includes(option.value),
            )}
            renderOption={(row) => row.label}
            getOptionLabel={(row) => row.label}
            onChange={(event, nextOptions) => {
              onChange({ flags: nextOptions?.map((option) => option.value) })
            }}
            inputProps={{
              'data-testid': 'RequestsFilters__flags-input',
            }}
            multiple
          />
          <OptionsContainer>
            {filters.flags?.map((filterValue) => {
              const option = flagsOptions.find(
                (option) => option.value === filterValue,
              )

              return (
                <StyledFilterItem
                  key={filterValue}
                  onDelete={() =>
                    onChange({
                      flags: filters.flags?.filter(
                        (current) => current !== filterValue,
                      ),
                    })
                  }
                  label={option?.label ?? filterValue}
                />
              )
            })}
          </OptionsContainer>
        </div>
      )}

      <TextField
        label={t('molecules.RequestsFilters.requestorLabel')}
        placeholder={t('molecules.RequestsFilters.requestorPlaceholder')}
        value={filters.requestor ?? ''}
        inputProps={{
          'data-testid': 'RequestsFilters__requestor-input',
        }}
        onChange={(event) => {
          onChange(
            { requestor: event.target.value },
            DEFAULT_REQUEST_DEBOUNCE_IN_MILLISECONDS,
          )
        }}
      />

      <SingleDatePicker
        disablePast={false}
        value={
          filters.departure_date_start
            ? getLocalDateIgnoringTimezone(filters.departure_date_start)
            : null
        }
        onChange={(nextValue) => {
          if (!nextValue) {
            return onChange({
              display_time_type: userInfo?.display_time_type ?? undefined,
              departure_date_start: undefined,
            })
          }

          const dateIgnoringTimezone = getISOStringIgnoringTimezone(nextValue)

          const startOfDay = dayjs
            .utc(dateIgnoringTimezone)
            .startOf('day')
            .toISOString()

          onChange({
            display_time_type: userInfo?.display_time_type ?? undefined,
            departure_date_start: startOfDay,
          })
        }}
        placeholder={t(
          'molecules.RequestsFilters.departureDateStartPlaceholder',
        )}
        TextFieldProps={{
          label: t('molecules.RequestsFilters.departureDateStartLabel'),
          inputProps: {
            'data-testid': 'RequestsFilters__departure-date-start-input',
          },
        }}
      />

      <SingleDatePicker
        disablePast={false}
        value={
          filters.departure_date_end
            ? getLocalDateIgnoringTimezone(filters.departure_date_end)
            : null
        }
        onChange={(nextValue) => {
          if (!nextValue) {
            return onChange({
              display_time_type: userInfo?.display_time_type ?? undefined,
              departure_date_end: undefined,
            })
          }

          const dateIgnoringTimezone = getISOStringIgnoringTimezone(nextValue)

          const endOfDay = dayjs
            .utc(dateIgnoringTimezone)
            .endOf('day')
            .toISOString()

          onChange({
            display_time_type: userInfo?.display_time_type ?? undefined,
            departure_date_end: endOfDay,
          })
        }}
        placeholder={t('molecules.RequestsFilters.departureDateEndPlaceholder')}
        TextFieldProps={{
          label: t('molecules.RequestsFilters.departureDateEndLabel'),
          inputProps: {
            'data-testid': 'RequestsFilters__departure-date-end-input',
          },
        }}
      />

      <TextField
        label={t('molecules.RequestsFilters.tripIdLabel')}
        placeholder={t('molecules.RequestsFilters.tripIdPlaceholder')}
        value={filters.trip_id ?? ''}
        inputProps={{
          'data-testid': 'RequestsFilters__trip-id-input',
        }}
        onChange={(event) => {
          onChange(
            { trip_id: event.target.value },
            DEFAULT_REQUEST_DEBOUNCE_IN_MILLISECONDS,
          )
        }}
      />

      <TextField
        label={t('molecules.RequestsFilters.aircraftLabel')}
        placeholder={t('molecules.RequestsFilters.aircraftPlaceholder')}
        value={filters.aircraft ?? ''}
        inputProps={{
          'data-testid': 'RequestsFilters__aircraft-input',
        }}
        onChange={(event) => {
          onChange(
            { aircraft: event.target.value },
            DEFAULT_REQUEST_DEBOUNCE_IN_MILLISECONDS,
          )
        }}
      />

      <TextField
        label={t('molecules.RequestsFilters.airportLabel')}
        placeholder={t('molecules.RequestsFilters.airportPlaceholder')}
        defaultValue={filters.airport}
        value={filters.airport ?? ''}
        onChange={(event) =>
          onChange(
            { airport: event.target.value },
            DEFAULT_REQUEST_DEBOUNCE_IN_MILLISECONDS,
          )
        }
        inputProps={{
          'data-testid': 'RequestsFilters__full-text-search-input',
        }}
      />

      <div>
        <Select<
          {
            name: string
            value: string
          },
          false,
          false,
          false
        >
          label={t('molecules.RequestsFilters.warningsLabel')}
          placeholder={t('molecules.RequestsFilters.warningsPlaceholder')}
          options={warningOptions}
          value={warningOptions.find(
            (option) => option.value === filters.warnings,
          )}
          renderOption={(row) => (
            <>
              <div
                style={{
                  backgroundColor: row.value.startsWith('#')
                    ? row.value
                    : 'black',
                  width: 12,
                  height: 12,
                  borderRadius: '50%',
                  marginRight: 8,
                }}
              />
              <ListItemText primary={row.name} />
            </>
          )}
          getOptionLabel={(row) => row.name}
          onChange={(event, nextOptions) => {
            onChange({ warnings: nextOptions?.value })
          }}
          inputProps={{
            'data-testid': 'RequestsFilters__warnings-input',
          }}
        />
      </div>

      <div>
        <ReservationLabel>
          {t('molecules.RequestsFilters.reservationLabel')}
        </ReservationLabel>
        <ReservationContainer>
          <RerenderOnChange watch={filters.reserved} delay={300}>
            <Switch
              data-testid="RequestsFilters__reservations-switch"
              name={'reservations'}
              checked={filters.reserved}
              onChange={(e, checked) => {
                onChange(
                  { reserved: checked },
                  DEFAULT_REQUEST_DEBOUNCE_IN_MILLISECONDS,
                )
              }}
              disabled={
                filters.statuses &&
                filters.statuses?.length > 0 &&
                !filters.statuses.includes(OfferStatuses.Quoted)
              }
            />
          </RerenderOnChange>
        </ReservationContainer>
      </div>
    </Container>
  )
}

const mdBreakpoint = '1500px'
const lgBreakpoint = '1900px'

const Container = styled.div`
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  gap: 1rem;
  transition: all 0.5s;

  @media (min-width: ${mdBreakpoint}) {
    flex-wrap: nowrap;
    gap: 1rem;
  }

  @media (min-width: ${lgBreakpoint}) {
    gap: 2rem;
  }

  & > * {
    flex: 1;
    flex-basis: calc(50% - 5px);
    max-width: 10rem;
  }

  @media (min-width: ${lgBreakpoint}) {
    & > * {
      max-width: 12.5rem;
    }
  }
`

const ReservationLabel = styled.div`
  font-size: 0.75rem;
  position: relative;
  transform: none;
  color: rgb(57, 57, 57);
`
const ReservationContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  padding-left: 0;
  margin-top: 0.5rem;
`

const StyledSelect = styled(Select)`
  @media (min-width: ${mdBreakpoint}) {
    max-width: 10rem;
  }

  @media (min-width: ${lgBreakpoint}) {
    width: 12.5rem;
  }
` as typeof Select

const StyledFilterItem = styled(FilterItem)`
  margin: 0 0.5rem 0.5rem 0;
`

const OptionsContainer = styled.div`
  margin-top: 0.5rem;
`

function RerenderOnChange({
  children,
  watch,
  delay = 200,
}: PropsWithChildren<{ watch: unknown; delay: number }>) {
  const [showChildren, setShowChildren] = useState(true)

  useEffect(() => {
    const to1 = setTimeout(() => setShowChildren(false), delay)
    const to2 = setTimeout(() => setShowChildren(true), delay + 10)

    return () => {
      clearTimeout(to1)
      clearTimeout(to2)
    }
  }, [watch])

  if (showChildren) {
    return <>{children}</>
  }
  return null
}

export default RequestsFilters
