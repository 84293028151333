import { LegDetailDto } from '@shared/dto/requests.dto'
import { api } from '@app/utils/api/api'
import { useCallback, useEffect, useState } from 'react'
import { LatLng } from 'leaflet'
import { useQuery } from '@tanstack/react-query'

interface Props {
  legs: LegDetailDto[]
}

export interface LegPathPoints {
  [key: string]: LatLng
}

export const useMapFlightPoints = ({ legs }: Props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [mapPointsData, setMapPointsData] = useState<LegPathPoints | null>(null)
  const airwayLegs: number[] = []
  legs.forEach((leg) => (leg.airway_id ? airwayLegs.push(leg.airway_id) : null))
  const {
    data,
    isLoading: isAirwayLoading,
    error: airwayError,
  } = useQuery(
    legs.map((leg) => leg.id),
    () => api.getMultipleAirways(airwayLegs).then((res) => res.data),
  )

  const getMapPoints = useCallback(() => {
    if (!isAirwayLoading) {
      const fetchFlightPoints = (leg: LegDetailDto) => {
        if (
          !airwayError &&
          !!data &&
          data?.some((airway) => airway.id === leg.airway_id)
        ) {
          const airway = data.find((airway) => airway.id === leg.airway_id)
          if (airway?.path) {
            const newFlightPoints = airway.path.map((path) => [
              path.la,
              path.lo,
            ])
            setMapPointsData((prevMapPointsData) => ({
              ...prevMapPointsData,
              [leg.id]: newFlightPoints,
            }))
          }
        } else {
          const startPoint = [
            leg.departure_airport.latitude,
            leg.departure_airport.longitude,
          ]
          const endPoint = [
            leg.arrival_airport.latitude,
            leg.arrival_airport.longitude,
          ]
          setMapPointsData((prevMapPointsData) => ({
            ...prevMapPointsData,
            [leg.id]: [startPoint, endPoint] as unknown as LatLng,
          }))
        }
      }

      legs.forEach((leg) => {
        fetchFlightPoints(leg)
      })
    }
  }, [legs, data, isAirwayLoading])

  useEffect(() => {
    getMapPoints()
  }, [data, isAirwayLoading])

  useEffect(() => {
    if (
      !!mapPointsData &&
      Object.keys(mapPointsData).length === legs.length &&
      Object.values(mapPointsData).every((value) => !!value)
    ) {
      setIsLoading(false)
    }
  }, [mapPointsData, legs])

  return {
    isLoading,
    mapPointsData,
  }
}
