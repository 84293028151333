import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Typography from '@app/components/atoms/Typography/Typography'
import Button from '@app/components/atoms/Button/Button'

import { ClientDetailDto } from '@shared/dto/clients.dto'
import { ContactPersonDetailDto } from '@shared/dto/contactPerson.dto'

export interface CustomerSelectorSelectedStateProps {
  customer?: ClientDetailDto
  contactPerson?: ContactPersonDetailDto
  onChangeCustomerClick: () => void
  className?: string
  disabled?: boolean
}

const CustomerSelectorSelectedState = ({
  customer,
  contactPerson,
  onChangeCustomerClick,
  className,
  disabled,
}: CustomerSelectorSelectedStateProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Container className={className}>
      <Heading>{t('molecules.CustomerSelectorSelectedState.heading')}</Heading>
      <Content>
        <div>
          {customer && (
            <InfoBlock>
              <Typography>{customer.company_name}</Typography>
              <SmallText>{customer.street}</SmallText>
              <SmallText>{customer.city}</SmallText>
            </InfoBlock>
          )}
          {contactPerson && (
            <InfoBlock>
              <Typography>{contactPerson.name}</Typography>
              <SmallText>{contactPerson.email}</SmallText>
              <SmallText>{contactPerson.phone}</SmallText>
            </InfoBlock>
          )}
        </div>
        <Controls>
          <Button
            inverted
            onClick={onChangeCustomerClick}
            disabled={disabled}
            data-testid="CustomerSelectorSelectedState__change-customer-button"
          >
            {t('molecules.CustomerSelectorSelectedState.actionButtonText')}
          </Button>
        </Controls>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  padding: 2rem;
`

const Heading = styled(Typography)`
  font-weight: bold;
  margin-bottom: 1rem;
`

const SmallText = styled(Typography)`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.palette.grey[400]};
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

const InfoBlock = styled.div`
  &:first-child {
    margin-bottom: 1rem;
  }
`

const Controls = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
`

export default CustomerSelectorSelectedState
