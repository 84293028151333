import {
  FieldPath,
  useController,
  UseControllerProps,
  UseControllerReturn,
  UseFormTrigger,
} from 'react-hook-form'

interface UseControllerWithValidationTriggerProps<
  TFieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  trigger: UseFormTrigger<TFieldValues>
  hasError: boolean
}

// see https://github.com/react-hook-form/react-hook-form/issues/2369
export const useControllerWithValidationTrigger = <
  TFieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  hasError,
  trigger,
  ...useControllerProps
}: UseControllerWithValidationTriggerProps<
  TFieldValues,
  TName
>): UseControllerReturn<TFieldValues, TName> => {
  const { field, ...rest } = useController<TFieldValues, TName>(
    useControllerProps,
  )

  return {
    ...rest,
    field: {
      ...field,
      onChange: (...args) => {
        field.onChange(...args)

        if (hasError) {
          trigger()
        }
      },
    },
  }
}
