import React from 'react'
import styled from 'styled-components'
import { Apartment } from '@material-ui/icons'
import { Trans, useTranslation } from 'react-i18next'

import Typography from '@app/components/atoms/Typography/Typography'

export interface CustomerSelectorInitialStateProps {
  onSelectExistingClick: () => void
  onCreateNewClick?: () => void
  className?: string
  disabled?: boolean
}

const CustomerSelectorInitialState = ({
  onSelectExistingClick,
  onCreateNewClick,
  className,
  disabled,
}: CustomerSelectorInitialStateProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Container className={className}>
      <Heading>{t('molecules.CustomerSelectorInitialState.heading')}</Heading>
      <Content>
        <StyledIcon />
        <CenteredText>
          {t('molecules.CustomerSelectorInitialState.infoText')}
        </CenteredText>
        <CenteredText>
          <Trans
            i18nKey={'molecules.CustomerSelectorInitialState.selectExisting'}
          >
            Please
            <Link
              $disabled={disabled}
              onClick={disabled ? undefined : onSelectExistingClick}
              data-testid="CustomerSelectorInitialState__select-existing-button"
            >
              select existing
            </Link>{' '}
          </Trans>
          {!!onCreateNewClick && (
            <Trans i18nKey={'molecules.CustomerSelectorInitialState.createNew'}>
              or
              <Link
                onClick={onCreateNewClick}
                data-testid="CustomerSelectorInitialState__create-new-button"
              >
                create new
              </Link>{' '}
              one
            </Trans>
          )}
        </CenteredText>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  padding: 2rem;
`

const Heading = styled(Typography)`
  font-weight: bold;
  margin-bottom: 1rem;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CenteredText = styled(Typography)`
  text-align: center;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.palette.grey[400]};
`

const StyledIcon = styled(Apartment)`
  font-size: 4rem;
  color: ${({ theme }) => theme.palette.grey[200]};
`

const Link = styled.a<{ $disabled?: boolean }>`
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  color: ${({ $disabled, theme }) =>
    $disabled ? theme.palette.grey[400] : theme.palette.primary.main};
  text-decoration: underline;

  &:hover {
    text-decoration: ${({ $disabled }) => ($disabled ? 'underline' : 'none')};
  }
`

export default CustomerSelectorInitialState
