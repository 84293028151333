import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

import TextField, {
  TextFieldPriceFormatter,
} from '@app/components/atoms/TextField/TextField'

type FeesManagerFieldProps = {
  readonly?: boolean
  label: string
  value: string
  onLabelChange: (nextLabel: string) => Promise<void> | void
  onValueChange: (nextValue: string) => Promise<void> | void
  onDelete: () => Promise<void> | void
}

const FeesManagerField = ({
  label,
  value,
  readonly,
  onLabelChange,
  onValueChange,
  onDelete,
}: FeesManagerFieldProps) => {
  const [internalLabel, setInternalLabel] = useState(label)
  const [internalValue, setInternalValue] = useState(value)

  useEffect(() => {
    setInternalLabel(label)
  }, [label])

  useEffect(() => {
    setInternalValue(value)
  }, [value])

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mt={1}
    >
      <LabelTextField
        disabled={readonly}
        value={internalLabel}
        onChange={(event) => setInternalLabel(event.target.value)}
        onBlur={() => onLabelChange(internalLabel)}
      />
      <UnitTextField
        disabled={readonly}
        inputComponent={TextFieldPriceFormatter}
        value={internalValue}
        onChange={(event) => setInternalValue(event.target.value)}
        onBlur={() => onValueChange(internalValue)}
      />

      <Box
        width="5rem"
        display="flex"
        alignItems="center"
        justifyContent="center"
        marginLeft="1rem"
      >
        {!readonly && (
          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  )
}

const LabelTextField = styled(TextField)`
  padding-right: 1rem;
  flex: 1;
`

const UnitTextField = styled(TextField)`
  width: 5rem;

  & input {
    text-align: right;
  }
`

export default FeesManagerField
