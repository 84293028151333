import React, { Ref, useMemo, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { InputProps } from '@material-ui/core/Input'
import { InputBaseComponentProps } from '@material-ui/core/InputBase'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import EventIcon from '@material-ui/icons/Event'
import ClearIcon from '@material-ui/icons/Clear'

import InputEndAdornment from '@app/components/atoms/InputEndAdornment/InputEndAdornment'

import { getISOStringIgnoringTimezone } from '@app/utils/dateUtils'
import { useDateAndTimeFormats } from '@app/hooks/useDateAndTimeFormats'

import {
  DesktopDatePicker as BaseDatePicker,
  DesktopDatePickerProps as BaseDatePickerProps,
} from '@material-ui/pickers'

import TextField, {
  TextFieldProps,
} from '@app/components/atoms/TextField/TextField'

dayjs.extend(utc)

interface DatePickerProps
  extends Omit<BaseDatePickerProps, 'onChange' | 'renderInput'> {
  size?: TextFieldProps['size']
  TextFieldProps?: TextFieldProps
  onChange?: (date: Date | null) => void
  renderInput?: BaseDatePickerProps['renderInput']
  className?: string
  placeholder?: string
}

interface RenderInputProps {
  inputRef?: Ref<HTMLInputElement> | undefined
  inputProps?: InputBaseComponentProps
  InputProps?: Partial<InputProps>
}

const SingleDatePicker = ({
  onChange,
  className,
  disabled,
  placeholder,
  TextFieldProps = {},
  disablePast = true,
  inputFormat,
  size = 'normal',
  ...props
}: DatePickerProps): JSX.Element => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const { dateFormat: defaultDateFormat } = useDateAndTimeFormats()

  const handleChange = (value: unknown) => {
    const isDateIOType = (value: unknown): value is Dayjs =>
      !!(value as Dayjs)?.toDate?.()

    if (isDateIOType(value) && value.isValid()) {
      const nextValue = getISOStringIgnoringTimezone(value.toDate())
      const nextDate = dayjs.utc(nextValue).startOf('day').toDate()

      return onChange?.(nextDate)
    }

    onChange?.(null)
  }

  const defaultPlaceholder = t('atoms.SingleDatePicker.defaultPlaceholder')

  const defaultRenderInput: BaseDatePickerProps['renderInput'] = useMemo(() => {
    const DefaultRenderInput = ({ inputRef, inputProps }: RenderInputProps) => {
      return (
        <StyledTextField
          {...TextFieldProps}
          className={className}
          size={size}
          ref={(inputRef ?? TextFieldProps.ref) as Ref<HTMLInputElement>}
          endAdornment={
            <InputEndAdornment
              onClick={(event) => {
                if (inputProps?.value && !disabled) {
                  event.stopPropagation()
                  handleChange(null)
                }
              }}
            >
              {inputProps?.value && !disabled ? <ClearIcon /> : <EventIcon />}
            </InputEndAdornment>
          }
          onClick={() => setIsOpen(true)}
          inputProps={{
            ...inputProps,
            ...TextFieldProps?.inputProps,
            ref: (inputRef ?? TextFieldProps.ref) as Ref<HTMLInputElement>,
            placeholder: placeholder ?? defaultPlaceholder,
          }}
        />
      )
    }

    return DefaultRenderInput
  }, [TextFieldProps, size])

  return (
    // @see https://github.com/mui-org/material-ui/issues/25135#issuecomment-812483780
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div>
        <BaseDatePicker
          {...props}
          disabled={disabled}
          open={disabled ? false : isOpen}
          onClose={() => setIsOpen(false)}
          inputFormat={inputFormat ?? defaultDateFormat}
          disablePast={disablePast}
          renderInput={props.renderInput ?? defaultRenderInput}
          onChange={handleChange}
        />
      </div>
    </ClickAwayListener>
  )
}

const StyledTextField = styled(TextField)`
  max-width: 100%;
`

export default SingleDatePicker
