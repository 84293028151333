import React from 'react'
import { useTheme } from 'styled-components'

import Badge, { BadgeProps } from '@app/components/atoms/Badge/Badge'

type DraftBadgeProps = Partial<BadgeProps>

const DraftBadge = ({
  color,
  label = 'draft',
  ...props
}: DraftBadgeProps): JSX.Element => {
  const theme = useTheme()

  return (
    <Badge color={color ?? theme.colors.lightBlue} label={label} {...props} />
  )
}

export default DraftBadge
