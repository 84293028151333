import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import FlightRouteIcon from '@app/components/atoms/FlightRouteIcon/FlightRouteIcon'
import Price from '@app/components/atoms/Price/Price'
import Tooltip from '@app/components/atoms/Tooltip/Tooltip'
import Typography from '@app/components/atoms/Typography/Typography'
import LegPriceTooltip from '@app/components/molecules/LegInfo/LegPriceTooltip'
import LegTypeTooltip from '@app/components/molecules/LegInfo/LegTypeTooltip'
import { Extras } from '@shared/interfaces/Leg'
import { CustomRouteDetailDto } from '@shared/dto/customRoutes.dto'

import { SIMPLE_DURATION_FORMAT } from '@shared/constants'
import { assertUnreachable } from '@shared/utils/assertUnreachable'
import { getLegTripCosts } from '@shared/utils/computationCalculator'

export interface LegInfoProps {
  extras?: Extras | null
  disabled: boolean
  tooltipText?: string
  relevantCustomRoute?: CustomRouteDetailDto
}

enum LegInfoModes {
  TimeAndDistance = 'timeAndDistance',
  CostAndProfit = 'costAndProfit',
}

const LegInfo = ({
  extras,
  disabled,
  relevantCustomRoute,
}: LegInfoProps): JSX.Element => {
  const { t } = useTranslation()

  const [mode, setMode] = useState<LegInfoModes>(LegInfoModes.CostAndProfit)

  // TODO take from user settings
  const distanceUnit = 'NM'

  const profit = extras?.profit ?? null
  const distance = extras?.distance_in_nautical_miles ?? null
  const duration = extras?.duration_in_minutes ?? null
  const cost = extras ? getLegTripCosts(extras).toNumber() : null

  const handleSwitchMode = () => {
    setMode((currentMode) => {
      switch (currentMode) {
        case LegInfoModes.TimeAndDistance:
          return LegInfoModes.CostAndProfit

        case LegInfoModes.CostAndProfit:
          return LegInfoModes.TimeAndDistance

        default:
          assertUnreachable(currentMode)
      }
    })
  }

  const isCustomRoute =
    relevantCustomRoute &&
    relevantCustomRoute.flight_time_in_minutes === extras?.duration_in_minutes

  return (
    <Container onClick={handleSwitchMode}>
      {typeof profit === 'number' && mode === LegInfoModes.CostAndProfit && (
        <Tooltip title={<LegPriceTooltip extras={extras} />}>
          <div>
            <LegProfit
              value={profit}
              type={disabled ? 'disabled' : profit >= 0 ? 'profit' : 'loss'}
            />
          </div>
        </Tooltip>
      )}

      {typeof duration === 'number' &&
        mode === LegInfoModes.TimeAndDistance && (
          <StyledTypography airwayId={extras?.airway_id}>
            {dayjs()
              .startOf('day')
              .minute(duration)
              .format(SIMPLE_DURATION_FORMAT)}
          </StyledTypography>
        )}
      <StyledTooltip
        title={<LegTypeTooltip extras={extras} isCustomRoute={isCustomRoute} />}
      >
        <IconContainer>
          <FlightRouteIcon
            disabled={disabled}
            airwayId={extras?.airway_id}
            isCustomRoute={isCustomRoute}
            timeAndDistance={mode === LegInfoModes.TimeAndDistance}
          />
        </IconContainer>
      </StyledTooltip>

      {typeof cost === 'number' && mode === LegInfoModes.CostAndProfit && (
        <Tooltip title={<LegPriceTooltip extras={extras} />}>
          <div>
            <StyledPrice
              value={cost}
              type={disabled ? 'disabled' : 'default'}
            />
          </div>
        </Tooltip>
      )}

      {typeof distance === 'number' &&
        mode === LegInfoModes.TimeAndDistance && (
          <StyledTypography airwayId={extras?.airway_id}>
            {t('atoms.LegInfo.flightDistance', {
              distance: distance,
              unit: distanceUnit,
            })}
          </StyledTypography>
        )}
    </Container>
  )
}

const StyledTooltip = styled(Tooltip)`
  width: 100%;
`

const IconContainer = styled.div`
  width: 100%;
`

const StyledPrice = styled(Price)`
  margin: auto;
  width: fit-content;
`

const Container = styled.div`
  min-height: 100%;
  min-width: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
  cursor: pointer;
`

const StyledTypography = styled(Typography)<{
  airwayId?: number | null
  isCustomRoute?: boolean
}>`
  align-self: center;
  white-space: nowrap;
  color: ${({ airwayId, theme, isCustomRoute }) =>
    airwayId !== null && isCustomRoute
      ? '#0864fc'
      : airwayId !== null && !isCustomRoute
        ? '#0864fc'
        : airwayId === null && isCustomRoute
          ? '#6AA2FD'
          : theme.palette.grey[800]};
`

const LegProfit = styled(StyledPrice)`
  font-weight: bold;
`

export default LegInfo
