import React, { ReactNode } from 'react'
import styled from 'styled-components'

interface Props {
  children?: ReactNode | ReactNode[]
}

interface LegPointRowProps extends Props {
  title?: string
}

export const LegPointContainer = (props: Props) => {
  const { children } = props

  return <StyledLegPointContainer>{children}</StyledLegPointContainer>
}

export const LegPointRow = (props: LegPointRowProps) => {
  const { children, ...rest } = props

  return <StyledLegPointRow {...rest}>{children}</StyledLegPointRow>
}

export const DatePickerContainer = (props: Props) => {
  const { children } = props

  return <StyledDatePickerContainer>{children}</StyledDatePickerContainer>
}

const StyledLegPointContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const StyledLegPointRow = styled.div`
  display: flex;
  align-items: center;
`

const StyledDatePickerContainer = styled.div`
  padding: 2px 4px;
  width: 8rem;
`
