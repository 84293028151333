import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'

import CheckIcon from '@material-ui/icons/Check'

import {
  Checkbox as BaseCheckbox,
  CheckboxProps as BaseCheckboxProps,
} from '@material-ui/core'

import { prop } from '@app/utils/css'

type Sizes = 'normal' | 'large' | 'xlarge'

interface CheckboxProps extends Omit<BaseCheckboxProps, 'size' | 'color'> {
  inverted?: boolean
  size?: Sizes
  color?: string
}

interface StyledCheckboxProps extends Omit<CheckboxProps, 'inverted'> {
  $scale: number
  $inverted?: boolean
  $color?: string
}

const SIZES_TO_SCALE_MAP = {
  normal: 1,
  large: 2,
  xlarge: 3.125,
}

const Checkbox = ({
  color,
  inverted = false,
  size = 'normal',
  ...props
}: CheckboxProps): JSX.Element => {
  const scale = useMemo(() => SIZES_TO_SCALE_MAP[size], [size])

  return (
    <StyledCheckbox
      color="primary"
      $inverted={inverted}
      $scale={scale}
      $color={color}
      icon={
        inverted ? (
          <StyledCheckboxOutlineBlankIcon $color={color} $scale={scale} />
        ) : (
          <StyledCheckboxOutlineBlankOutlinedIcon
            $color={color}
            $scale={scale}
          />
        )
      }
      checkedIcon={
        inverted ? (
          <StyledCheckIcon $color={color} $scale={scale} />
        ) : (
          <StyledCheckboxOutlinedIcon $color={color} $scale={scale} />
        )
      }
      {...props}
    />
  )
}

const styleBase = css<StyledCheckboxProps>`
  height: ${prop('$scale')}rem;
  width: ${prop('$scale')}rem;
  padding: 0;
`

const StyledCheckbox = styled(BaseCheckbox)<StyledCheckboxProps>`
  ${styleBase}

  border-radius: 3px;

  border: ${({ $inverted, theme }) =>
    `1px solid ${$inverted ? 'rgba(0, 0, 0, 0)' : theme.palette.grey[300]}`};

  background-color: ${({ $inverted, $color, theme }) =>
    $inverted
      ? $color ?? theme.palette.primary.main
      : theme.palette.common.white};

  &:hover {
    border: ${({ $inverted, theme }) =>
      $inverted && `1px solid ${theme.palette.grey[300]}`};
  }
`

const StyledCheckIcon = styled(CheckIcon)`
  ${styleBase}

  color: white;
  stroke-width: 1px;
  stroke: ${({ $color, theme }) => $color ?? theme.palette.primary.main};
`

const StyledCheckboxOutlineBlankIcon = styled.div`
  ${styleBase}

  color: ${({ theme }) => theme.palette.grey[300]};
`

const StyledCheckboxOutlinedIcon = styled(CheckIcon)`
  ${styleBase}

  color: ${({ $color, theme }) => $color ?? theme.palette.primary.main};
`

const StyledCheckboxOutlineBlankOutlinedIcon = styled.div`
  ${styleBase}
`

export default Checkbox
