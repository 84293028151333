import React, { useEffect } from 'react'
import styled from 'styled-components'
import { AircraftDetailDto } from '@shared/dto/aircraft.dto'
import { useTranslation } from 'react-i18next'

import EmptyListMessage from '@app/components/atoms/EmptyListMessage/EmptyListMessage'
import LoadingSpinner from '@app/components/atoms/LoadingSpinner/LoadingSpinner'
import Logo from '@app/components/atoms/Logo/Logo'

import Schedule, {
  ScheduleProps,
} from '@app/components/molecules/Schedule/Schedule'

import {
  OfferProfitAndPrice,
  ScheduleDetailDto,
} from '@shared/dto/schedule.dto'
import { ScheduleSource } from '@shared/enums'

interface ScheduleCalendarViewProps
  extends Omit<
    ScheduleProps,
    'schedule' | 'availableAircraft' | 'viewStart' | 'viewEnd'
  > {
  onGetInitialData?: () => void
  schedule: ScheduleDetailDto[] | null
  availableAircraft: AircraftDetailDto[] | null
  viewStart?: string
  viewEnd?: string
  sources: ScheduleSource[]
  error: unknown
  finalPriceAndTotalProfit?: OfferProfitAndPrice[]
}

const ScheduleCalendarView = ({
  onGetInitialData,
  schedule,
  availableAircraft,
  viewStart,
  viewEnd,
  error,
  ...props
}: ScheduleCalendarViewProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    onGetInitialData?.()
  }, [])

  if (!viewStart || !viewEnd) {
    return (
      <EmptyListMessageContainer>
        <EmptyListMessage
          title={t('molecules.ScheduleCalendarView.noBoundaryDates')}
        />
      </EmptyListMessageContainer>
    )
  }

  if (!schedule || !availableAircraft) {
    if (props.isLoading) {
      return (
        <LoadingSpinner loading={props.isLoading}>
          <StyledLogo />
        </LoadingSpinner>
      )
    }

    if (error) {
      return (
        <EmptyListMessageContainer>
          <EmptyListMessage
            title={t('molecules.ScheduleCalendarView.defaultError')}
          />
        </EmptyListMessageContainer>
      )
    }

    return (
      <EmptyListMessageContainer>
        <EmptyListMessage title={t('molecules.ScheduleCalendarView.noData')} />
      </EmptyListMessageContainer>
    )
  }

  return (
    <Schedule
      {...props}
      schedule={schedule}
      availableAircraft={availableAircraft}
      viewStart={new Date(viewStart)}
      viewEnd={new Date(viewEnd)}
    />
  )
}

const StyledLogo = styled(Logo)`
  color: ${({ theme }) => theme.palette.primary.main};
  width: 3rem;
  height: 3rem;
`

const EmptyListMessageContainer = styled.div`
  margin: 4rem auto;
  display: flex;
  justify-content: center;
`

export default ScheduleCalendarView
