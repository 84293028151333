import { useSelector } from 'react-redux'

import {
  selectOpenOfferId,
  selectRequestDetailData,
} from '@app/store/pages/requests/requestDetail/requestDetail.selectors'

export const useActiveOffer = () => {
  const requestDetailData = useSelector(selectRequestDetailData)
  const openOfferId = useSelector(selectOpenOfferId)

  if (!requestDetailData) {
    throw new Error(`Request detail data is required`)
  }

  return requestDetailData.offers.find((offer) => offer.id === openOfferId)
}
