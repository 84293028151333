import { useSelector } from 'react-redux'

import { selectUserInfo, selectUserInfoIsLoading } from '@app/store/core/userInfo/userInfo.selectors'

/**
 * @todo Display settings default values
 */
export const useActiveUser = () => {
  const selectedUser = useSelector(selectUserInfo)
  const isSelectedUserLoading = useSelector(selectUserInfoIsLoading)

  if (!selectedUser && !isSelectedUserLoading) {
    throw new Error(`Selected user not found`)
  }

  return selectedUser
}
