import { useQuery } from '@tanstack/react-query'
import { api } from '@app/utils/api/api'
import { useMemo } from 'react'

export const useOffers = (ids: number[]) => {
  const uniqueOfferIds = useMemo(
    () => [...new Set(ids)].filter((num) => num !== 0),
    [ids],
  )

  return useQuery(
    uniqueOfferIds,
    () => api.getOffers(uniqueOfferIds).then((res) => res.data),
    {
      cacheTime: 10 * 60 * 1000,
      refetchOnWindowFocus: false
    },
  )
}
