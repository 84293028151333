import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

export const useChatFormValidationSchema = () => {
  const { t } = useTranslation()

  return yup.object().shape({
    content: yup
      .string()
      .required(t('organisms.Chat.messageContentRequired'))
      .typeError(t('organisms.Chat.messageContentRequired')),
  })
}
