import React from 'react'
import { useTheme } from 'styled-components'

import Badge, { BadgeProps } from '@app/components/atoms/Badge/Badge'

type BookedBadgeProps = Partial<BadgeProps>

const BookedBadge = ({
  color,
  label = 'booked',
  ...props
}: BookedBadgeProps): JSX.Element => {
  const theme = useTheme()

  return <Badge color={color ?? theme.colors.green} label={label} {...props} />
}

export default BookedBadge
