import { useRef } from 'react'

const DEFAULT_DEBOUNCE_IN_MILLISECONDS = 500

export const useDebounce = (defaultMilliseconds?: number) => {
  const timeout = useRef<ReturnType<typeof setTimeout>>()

  return (callback: () => void | Promise<void>, ms?: number) => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }

    timeout.current = setTimeout(
      callback,
      ms ?? defaultMilliseconds ?? DEFAULT_DEBOUNCE_IN_MILLISECONDS,
    )
  }
}
