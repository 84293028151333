import { BaseLegDetailDto } from '@shared/dto/requests.dto'
import { AirportFeeDto, OperatorDto } from '@shared/dto'
import { AirportFeeInputIds } from '@app/components/organisms/LegPriceBreakdown/LegPriceBreakdown'
import { ScheduleSource } from '@shared/enums'

export const getAreAirportFeesSameAsLegValues = (
  airportFees: AirportFeeDto[] | null,
  leg: BaseLegDetailDto,
  touchedLegFields: AirportFeeInputIds[],
) => {
  const isDepartureFeeInputTouched = touchedLegFields?.includes(
    AirportFeeInputIds.DepartureFee,
  )

  const isAnyArrivalRelatedFeeInputTouched =
    touchedLegFields && !isDepartureFeeInputTouched

  const arrivalAirportFee = airportFees?.find(
    (airportFee) =>
      !airportFee.matcher ||
      airportFee.matcher === leg.arrival_airport.icao_code,
  )

  const areArrivalAirportFeesSame =
    arrivalAirportFee &&
    arrivalAirportFee.arrival_fee === leg.arrival_fee &&
    arrivalAirportFee.handling_fee === leg.handling_fee &&
    arrivalAirportFee.airport_fee === leg.airport_fee

  const departureAirportFee = airportFees?.find(
    (airportFee) =>
      !airportFee.matcher ||
      airportFee.matcher === leg.departure_airport.icao_code,
  )

  const isDepartureAirportFeeSame =
    departureAirportFee?.departure_fee === leg.departure_fee

  if (isDepartureFeeInputTouched && isAnyArrivalRelatedFeeInputTouched) {
    return isDepartureAirportFeeSame && areArrivalAirportFeesSame
  }

  if (isAnyArrivalRelatedFeeInputTouched) {
    return areArrivalAirportFeesSame
  }

  if (isDepartureFeeInputTouched) {
    return isDepartureAirportFeeSame
  }

  return false
}

export const getExtenralAppSources = (operator: OperatorDto) => {
  const toReturn = [ScheduleSource.STRAFOS]

  if (operator.is_fl3xx_enabled) {
    toReturn.push(ScheduleSource.FL3XX)
  }

  if (operator.is_leon_enabled) {
    toReturn.push(ScheduleSource.LEON)
  }

  return toReturn
}
