import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import EditIcon from '@material-ui/icons/EditOutlined'

interface ScheduleItemContextMenuProps {
  onEditMarketingLeg?: () => void
  onRemoveMarketingLeg?: () => void
  onEditOutage?: () => void
  onRemoveOutage?: () => void
  onMarkLegAsRemoved?: () => void
}

const ScheduleItemContextMenu = ({
  onEditMarketingLeg,
  onRemoveMarketingLeg,
  onEditOutage,
  onRemoveOutage,
  onMarkLegAsRemoved,
}: ScheduleItemContextMenuProps) => {
  const { t } = useTranslation()

  return (
    <>
      {onEditMarketingLeg && (
        <StyledMenuItem
          onClick={(event) => {
            event.stopPropagation()
            onEditMarketingLeg()
          }}
        >
          <StyledListItemIcon>
            <StyledEditIcon />
          </StyledListItemIcon>
          <ListItemText>
            {t('molecules.Schedule.editMarketingLeg')}
          </ListItemText>
        </StyledMenuItem>
      )}
      {onRemoveMarketingLeg && (
        <StyledMenuItem
          onClick={(event) => {
            event.stopPropagation()
            onRemoveMarketingLeg()
          }}
        >
          <StyledListItemIcon>
            <StyledDeleteIcon />
          </StyledListItemIcon>
          <ListItemText>
            {t('molecules.Schedule.removeMarketingLeg')}
          </ListItemText>
        </StyledMenuItem>
      )}
      {onEditOutage && (
        <StyledMenuItem
          onClick={(event) => {
            event.stopPropagation()
            onEditOutage()
          }}
        >
          <StyledListItemIcon>
            <StyledEditIcon />
          </StyledListItemIcon>
          <ListItemText>{t('molecules.Schedule.editOutage')}</ListItemText>
        </StyledMenuItem>
      )}
      {onRemoveOutage && (
        <StyledMenuItem
          onClick={(event) => {
            event.stopPropagation()
            onRemoveOutage()
          }}
        >
          <StyledListItemIcon>
            <StyledDeleteIcon />
          </StyledListItemIcon>
          <ListItemText>{t('molecules.Schedule.removeOutage')}</ListItemText>
        </StyledMenuItem>
      )}
      {onMarkLegAsRemoved && (
        <StyledMenuItem
          onClick={(event) => {
            event.stopPropagation()
            onMarkLegAsRemoved()
          }}
        >
          <StyledListItemIcon>
            <StyledDeleteIcon />
          </StyledListItemIcon>
          <ListItemText>{t('molecules.Schedule.removeLeg')}</ListItemText>
        </StyledMenuItem>
      )}
    </>
  )
}

const StyledMenuItem = styled(MenuItem)`
  color: ${({ theme }) => theme.palette.primary.main};
`

const StyledListItemIcon = styled(ListItemIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
  min-width: 2rem;
`

const StyledDeleteIcon = styled(DeleteIcon)`
  font-size: 1.2rem;
`

const StyledEditIcon = styled(EditIcon)`
  font-size: 1.2rem;
`

export default ScheduleItemContextMenu
