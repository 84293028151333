import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

const useUserProfileValidationSchema = () => {
  const { t } = useTranslation()

  return yup.object().shape({
    firstName: yup
      .string()
      .required(t('organisms.UserPanel.UserProfileForm.firstNameRequired'))
      .typeError(t('organisms.UserPanel.UserProfileForm.firstNameRequired')),
    lastName: yup
      .string()
      .required(t('organisms.UserPanel.UserProfileForm.lastNameRequired'))
      .typeError(t('organisms.UserPanel.UserProfileForm.lastNameRequired')),
    email: yup
      .string()
      .email(t('organisms.UserPanel.UserProfileForm.emailNotValid'))
      .required(t('organisms.UserPanel.UserProfileForm.emailRequired'))
      .typeError(t('organisms.UserPanel.UserProfileForm.emailRequired')),
  })
}

export default useUserProfileValidationSchema
