import { CellProps } from 'react-table'
import { PartialRequestDto } from '@shared/dto/requests.dto'
import React, { useMemo } from 'react'
import { OfferFlags } from '@shared/enums'
import Typography from '@app/components/atoms/Typography/Typography'
import Box from '@material-ui/core/Box'
import Price from '@app/components/atoms/Price/Price'
import { Profit } from '@app/components/molecules/RequestsGridView/Cells/CellStyledComponents'
import { useTheme } from 'styled-components'
import { useOffers } from '@app/hooks/useOffers'
import { getOfferTotalProfit } from '@shared/utils/computationCalculator'

export const AdjustedQuoteCell = ({
  cell,
}: CellProps<PartialRequestDto, unknown>) => {
  const theme = useTheme()
  const request = cell.row.original
  const flagsToShow = [
    OfferFlags.ProfitOpportunity,
    OfferFlags.ProfitLost,
    OfferFlags.ProfitReduced,
    OfferFlags.Conflict,
  ]
  const offersToShow = request.offers.filter((offer) =>
    flagsToShow.includes(offer.flag),
  )
  const { data, isLoading, error } = useOffers(
    offersToShow.map((offer) => offer.id),
  )

  const sortedData = useMemo(
    () =>
      !!data && Array.isArray(data)
        ? data?.sort((a, b) => {
            return (
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
            )
          })
        : [],
    [data],
  )

  if (!request.flags.some((flag) => flagsToShow.includes(flag))) {
    return null
  }

  if (!isLoading && error) return null

  return (
    <Box
      display="flex"
      alignItems="flex-start"
      justifyContent="center"
      flexDirection="column"
    >
      <Typography variant="content">{request.aircraft_code}</Typography>
      <Box display="flex" alignItems="center">
        {!!sortedData && sortedData.length > 0 && (
          <>
            {sortedData[0].flag === OfferFlags.Conflict ? (
              <Typography style={{ color: theme.palette.error.dark }}>
                Conflict
              </Typography>
            ) : (
              <>
                <Profit
                  type={
                    getOfferTotalProfit(sortedData[0]).toNumber() >= 0
                      ? 'profit'
                      : 'loss'
                  }
                  size="small"
                  value={getOfferTotalProfit(sortedData[0]).toNumber()}
                />
                <Price size="small" value={request.final_price} />
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}
