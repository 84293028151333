import styled from 'styled-components'
import LoadingSpinner from '@app/components/atoms/LoadingSpinner/LoadingSpinner'
import React from 'react'

export const Loader = () => {
  return (
    <StyledContainer>
      <StyledLoadingSpinner loading size={40} />
    </StyledContainer>
  )
}

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin: auto;
`
const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
