import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import GeneralNoDataIcon from '@app/components/atoms/icons/GeneralNoDataIcon/GeneralNoDataIcon'
import Typography from '@app/components/atoms/Typography/Typography'

interface EmptyListMessageProps {
  title?: string
  description?: string
  Icon?: FunctionComponent
  className?: string
}

const EmptyListMessage = ({
  Icon = GeneralNoDataIcon,
  description,
  className,
  ...props
}: EmptyListMessageProps) => {
  const { t } = useTranslation()

  const title = props.title ?? t('atoms.EmptyListMessage.defaultTitle')

  return (
    <Container className={className}>
      <IconContainer>
        <Icon />
      </IconContainer>
      <StyledTypography variant="boldText">{title}</StyledTypography>
      {description && (
        <StyledTypography variant="subtitle">{description}</StyledTypography>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  text-align: center;
`

const StyledTypography = styled(Typography)`
  margin-bottom: 0.5rem;
`

const IconContainer = styled.div`
  width: 50px;
  margin: 1rem 0;
`

export default EmptyListMessage
