import React from 'react'
import { Control, UseFormTrigger } from 'react-hook-form'

import { CreateRequestFormData } from '@app/components/organisms/CreateRequestForm/CreateRequestForm'

import LegFieldGroup, {
  LegFieldGroupProps,
} from '@app/components/organisms/CreateRequestForm/LegFieldGroup/LegFieldGroup'

import { useControllerWithValidationTrigger } from '@app/hooks/useControllerWithValidationTrigger'

interface ControlledLegFieldGroupProps
  extends Omit<LegFieldGroupProps, 'fields'> {
  control: Control<CreateRequestFormData>
  trigger: UseFormTrigger<CreateRequestFormData>
}

const ControlledLegFieldGroup = ({
  control,
  index,
  trigger,
  ...rest
}: ControlledLegFieldGroupProps): JSX.Element => {
  const { field: passengerCount } = useControllerWithValidationTrigger({
    name: `legs.${index}.passengerCount`,
    hasError: !!rest.errors?.passengerCount,
    control,
    trigger,
  })

  const { field: date } = useControllerWithValidationTrigger({
    name: `legs.${index}.date`,
    hasError: !!rest.errors?.date,
    control,
    trigger,
  })

  const { field: time } = useControllerWithValidationTrigger({
    name: `legs.${index}.time`,
    hasError: !!rest.errors?.time,
    control,
    trigger,
  })

  const { field: dateType } = useControllerWithValidationTrigger({
    control,
    name: `legs.${index}.dateType`,
    hasError: !!rest.errors?.dateType,
    trigger,
  })

  const { field: returnDate } = useControllerWithValidationTrigger({
    name: `legs.${index}.returnDate`,
    hasError: !!rest.errors?.returnDate,
    control,
    trigger,
  })

  const { field: returnTime } = useControllerWithValidationTrigger({
    name: `legs.${index}.returnTime`,
    hasError: !!rest.errors?.returnTime,
    control,
    trigger,
  })

  const { field: returnDateType } = useControllerWithValidationTrigger({
    control,
    name: `legs.${index}.returnDateType`,
    hasError: !!rest.errors?.returnDateType,
    trigger,
  })

  const { field: departureAirport } = useControllerWithValidationTrigger({
    name: `legs.${index}.departureAirport`,
    hasError: !!rest.errors?.departureAirport,
    control,
    trigger,
  })

  const { field: arrivalAirport } = useControllerWithValidationTrigger({
    name: `legs.${index}.arrivalAirport`,
    hasError: !!rest.errors?.arrivalAirport,
    control,
    trigger,
  })

  return (
    <LegFieldGroup
      fields={{
        departureAirport,
        arrivalAirport,
        date,
        time,
        dateType,
        passengerCount,
        returnDate,
        returnTime,
        returnDateType,
      }}
      index={index}
      {...rest}
    />
  )
}

export default ControlledLegFieldGroup
