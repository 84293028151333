import React, { MouseEvent, ReactNode, useRef, useState } from 'react'
import { Hooks, CellProps } from 'react-table'
import styled from 'styled-components'

import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'

import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Badge from '@material-ui/core/Badge'

export interface ContextMenuItem {
  name: ReactNode
  icon?: ReactNode
  shouldCloseAfterClick?: boolean
  onClick: (id: number) => void
  testId?: string
}

interface ActionsCellProps<T extends object> extends CellProps<T> {
  getContextMenuItems: (entity: T) => ContextMenuItem[] | undefined
  getBadgeNotificationContent?: (entity: T) => ReactNode
}

export function useRowActionColumn<T extends { id: number }>(
  getContextMenuItems?: (entity: T) => ContextMenuItem[] | undefined,
  getBadgeNotificationContent?: (entity: T) => number | string | null,
) {
  return (hooks: Hooks<T>): void => {
    if (!getContextMenuItems) {
      return
    }

    hooks.allColumns.push((columns) => [
      ...columns,
      {
        id: 'action',
        Cell: (props: CellProps<T>) => (
          <ActionsCell
            getContextMenuItems={getContextMenuItems}
            getBadgeNotificationContent={getBadgeNotificationContent}
            {...props}
          />
        ),
      },
    ])
  }
}

function ActionsCell<T extends { id: number }>({
  getContextMenuItems,
  getBadgeNotificationContent,
  row,
}: ActionsCellProps<T>): JSX.Element | null {
  const anchorElement = useRef(null)
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false)
  const contextMenuItems = getContextMenuItems(row.original)

  if (!contextMenuItems) {
    return null
  }

  const badgeNotificationContent = getBadgeNotificationContent?.(row.original)

  const onContextMenuIconButtonClick = (
    event: MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation()

    setIsContextMenuOpen(true)
  }

  return (
    <Box
      display="flex"
      alignItems="flex-end"
      justifyContent="center"
      flexDirection="column"
    >
      <IconButton
        data-testid={`ActionsCell__row[${row.index}].context-menu-button`}
        ref={anchorElement}
        onClick={onContextMenuIconButtonClick}
        disabled={!contextMenuItems.length}
      >
        {badgeNotificationContent ? (
          <StyledBadge
            badgeContent={badgeNotificationContent}
            classes={{ badge: 'Badge__Badge' }}
          >
            <MoreHorizIcon />
          </StyledBadge>
        ) : (
          <MoreHorizIcon />
        )}
      </IconButton>
      <Menu
        anchorEl={anchorElement.current}
        open={isContextMenuOpen}
        onClose={(event) => {
          // @todo How to avoid this
          const castedEvent = event as MouseEvent<HTMLButtonElement>

          castedEvent.stopPropagation?.()

          setIsContextMenuOpen(false)
        }}
      >
        {contextMenuItems.map(
          (
            { name, onClick, icon, testId, shouldCloseAfterClick = true },
            index,
          ) => (
            <MenuItem
              key={index}
              data-testid={testId}
              onClick={(event) => {
                event.stopPropagation()

                onClick(row.original.id)

                if (shouldCloseAfterClick) {
                  setIsContextMenuOpen(false)
                }
              }}
            >
              {icon && <ListItemIcon>{icon}</ListItemIcon>}
              <ListItemText>{name}</ListItemText>
            </MenuItem>
          ),
        )}
      </Menu>
    </Box>
  )
}

const StyledBadge = styled(Badge)`
  .Badge__Badge {
    background: ${({ theme }) => theme.colors.red};
    color: #fff;
    font-weight: bold;
  }
`
