import { useCallback } from 'react'
import { AnySchema, ValidationError } from 'yup'
import { DeepMap, FieldError, ResolverSuccess } from 'react-hook-form'

export const getFieldErrorsFromException = (exception: unknown) => {
  const getIsExceptionValidationError = (
    exception: unknown,
  ): exception is ValidationError => !!(exception as ValidationError).inner

  if (!getIsExceptionValidationError(exception)) {
    console.error(exception)

    return {}
  }

  return exception.inner.reduce(
    (allErrors: Record<string, FieldError>, currentError: ValidationError) => {
      if (!currentError.path) {
        return allErrors
      }

      return {
        ...allErrors,
        [currentError.path]: {
          type: currentError.type ?? 'validation',
          message: currentError.message,
        },
      }
    },
    {},
  )
}

export const useYupValidationResolver = <FormData extends object>(
  validationSchema: AnySchema,
  // There is no native lib support for warnings
  //  see https://github.com/react-hook-form/react-hook-form/issues/1761
  warningsOptions?: {
    warningsSchema: AnySchema
    warnings: DeepMap<FormData, FieldError>
    setWarnings: (warnings: Record<string, FieldError>) => void
  },
): ((
  data: FormData,
  context?: object,
) => Promise<
  | ResolverSuccess<FormData>
  // TODO refactor the file for this to return ResolverError with DeepMap<FormData, FieldError>
  //  (means using deep map instead of plain object with errors... such as "{ 'legs[0].type': { ... } }")
  | { values: object; errors: Record<string, FieldError> }
>) =>
  useCallback(
    async (data, context) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
          context,
        })

        if (!warningsOptions) {
          return {
            values,
            errors: {},
          }
        }

        const { warningsSchema, setWarnings } = warningsOptions

        try {
          await warningsSchema.validate(data, {
            abortEarly: false,
            context,
          })

          setWarnings({})
        } catch (error) {
          const warnings = getFieldErrorsFromException(error)

          setWarnings(warnings)
        } finally {
          return {
            values,
            errors: {},
          }
        }
      } catch (error) {
        return {
          values: {},
          errors: getFieldErrorsFromException(error),
        }
      }
    },
    [validationSchema, warningsOptions],
  )
