import React from 'react'
import styled from 'styled-components'

const GeneralNoDataIcon = (
  props: Omit<React.SVGProps<SVGSVGElement>, 'ref'>,
): JSX.Element => {
  return (
    <StyledSvg
      viewBox="0 0 57 57"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M47.5 22H35V9.5C35 8.125 33.875 7 32.5 7h-30A2.507 2.507 0 000 9.5v45C0 55.875 1.125 57 2.5 57h10V42h10v15h25c1.375 0 2.5-1.125 2.5-2.5v-30c0-1.375-1.125-2.5-2.5-2.5zM15 37H5V27h10v10zm0-15H5V12h10v10zm15 15H20V27h10v10zm0-15H20V12h10v10zm15 27.5H35V42h10v7.5zM45 37H35V27h10v10z"
          fill="#E0E0E0"
        />
        <path
          d="M47 0c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10S52.52 0 47 0zm-3 18l2-7h-4l8-9-2 7h4l-8 9z"
          fill="currentColor"
        />
      </g>
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  color: ${({ theme }) => theme.palette.grey[200]};
`

export default GeneralNoDataIcon
