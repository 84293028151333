import { useEffect } from 'react'

interface Props {
  elementId: number
}

export const ScrollToTop = ({ elementId }: Props) => {
  useEffect(() => {
    const el = document.getElementById(elementId.toString())
    if (el) el.scrollIntoView(true)
  }, [])
  return null
}
