import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { useDateAndTimeFormats } from '@app/hooks/useDateAndTimeFormats'

const CURRENT_TIME_REFRESH_INTERVAL = 1000

interface LabelProps {
  $color?: string
}

interface LineProps {
  $color?: string
}

interface TimeIndicatorProps {
  label?: string
  color?: string
  className?: string
  viewStart: Date
  viewEnd: Date
}
const TimeIndicator = ({
  color,
  label,
  className,
  viewEnd,
  viewStart,
}: TimeIndicatorProps) => {
  const [currentTime, setCurrentTime] = useState(dayjs.utc())

  const { timeFormat } = useDateAndTimeFormats()

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(dayjs.utc())
    }, CURRENT_TIME_REFRESH_INTERVAL)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const isCurrentTimeVisible =
    currentTime.isAfter(viewStart) && currentTime.isBefore(viewEnd)

  if (!isCurrentTimeVisible) {
    return null
  }

  const currentTimeIndicatorPositionPercentage =
    (currentTime.diff(viewStart) / dayjs.utc(viewEnd).diff(viewStart)) * 100
  return (
    <Container
      className={className}
      $left={`${Math.round(currentTimeIndicatorPositionPercentage)}%`}
    >
      <Label $color={color}>{label ?? currentTime.format(timeFormat)}</Label>
      <Line $color={color} />
    </Container>
  )
}

const Container = styled.div<{ $left: string }>`
  position: absolute;
  top: 0;
  height: 100%;
  padding-top: 2px;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  pointer-events: none;
  left: ${({ $left }) => $left};
`

const Label = styled.div<LabelProps>`
  position: relative;
  color: ${({ theme }) => theme.palette.common.white};
  background: ${({ $color, theme }) =>
    $color ? $color : theme.palette.grey[500]};
  padding: 0.2rem 0.5rem;
  font-size: 0.75rem;
  border-radius: 2px;

  &:after {
    content: '';
    position: absolute;
    top: calc(100% - 3px);
    left: calc(50% - 3px);
    width: 6px;
    height: 6px;
    transform: rotate(45deg);
    background: ${({ $color, theme }) =>
      $color ? $color : theme.palette.grey[500]};
  }
`

const Line = styled.div<LineProps>`
  border-left: 1px solid
    ${({ $color, theme }) => ($color ? $color : theme.palette.grey[500])};
  position: relative;
  left: calc(50% - 1px);
  flex-grow: 1;
`

export default TimeIndicator
