import React, { MouseEvent } from 'react'
import styled from 'styled-components'
import { darken } from '@material-ui/core'

import Box from '@material-ui/core/Box'
import CloseIcon from '@material-ui/icons/Close'

import Typography from '@app/components/atoms/Typography/Typography'
import FlagIcon from '@app/v2/components/atoms/FlagIcon/FlagIcon'

import { OfferFlags, OfferStatuses } from '@shared/enums'

export interface OfferDetailTabProps {
  id: number
  status: OfferStatuses
  aircraftCode: string
  subtitle: string
  flag?: OfferFlags
  isActive?: boolean
  onClose?: (id: number) => void | Promise<void>
  isReserved?: boolean
}

const OfferDetailTab = ({
  id,
  status,
  flag,
  aircraftCode,
  subtitle,
  isActive,
  onClose,
  isReserved,
}: OfferDetailTabProps) => {
  const onCloseIconButtonClick = (event: MouseEvent<SVGElement>) => {
    event.stopPropagation()

    onClose?.(id)
  }

  return (
    <Box display="flex">
      <Box textAlign="start" pr={1}>
        <TitleTypography
          $isActive={isActive ?? false}
          data-id="offer-detail-tab-title"
        >
          {isReserved ? 'Reserved' : status} ({aircraftCode}){' '}
          {flag && <StyledFlagIcon flag={flag} />}
        </TitleTypography>
        <Typography variant="subtitle">{subtitle}</Typography>
      </Box>
      {onClose && <StyledCloseIcon onClick={onCloseIconButtonClick} />}
    </Box>
  )
}

const StyledFlagIcon = styled(FlagIcon)`
  margin-left: 0.5rem;
  width: 1rem;
`

const TitleTypography = styled(Typography)<{ $isActive: boolean }>`
  align-items: center;
  display: flex;
  font-size: 0.9rem;
  text-transform: capitalize;

  color: ${({ $isActive, theme }) =>
    $isActive ? theme.palette.primary.light : theme.palette.common.black};
`

const StyledCloseIcon = styled(CloseIcon)`
  color: ${({ theme }) => theme.palette.grey[500]};
  cursor: pointer;
  font-size: 1.25rem;

  &:hover {
    color: ${({ theme }) => darken(theme.palette.grey[500], 0.2)};
  }
`

export default OfferDetailTab
