import React, { FocusEventHandler, Ref } from 'react'
import { produce } from 'immer'
import styled from 'styled-components'

import CloseIcon from '@material-ui/icons/Close'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import CircularProgress from '@material-ui/core/CircularProgress'
import { Value } from '@material-ui/lab/useAutocomplete'

import Autocomplete, {
  AutocompleteProps,
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete'

import TextField, {
  TextFieldProps,
} from '@app/components/atoms/TextField/TextField'

export interface BasicSelectOption<T = string> {
  id: T
  label: string
}

// @see https://github.com/mui-org/material-ui/issues/19354
export interface SelectProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'size' | 'renderInput'
  > {
  label?: string
  className?: string
  size?: TextFieldProps['size']
  name?: string
  inputRef?: Ref<Element>
  error?: boolean
  warning?: boolean
  onInputBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  renderInput?: AutocompleteProps<
    T,
    Multiple,
    DisableClearable,
    FreeSolo
  >['renderInput']
  inputProps?: object
}

function Select<
  T = BasicSelectOption,
  Multiple extends boolean = false,
  DisableClearable extends boolean = true,
  FreeSolo extends boolean = false,
>({
  label,
  loading,
  placeholder,
  error,
  warning,
  name,
  inputRef,
  size = 'normal',
  onInputBlur,
  inputProps,
  ...props
}: SelectProps<T, Multiple, DisableClearable, FreeSolo>): JSX.Element {
  const defaultRenderInput = (params: AutocompleteRenderInputParams) => {
    const nextParams = produce<Partial<AutocompleteRenderInputParams>>(
      params,
      (draft) => {
        delete draft.InputLabelProps
        delete draft.InputProps
      },
    )

    return (
      <TextField
        inputRef={inputRef}
        name={name}
        label={label}
        placeholder={placeholder}
        {...nextParams}
        {...params.InputProps}
        inputProps={{
          // @todo Why do we need to declare this here and can't use TextField default props ???
          // @see https://stackoverflow.com/a/44984917/3210641
          'data-lpignore': true,
          ...(inputProps ?? {}),
          ...nextParams.inputProps,
        }}
        size={size}
        error={error}
        warning={warning}
        onBlur={onInputBlur}
        endAdornment={
          <>
            {loading && <CircularProgress color="inherit" size={20} />}
            {params.InputProps.endAdornment}
          </>
        }
      />
    )
  }

  const defaultRenderTags = () => null

  const {
    multiple,
    defaultValue,
    disableClearable,
    renderTags = defaultRenderTags,
    renderInput = defaultRenderInput,
    ...rest
  } = props

  return (
    <StyledAutocomplete
      disableClearable={
        disableClearable ?? (multiple && (true as DisableClearable))
      }
      popupIcon={<KeyboardArrowDownIcon />}
      multiple={multiple}
      renderInput={renderInput}
      renderTags={renderTags}
      defaultValue={
        defaultValue ??
        (multiple
          ? ([] as unknown as Value<T, Multiple, DisableClearable, FreeSolo>)
          : undefined)
      }
      ChipProps={{
        deleteIcon: <CloseIcon color="primary" />,
      }}
      classes={{
        inputRoot: 'Select__inputRoot',
      }}
      {...rest}
    />
  )
}

const StyledAutocomplete = styled(Autocomplete)`
  box-sizing: content-box;
  overflow: hidden;

  & .Select__inputRoot {
    // @todo Remove important
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
` as typeof Autocomplete

export default Select
