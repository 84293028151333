import { RootState } from '@app/store'

export const selectIsPostOutageLoading = (store: RootState) =>
  store.pages.schedule.scheduleManagement.isPostOutageLoading

export const selectPostOutageError = (store: RootState) =>
  store.pages.schedule.scheduleManagement.postOutageError

export const selectIsPatchOutageLoading = (store: RootState) =>
  store.pages.schedule.scheduleManagement.isPatchOutageLoading

export const selectPatchOutageError = (store: RootState) =>
  store.pages.schedule.scheduleManagement.patchOutageError

export const selectIsDeleteOutageLoading = (store: RootState) =>
  store.pages.schedule.scheduleManagement.isDeleteOutageLoading

export const selectDeleteOutageError = (store: RootState) =>
  store.pages.schedule.scheduleManagement.deleteOutageError

export const selectIsPatchMarketplaceExtensionLoading = (store: RootState) =>
  store.pages.schedule.scheduleManagement.isPatchMarketplaceExtensionLoading

export const selectPatchMarketplaceExtensionError = (store: RootState) =>
  store.pages.schedule.scheduleManagement.patchMarketplaceExtensionError

export const selectIsDeleteMarketplaceExtensionLoading = (store: RootState) =>
  store.pages.schedule.scheduleManagement.isDeleteMarketplaceExtensionLoading

export const selectDeleteMarketplaceExtensionError = (store: RootState) =>
  store.pages.schedule.scheduleManagement.deleteMarketplaceExtensionError
