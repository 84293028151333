import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import produce from 'immer'

import FormControlLabel from '@material-ui/core/FormControlLabel'

import TextField from '@app/components/atoms/TextField/TextField'
import Button from '@app/components/atoms/Button/Button'
import ErrorTooltip from '@app/components/atoms/Tooltip/ErrorTooltip'
import Checkbox from '@app/components/atoms/Checkbox/Checkbox'

import { useYupValidationResolver } from '@app/hooks/useYupValidationResolver'
import { useChatFormValidationSchema } from '@app/components/organisms/Chat/useChatFormValidationSchema'
import { OfferDetailDto } from '@shared/dto/requests.dto'
import { OfferStatuses } from '@shared/enums'

interface ChatFormData {
  content: string
  includeOffer: boolean
}

export type SubmitChatMessageData = {
  content: string
  offerId?: number
  nextStatus?: OfferStatuses
}

interface ChatFormProps {
  onSubmit: (data: SubmitChatMessageData) => void
  disabled?: boolean
  selectedOffer?: OfferDetailDto
  className?: string
}

const ChatForm = ({
  onSubmit,
  disabled,
  selectedOffer,
  className,
}: ChatFormProps) => {
  const { t } = useTranslation()

  const validationSchema = useChatFormValidationSchema()
  const validationResolver = useYupValidationResolver(validationSchema)

  const { handleSubmit, formState, reset, control, watch } =
    useForm<ChatFormData>({
      resolver: validationResolver,
    })

  const contentValue = watch('content')

  // TODO quotation / booking through checkbox functionality postponed
  const isCheckboxVisible = false
  // selectedOffer &&
  // [
  //   OfferStatuses.Quoted,
  //   OfferStatuses.Draft,
  //   OfferStatuses.Unhandled,
  // ].includes(selectedOffer.status)

  const getNextOfferStatus = (status?: OfferStatuses) => {
    switch (status) {
      case OfferStatuses.Unhandled:
      case OfferStatuses.Draft:
        return OfferStatuses.Quoted

      case OfferStatuses.Quoted:
        return OfferStatuses.Booked

      default:
        return null
    }
  }

  const onSubmitClick = ({ content, includeOffer }: ChatFormData) => {
    const data = { content }

    onSubmit(
      produce<SubmitChatMessageData>(data, (draft) => {
        if (selectedOffer && includeOffer) {
          draft.offerId = selectedOffer.id

          const nextStatus = getNextOfferStatus(selectedOffer.status)

          if (nextStatus) {
            draft.nextStatus = nextStatus
          }
        }
      }),
    )

    reset()
  }

  return (
    <form className={className} onSubmit={handleSubmit(onSubmitClick)}>
      <Controller<ChatFormData, 'content'>
        name="content"
        control={control}
        render={({ field: { value, onChange, onBlur } }) => (
          <ErrorTooltip title={formState.errors.content?.message}>
            <TextField
              value={value ?? ''}
              onChange={onChange}
              onBlur={onBlur}
              error={!!formState.errors.content}
              placeholder={t('organisms.Chat.contentFieldPlaceholder')}
              rows={4}
              disabled={disabled}
              multiline
              fullWidth
            />
          </ErrorTooltip>
        )}
      />
      {isCheckboxVisible && (
        <Controller<ChatFormData, 'includeOffer'>
          name="includeOffer"
          control={control}
          render={({ field: { value, onChange, onBlur } }) => (
            <FormControlLabel
              disabled={disabled}
              label={
                selectedOffer?.status === OfferStatuses.Quoted
                  ? t('organisms.Chat.includeBookingLabel')
                  : t('organisms.Chat.includeQuotationLabel')
              }
              control={
                <CheckboxContainer>
                  <Checkbox
                    checked={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    disabled={disabled}
                  />
                </CheckboxContainer>
              }
            />
          )}
        />
      )}
      <ButtonContainer>
        <Button type="submit" disabled={!contentValue || disabled}>
          {t('organisms.Chat.submitButton')}
        </Button>
      </ButtonContainer>
    </form>
  )
}

const ButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row-reverse;
`

const CheckboxContainer = styled.div`
  margin: 1rem 1rem 1rem 0;
`

export default ChatForm
