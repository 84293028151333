import { useSelector } from 'react-redux'

import { selectSelectedOperator } from '@app/store/core/userOperators/userOperators.selectors'

export const useActiveOperator = () => {
  const selectedOperator = useSelector(selectSelectedOperator)

  if (!selectedOperator) {
    throw new Error(`Active operator not found`)
  }

  return selectedOperator
}
