import { LegFormData } from '@shared/interfaces/Leg'
import { AviaCalculationsResponseDto } from '@shared/modules/aviaPagesClient/aviaPages.interface'
import {
  DatePickerContainer,
  LegPointContainer,
  LegPointRow,
} from '@app/components/organisms/LegEditorForm/components/Wrappers'
import AirportPicker, {
  AirportPickerVariants,
} from '@app/components/atoms/AirportPicker/AirportPicker'
import SingleDatePicker from '@app/components/atoms/SingleDatePicker/SingleDatePicker'
import TimePicker from '@app/components/atoms/TimePicker/TimePicker'
import React from 'react'
import styled from 'styled-components'
import { LegTimeDistanceLegend } from '@app/components/organisms/LegEditorForm/components/LegTimeDistanceLegend'
import Typography from '@app/components/atoms/Typography/Typography'
import { useActiveUser } from '@app/hooks/useActiveUser'
import {
  getArrivalDate,
  getDepartureDate,
} from '@app/components/organisms/LegEditorForm/LegEditorForm.utils'
import { DisplayTimeTypes } from '@shared/enums'

interface Props {
  header: string
  leg: LegFormData
  type: 'airway' | 'greatCircle' | 'airwayWeatherImpacted'
  calculations: AviaCalculationsResponseDto
}

export const CalculatedLegDetail = (props: Props) => {
  const { header, leg, type, calculations } = props
  const distance =
    calculations.distance[type !== 'airwayWeatherImpacted' ? type : 'airway']
  const duration = calculations.flightTime[type] ?? 0
  const user = useActiveUser()
  const departureDate = getDepartureDate(
    leg.departureDate ?? new Date(),
    leg.departureTime ?? new Date(),
    leg.departureAirport?.timezone ?? '',
    user?.display_time_type ?? DisplayTimeTypes.UTC,
  )
  const arrivalDate = getArrivalDate(
    leg.departureDate ?? new Date(),
    leg.departureTime ?? new Date(),
    leg.departureAirport?.timezone ?? '',
    leg.arrivalAirport?.timezone ?? '',
    user?.display_time_type ?? DisplayTimeTypes.UTC,
    duration,
  )

  return (
    <CalculationResultWrapper>
      <HeadingContainer>
        <StyledTypography>{header}</StyledTypography>
      </HeadingContainer>
      <LegDetails>
        <StyledLegPointContainer>
          <StyledLegPointRowLeft>
            <StyledUpperRow>
              <StyledAirportPicker
                value={leg.departureAirport ?? null}
                variant={AirportPickerVariants.Condensed}
                disabled
              />
            </StyledUpperRow>
          </StyledLegPointRowLeft>
          <LegPointRow>
            <DatePickerContainer>
              <SingleDatePicker size="normal" value={departureDate} />
            </DatePickerContainer>
            <DatePickerContainer>
              <StyledTimePicker value={departureDate} disabled />
            </DatePickerContainer>
          </LegPointRow>
        </StyledLegPointContainer>
        <LegPointContainer>
          <LegTimeDistanceLegend distanceInKm={distance} time={duration ?? 0} />
        </LegPointContainer>
        <StyledLegPointContainer>
          <StyledLegPointRowRight>
            <StyledUpperRow>
              <StyledAirportPicker
                value={leg.arrivalAirport ?? null}
                variant={AirportPickerVariants.Condensed}
                disabled
              />
            </StyledUpperRow>
          </StyledLegPointRowRight>
          <LegPointRow>
            <DatePickerContainer>
              <SingleDatePicker size="normal" value={arrivalDate} />
            </DatePickerContainer>
            <DatePickerContainer>
              <StyledTimePicker value={arrivalDate} disabled />
            </DatePickerContainer>
          </LegPointRow>
        </StyledLegPointContainer>
      </LegDetails>
    </CalculationResultWrapper>
  )
}

const StyledTimePicker = styled(TimePicker)`
  width: 100%;
`

const StyledAirportPicker = styled(AirportPicker)`
  &.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot,
  .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: 0;
  }

  width: 7.75rem;
`

const StyledLegPointContainer = styled(LegPointContainer)`
  width: 100%;
`

const CalculationResultWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  align-items: flex-start;
`

const LegDetails = styled.div`
  display: flex;
`

const StyledUpperRow = styled.div`
  width: 8rem;
  display: flex;
  padding: 0 4px;
`

const StyledLegPointRowLeft = styled(LegPointRow)`
  justify-content: end;
`

const StyledLegPointRowRight = styled(LegPointRow)`
  justify-content: start;
`

const HeadingContainer = styled.div`
  display: flex;
  text-align: start;
  padding: 2px 4px;
  font-size: 0.66rem;
`
const StyledTypography = styled(Typography)`
  font-size: 1rem;
  font-weight: 600;
  color: #525252;
  margin-bottom: 0.5rem;
`
