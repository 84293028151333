import React, { MouseEvent } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'
import { IconButton } from '@material-ui/core'

import Tooltip from '@app/components/atoms/Tooltip/Tooltip'

export enum MarketingLegConversionMenuPosition {
  Start = 'Start',
  End = 'End',
}

interface ContainerProps {
  $position: MarketingLegConversionMenuPosition
}

interface StyledDoubleArrowIconProps {
  $position: MarketingLegConversionMenuPosition
}

interface MarketingLegExtensionMenuProps {
  onConvertToMarketingLeg: () => void
  position: MarketingLegConversionMenuPosition
  className?: string
}

const MarketingLegExtensionMenu = ({
  onConvertToMarketingLeg,
  position,
  className,
}: MarketingLegExtensionMenuProps) => {
  const { t } = useTranslation()

  const onMarketingLegButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()

    onConvertToMarketingLeg()
  }

  return (
    <Tooltip title={t('molecules.Schedule.marketplaceLegExtension') as string}>
      <Container className={className} $position={position}>
        <MarketingLegButton onClick={onMarketingLegButtonClick}>
          <StyledDoubleArrowIcon $position={position} />
        </MarketingLegButton>
      </Container>
    </Tooltip>
  )
}

const Container = styled.div<ContainerProps>`
  height: 100%;
  display: flex;
  align-items: center;
  transition: width 0.25s ease;
  background: ${({ theme }) => theme.palette.common.white};
  border-radius: 3px;
  border: 2px solid currentColor;

  flex-direction: ${({ $position }) =>
    $position === MarketingLegConversionMenuPosition.Start
      ? 'row'
      : 'row-reverse'};
`

const MarketingLegButton = styled(IconButton)`
  width: 100%;
  height: 100%;
  border-radius: 0;
  color: currentColor;
  padding: 0;
`

const StyledDoubleArrowIcon = styled(
  DoubleArrowIcon,
)<StyledDoubleArrowIconProps>`
  height: 0.8rem;
  width: 0.8rem;

  transform: ${({ $position }) =>
    $position === MarketingLegConversionMenuPosition.Start
      ? 'rotate(180deg)'
      : 'none'};
`

export default MarketingLegExtensionMenu
