import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

export enum DateTypes {
  Arrival = 'arrival',
  Departure = 'departure',
}

interface DateTypeSwitchProps {
  value: DateTypes | null
  onChange?: (value: DateTypes) => void
  isReturnDate?: boolean
}

// TODO static i18next scanner makes this kinda awkward
const DateTypeSwitch = ({
  value,
  onChange,
  isReturnDate,
}: DateTypeSwitchProps) => {
  const children = useMemo(() => {
    return (
      <>
        Dont like it?
        <Link
          data-testid="DateTypeSwitch__switch"
          onClick={() =>
            onChange?.(
              value === DateTypes.Arrival
                ? DateTypes.Departure
                : DateTypes.Arrival,
            )
          }
        >
          Change it!
        </Link>
      </>
    )
  }, [value, onChange])

  if (value === DateTypes.Arrival) {
    return isReturnDate ? (
      <Trans i18nKey="atoms.DateTypeSwitch.returnDateTypeArrival">
        {children}
      </Trans>
    ) : (
      <Trans i18nKey="atoms.DateTypeSwitch.dateTypeArrival">{children}</Trans>
    )
  }

  return isReturnDate ? (
    <Trans i18nKey="atoms.DateTypeSwitch.returnDateTypeDeparture">
      {children}
    </Trans>
  ) : (
    <Trans i18nKey="atoms.DateTypeSwitch.dateTypeDeparture">{children}</Trans>
  )
}

const Link = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
`

export default DateTypeSwitch
