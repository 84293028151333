import React, { useMemo, useRef, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { lighten } from '@material-ui/core/styles'

import PersonIcon from '@material-ui/icons/Person'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import ScheduleItemContextMenu from '@app/components/molecules/Schedule/ScheduleItemContextMenu'
import Tooltip from '@app/components/atoms/Tooltip/Tooltip'

import MarketingLegExtensionMenu, {
  MarketingLegConversionMenuPosition,
} from '@app/components/molecules/Schedule/MarketingLegExtensionMenu'

import { LegTypes, OfferStatuses, ScheduleSource } from '@shared/enums'
import { Theme } from '@app/theme'
import { getLegColor } from '@app/utils/stylingUtils'

export enum ScheduleItemVariants {
  Schedule = 'Schedule',
  Request = 'Request',
}

export enum ScheduleItemWidthBreakpoints {
  OneControlVisible = 30,
  TwoControlsVisible = 50,
  InfoVisible = 70,
}

interface DescriptionProps {
  $isContextMenuVisible: boolean
}

interface ScheduleItemContainerProps {
  $variant: ScheduleItemVariants
  $legType: LegTypes
  $offerStatus?: OfferStatuses
  $source?: ScheduleSource
  $isReserved?: boolean
}

interface StyledMarketingLegExtensionMenuProps {
  $variant: ScheduleItemVariants
  $legType: LegTypes
  $offerStatus?: OfferStatuses
  $closedWidth: string
}

interface MarketingExtensionProps {
  $left: string
  $width: string
}

interface MarketingExtensionTitleProps {
  $variant: ScheduleItemVariants
  $legType: LegTypes
  $offerStatus?: OfferStatuses
}

interface DepartureAirportPositionProps {
  $left: string
}

interface ArrivalAirportPositionProps {
  $right: string
}

interface ScheduleItemProps {
  isMarketingLeg: boolean
  variant: ScheduleItemVariants
  name?: string
  legType: LegTypes
  offerStatus?: OfferStatuses
  passengerCount: number
  departureAirport: string
  arrivalAirport: string
  index: number
  isHidden: boolean
  className?: string
  marketingLegConversionOptions?: {
    position: MarketingLegConversionMenuPosition
    onConvertToMarketingLeg: () => void
  } | null
  contextMenuActions?: {
    onEditMarketingLeg?: () => void
    onRemoveMarketingLeg?: () => void
    onEditOutage?: () => void
    onRemoveOutage?: () => void
    onMarkLegAsRemoved?: () => void
  }
  marketingExtensionOptions?: {
    width: number
    startPercentage: number
  }
  source?: ScheduleSource
  isReserved?: boolean
}

const ScheduleItem = ({
  isMarketingLeg,
  variant,
  name,
  legType,
  offerStatus,
  passengerCount,
  departureAirport,
  arrivalAirport,
  index,
  className,
  marketingLegConversionOptions,
  contextMenuActions,
  marketingExtensionOptions,
  isHidden,
  source = ScheduleSource.STRAFOS,
  isReserved,
}: ScheduleItemProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const theme = useTheme()
  const { t } = useTranslation()
  const isLegOrderVisible = variant === ScheduleItemVariants.Request

  const isWideEnoughToShowOneControl =
    (containerRef?.current?.offsetWidth ?? 0) >
    ScheduleItemWidthBreakpoints.OneControlVisible

  const isWideEnoughToShowTwoControls =
    (containerRef?.current?.offsetWidth ?? 0) >
    ScheduleItemWidthBreakpoints.TwoControlsVisible

  const isWideEnoughToShowInfo =
    (containerRef?.current?.offsetWidth ?? 0) >
    ScheduleItemWidthBreakpoints.InfoVisible

  const isContextMenuVisible =
    isWideEnoughToShowOneControl &&
    !!contextMenuActions &&
    source === ScheduleSource.STRAFOS

  const isMarketplaceExtensionMenuVisible =
    marketingLegConversionOptions &&
    !isMarketingLeg &&
    source === ScheduleSource.STRAFOS &&
    ((isWideEnoughToShowOneControl && !isContextMenuVisible) ||
      isWideEnoughToShowTwoControls)

  const isDescriptionVisible =
    isWideEnoughToShowInfo &&
    [LegTypes.Occupied, LegTypes.Ferry].includes(legType)

  const isTitleVisible =
    isWideEnoughToShowInfo &&
    [LegTypes.Occupied, LegTypes.Ferry, LegTypes.Outage].includes(legType)

  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false)

  const hoverTitle = useMemo(() => {
    if (!name) {
      return `${departureAirport} - ${arrivalAirport}`
    }

    return `${name} (${departureAirport} - ${arrivalAirport})`
  }, [name, arrivalAirport, departureAirport])

  const departureAirportLeftPercentage = useMemo(() => {
    if (
      !marketingExtensionOptions ||
      marketingExtensionOptions.startPercentage
    ) {
      return 0
    }

    return marketingExtensionOptions.width
  }, [marketingExtensionOptions])

  const arrivalAirportRightPercentage = useMemo(() => {
    if (
      !marketingExtensionOptions ||
      !marketingExtensionOptions.startPercentage
    ) {
      return 0
    }

    return marketingExtensionOptions.startPercentage
  }, [marketingExtensionOptions])

  if (isHidden) {
    return null
  }

  return (
    <Container
      className={className}
      title={hoverTitle}
      ref={containerRef}
      $variant={variant}
      $legType={legType}
      $offerStatus={offerStatus}
      $source={source}
      $isReserved={isReserved}
    >
      {marketingExtensionOptions && (
        <MarketingExtension
          $left={`${marketingExtensionOptions.startPercentage}%`}
          $width={`${marketingExtensionOptions.width}%`}
        >
          {isWideEnoughToShowInfo && (
            <>
              <MarketingExtensionTitle
                $variant={variant}
                $legType={legType}
                $offerStatus={offerStatus}
              >
                {t('molecules.Schedule.marketingLeg')}
              </MarketingExtensionTitle>
              <MarketingLegAirportInfo>
                {departureAirport} - {arrivalAirport}
              </MarketingLegAirportInfo>
            </>
          )}
        </MarketingExtension>
      )}
      {isMarketplaceExtensionMenuVisible && marketingLegConversionOptions && (
        <StyledMarketingLegExtensionMenu
          $closedWidth="1.5rem"
          $variant={variant}
          $legType={legType}
          $offerStatus={offerStatus}
          position={marketingLegConversionOptions.position}
          onConvertToMarketingLeg={
            marketingLegConversionOptions.onConvertToMarketingLeg
          }
        />
      )}
      {isWideEnoughToShowInfo && (
        <Description $isContextMenuVisible={isContextMenuVisible}>
          <UpperRow>
            {isLegOrderVisible && (
              <LegOrder>
                {t('molecules.Schedule.legOrder', { order: index + 1 })}
              </LegOrder>
            )}
            {isDescriptionVisible && (
              <Passengers>
                <span>{passengerCount}</span> <StyledPersonIcon />
              </Passengers>
            )}
          </UpperRow>
          {isTitleVisible && name && <Title>{name}</Title>}
        </Description>
      )}
      {isWideEnoughToShowInfo && (
        <DepartureAirportDot $left={`${departureAirportLeftPercentage}%`} />
      )}
      {isWideEnoughToShowInfo && (
        <ArrivalAirportDot $right={`${arrivalAirportRightPercentage}%`} />
      )}
      {isWideEnoughToShowInfo && (
        <>
          <DepartureAirport $left={`${departureAirportLeftPercentage}%`}>
            {departureAirport}
          </DepartureAirport>
          <ArrivalAirport $right={`${arrivalAirportRightPercentage}%`}>
            {arrivalAirport}
          </ArrivalAirport>
        </>
      )}
      {isContextMenuVisible &&
        contextMenuActions &&
        Object.values(contextMenuActions).some(Boolean) && (
          <Tooltip
            interactive
            open={isContextMenuOpen}
            onClose={() => setIsContextMenuOpen(false)}
            backgroundColor={theme.palette.common.white}
            borderColor={theme.palette.grey[300]}
            placement="right-start"
            title={
              <ScheduleItemContextMenu
                onEditMarketingLeg={contextMenuActions.onEditMarketingLeg}
                onRemoveMarketingLeg={contextMenuActions.onRemoveMarketingLeg}
                onEditOutage={contextMenuActions.onEditOutage}
                onRemoveOutage={contextMenuActions.onRemoveOutage}
                onMarkLegAsRemoved={contextMenuActions.onMarkLegAsRemoved}
              />
            }
          >
            <StyledIconButton
              $isMarketingExtensionMenuVisible={
                !!marketingLegConversionOptions && isWideEnoughToShowTwoControls
              }
              onClick={(event) => {
                event.stopPropagation()
                setIsContextMenuOpen(true)
              }}
            >
              <MoreVertIcon />
            </StyledIconButton>
          </Tooltip>
        )}
    </Container>
  )
}

const getColorByLegProperties = ({
  offerStatus,
  legType,
  variant,
  theme,
  source = ScheduleSource.STRAFOS,
  isReserved,
}: {
  offerStatus?: OfferStatuses
  legType: LegTypes
  variant: ScheduleItemVariants
  theme: Theme
  source?: ScheduleSource
  isReserved?: boolean
}) => {
  if (legType === LegTypes.Removed) {
    return theme.colors.red
  }

  if (isReserved) {
    return [LegTypes.Occupied, LegTypes.Ferry].includes(legType)
      ? theme.colors.purple
      : lighten(theme.colors.purple, 0.6)
  }

  if (variant === ScheduleItemVariants.Schedule) {
    if ([LegTypes.Occupied, LegTypes.Ferry].includes(legType)) {
      return source === ScheduleSource.FL3XX
        ? theme.colors.yellow
        : source === ScheduleSource.LEON
        ? theme.colors.darkBlue
        : theme.colors.green
    }

    if (legType === LegTypes.Outage) {
      return theme.colors.grey
    }

    return source === ScheduleSource.FL3XX
      ? lighten(theme.colors.yellow, 0.6)
      : source === ScheduleSource.LEON
      ? lighten(theme.colors.darkBlue, 0.6)
      : lighten(theme.colors.green, 0.6)
  }

  if (!offerStatus) {
    return [LegTypes.Occupied, LegTypes.Ferry].includes(legType)
      ? theme.colors.orange
      : lighten(theme.colors.orange, 0.6)
  }

  return getLegColor(theme, legType, offerStatus)
}

const Container = styled.div<ScheduleItemContainerProps>`
  display: flex;
  border-radius: 3px;
  cursor: pointer;

  background: ${({
    $offerStatus,
    $legType,
    $variant,
    $source,
    $isReserved,
    theme,
  }) =>
    getColorByLegProperties({
      offerStatus: $offerStatus,
      legType: $legType,
      variant: $variant,
      source: $source,
      isReserved: $isReserved,
      theme,
    })};
`

const Description = styled.div<DescriptionProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.5rem;

  padding-right: ${({ $isContextMenuVisible }) =>
    $isContextMenuVisible ? '1.5rem' : '0.5rem'};
`

const Title = styled.span`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 0.75rem;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`

const UpperRow = styled.div`
  display: flex;
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 0.625rem;
  line-height: 0.625rem;
  height: 0.625rem;

  & > * {
    padding-left: 0.5rem;
    margin-left: 0.5rem;
    border-left: 1px solid ${({ theme }) => theme.palette.common.white};
  }

  & > *:first-child {
    padding: 0;
    margin: 0;
    border: none;
  }
`

const Passengers = styled.span`
  display: flex;
  justify-content: space-between;
`

const StyledPersonIcon = styled(PersonIcon)`
  width: 0.75rem;
  height: 100%;
`

const LegOrder = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Airport = styled.span`
  position: absolute;
  font-size: 0.625rem;
  color: ${({ theme }) => theme.text.heading.dark};
`

const DepartureAirport = styled(Airport)<DepartureAirportPositionProps>`
  left: ${({ $left }) => $left};
  bottom: 0;
  transform: translate(-10%, 100%);
`

const ArrivalAirport = styled(Airport)<ArrivalAirportPositionProps>`
  right: ${({ $right }) => $right};
  bottom: 0;
  transform: translate(10%, 100%);
`

const AirportDot = styled.div`
  position: absolute;
  bottom: 3px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.common.white};
`

const DepartureAirportDot = styled(AirportDot)<DepartureAirportPositionProps>`
  left: ${({ $left }) => `calc(${$left} + 3px)`};
`

const ArrivalAirportDot = styled(AirportDot)<ArrivalAirportPositionProps>`
  right: ${({ $right }) => `calc(${$right} + 3px)`};
`

const StyledMarketingLegExtensionMenu = styled(
  MarketingLegExtensionMenu,
)<StyledMarketingLegExtensionMenuProps>`
  position: absolute;
  height: 100%;
  width: ${({ $closedWidth }) => $closedWidth};

  color: ${({ $offerStatus, $legType, $variant, theme }) =>
    getColorByLegProperties({
      offerStatus: $offerStatus,
      legType: $legType,
      variant: $variant,
      theme,
    })};

  ${({ position, $closedWidth }) =>
    position === MarketingLegConversionMenuPosition.End
      ? `left: calc(100% - ${$closedWidth})`
      : `right: calc(100% - ${$closedWidth})`};
`

const StyledIconButton = styled(IconButton)<{
  $isMarketingExtensionMenuVisible: boolean
}>`
  position: absolute;
  right: ${({ $isMarketingExtensionMenuVisible }) =>
    $isMarketingExtensionMenuVisible ? '2rem' : 0};
  height: 100%;
  padding: 0.25rem;
  color: ${({ theme }) => theme.palette.common.white};
`

const MarketingExtension = styled.div<MarketingExtensionProps>`
  position: absolute;
  top: 2px;
  bottom: 2px;
  background: rgba(255, 255, 255, 0.5);
  left: ${({ $left }) => `calc(${$left} + 2px)`};
  width: ${({ $width }) => `calc(${$width} - 4px)`};
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  overflow: hidden;

  & > *:not(:last-child) {
    margin-right: 0.2rem;
  }
`

const MarketingExtensionTitle = styled.span<MarketingExtensionTitleProps>`
  font-size: 0.75rem;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ $offerStatus, $variant, theme }) =>
    getColorByLegProperties({
      offerStatus: $offerStatus,
      legType: LegTypes.Occupied,
      variant: $variant,
      theme,
    })};
`

const MarketingLegAirportInfo = styled.span`
  font-size: 0.625rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export default ScheduleItem
