import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { darken, lighten } from '@material-ui/core/styles'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import FlightOutlined from '@material-ui/icons/FlightOutlined'

import Typography from '@app/components/atoms/Typography/Typography'
import Price from '@app/components/atoms/Price/Price'

import { ChatMessageDto } from '@shared/dto/chat.dto'
import { getOfferColor } from '@app/utils/stylingUtils'
import { OfferStatuses } from '@shared/enums'
import { useDateAndTimeFormats } from '@app/hooks/useDateAndTimeFormats'
import useGetFormattedDateBasedOnUserDisplaySettings from '@app/hooks/useGetFormattedDateBasedOnUserDisplaySettings'

dayjs.extend(utc)

interface ColoredBoxProps {
  $offerStatus: OfferStatuses
}

interface StyledPriceProps {
  $offerStatus: OfferStatuses
}

interface ChatMessageOfferInfoProps {
  message: ChatMessageDto
  className?: string
}

const ChatMessageOfferInfo = ({
  message,
  className,
}: ChatMessageOfferInfoProps) => {
  const { t } = useTranslation()
  const { dateTimeFormat } = useDateAndTimeFormats()
  const getFormattedDate = useGetFormattedDateBasedOnUserDisplaySettings()

  const {
    offer_status,
    offer_aircraft_registration,
    offer_departure_date,
    offer_arrival_date,
    offer_departure_airport_icao,
    offer_arrival_airport_icao,
    offer_arrival_airport_timezone,
    offer_departure_airport_timezone,
    offer_final_price,
    offer_pax,
  } = message

  if (
    !offer_status ||
    !offer_aircraft_registration ||
    !offer_departure_date ||
    !offer_arrival_date ||
    !offer_departure_airport_icao ||
    !offer_arrival_airport_icao ||
    !offer_final_price ||
    !Number.isFinite(offer_pax)
  ) {
    return null
  }

  switch (offer_status) {
    case OfferStatuses.Declined:
      return (
        <Typography>
          {t('molecules.ChatMessage.offerDeclined', {
            aircraft: offer_aircraft_registration,
          })}
        </Typography>
      )

    case OfferStatuses.Cancelled:
      return (
        <Typography>
          {t('molecules.ChatMessage.offerCancelled', {
            aircraft: offer_aircraft_registration,
          })}
        </Typography>
      )

    case OfferStatuses.Rejected:
      return (
        <Typography>
          {t('molecules.ChatMessage.offerRejected', {
            aircraft: offer_aircraft_registration,
          })}
        </Typography>
      )

    case OfferStatuses.Storno:
      return (
        <Typography>
          {t('molecules.ChatMessage.offerStorno', {
            aircraft: offer_aircraft_registration,
          })}
        </Typography>
      )

    case OfferStatuses.Quoted:
    case OfferStatuses.Booked:
      return (
        <Container className={className}>
          <Heading variant="secondary">
            {offer_status === OfferStatuses.Quoted
              ? t('molecules.ChatMessage.quotations')
              : t('molecules.ChatMessage.bookings')}
          </Heading>
          <ColoredBox $offerStatus={offer_status}>
            <Row>
              <Typography variant="boldText">
                {offer_aircraft_registration}
              </Typography>
              <StyledPrice
                value={offer_final_price}
                $offerStatus={offer_status}
              />
            </Row>
            <Row>
              <StyledTypography>
                <span
                  title={t('general.utcDate', {
                    date: dayjs
                      .utc(offer_departure_date)
                      .format(dateTimeFormat),
                    interpolation: { escapeValue: true },
                  })}
                >
                  {
                    getFormattedDate(
                      offer_departure_date,
                      offer_departure_airport_timezone,
                    ).dateTime
                  }
                </span>{' '}
                -{' '}
                <span
                  title={t('general.utcDate', {
                    date: dayjs.utc(offer_arrival_date).format(dateTimeFormat),
                    interpolation: { escapeValue: true },
                  })}
                >
                  {
                    getFormattedDate(
                      offer_arrival_date,
                      offer_arrival_airport_timezone,
                    ).dateTime
                  }
                </span>
              </StyledTypography>
              <StyledTypography>
                {t('molecules.ChatMessage.passengerCount', {
                  count: offer_pax,
                })}
              </StyledTypography>
            </Row>
            <Row>
              <AirportsContainer>
                <StyledTypography>
                  {offer_departure_airport_icao}
                </StyledTypography>
                <IconContainer>
                  <StyledFlightOutlined />
                </IconContainer>
                <StyledTypography>
                  {offer_arrival_airport_icao}
                </StyledTypography>
              </AirportsContainer>
            </Row>
          </ColoredBox>
        </Container>
      )

    case OfferStatuses.New:
    case OfferStatuses.Unhandled:
    case OfferStatuses.Draft:
    default:
      console.warn(
        `Invalid offer status in ChatMessageOfferInfo - '${offer_status}'`,
      )

      return null
  }
}

const Container = styled.div`
  margin-top: 2rem;
`

const Heading = styled(Typography)`
  margin-bottom: 0.5rem;
`

const ColoredBox = styled.div<ColoredBoxProps>`
  padding: 0.5rem 1rem;

  background: ${({ $offerStatus, theme }) =>
    lighten(getOfferColor(theme, $offerStatus), 0.9)};
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledPrice = styled(Price)<StyledPriceProps>`
  font-weight: bold;
  margin-bottom: 0.5rem;

  color: ${({ $offerStatus, theme }) =>
    darken(getOfferColor(theme, $offerStatus), 0.25)};
`

const AirportsContainer = styled.div`
  display: flex;
  align-items: center;
`

const IconContainer = styled.div`
  padding: 0 0.5rem;
`

const StyledFlightOutlined = styled(FlightOutlined)`
  width: 1rem;
  height: 1rem;
  color: ${({ theme }) => theme.palette.grey[700]};
  transform: translate(0, 0.2rem) rotate(45deg);
`

const StyledTypography = styled(Typography)`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.palette.grey[700]};
`

export default ChatMessageOfferInfo
