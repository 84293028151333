import { Warning, Error, Info } from '@material-ui/icons'
import { CreateWarning } from '@shared/dto'
import { WarningType } from '@shared/enums'
import React from 'react'

export type CreateWarningFormData = CreateWarning & { bothWays: boolean }

export enum ButtonAction {
  Update = 'update',
  Delete = 'delete',
  Copy = 'copy',
  Create = 'create',
}
export const colors = [
  { name: 'Blue', value: '#2196f3' },
  { name: 'Green', value: '#4caf50' },
  { name: 'Red', value: '#f44336' },
  { name: 'Orange', value: '#f6a71e' },
  { name: 'Yellow', value: '#ffeb3b' },
]

export const icons = [
  { name: 'Info', component: <Info />, value: 'info' },
  { name: 'Warning', component: <Warning />, value: 'warning' },
  { name: 'Error', component: <Error />, value: 'error' },
]

export const warningTypes = [
  { name: 'All', value: WarningType.All },
  { name: 'Paid', value: WarningType.Paid },
  { name: 'Not Paid', value: WarningType.NotPaid },
]

export type FieldNameTypes =
  | `warning.${number}.country`
  | `warning.${number}.aircraft`
  | `warning.${number}.key`
