import React from 'react'
import { Hooks, CellProps } from 'react-table'

import Checkbox from '@material-ui/core/Checkbox'

export function useRowSelectionColumn<T extends object>(isEnabled: boolean) {
  return (hooks: Hooks<T>): void => {
    if (!isEnabled) {
      return
    }

    hooks.allColumns.push((columns) => [
      {
        id: 'selection',
        width: 30,
        Header: SelectionHeader,
        Cell: SelectionCell,
      },
      ...columns,
    ])
  }
}

function SelectionHeader<T extends object>({
  getToggleAllRowsSelectedProps,
  withSelectAll,
}: CellProps<T>): JSX.Element {
  return (
    <>
      {withSelectAll && (
        <Checkbox color="primary" {...getToggleAllRowsSelectedProps()} />
      )}
    </>
  )
}

function SelectionCell<T extends object>({
  row,
  isSelectable,
}: CellProps<T>): JSX.Element {
  return (
    <>
      {isSelectable && (
        <Checkbox color="primary" {...row.getToggleRowSelectedProps()} />
      )}
    </>
  )
}
