import React, { forwardRef, MouseEventHandler } from 'react'
import styled from 'styled-components'
import { theme } from '@app/theme'
import EmailIcon from '@material-ui/icons/EmailOutlined'

interface Props {
  onClick?: MouseEventHandler<SVGSVGElement>
}

const NewMessageIcon = forwardRef<SVGSVGElement, Props>((props: Props, ref) => {
  return <StyledEmailIcon {...props} ref={ref} />
})

export default NewMessageIcon

const StyledEmailIcon = styled(EmailIcon)`
  color: ${theme.colors.red};
`
