import { useSelector } from 'react-redux'

import { selectUserInfo } from '@app/store/core/userInfo/userInfo.selectors'
import { DisplayTimeFormats } from '@shared/enums'

import {
  SIMPLE_DATE_AND_TIME_FORMAT,
  SIMPLE_DATE_FORMAT,
  SIMPLE_TIME_FORMAT,
} from '@shared/constants'

const defaults = {
  dateFormat: SIMPLE_DATE_FORMAT,
  timeFormat: SIMPLE_TIME_FORMAT,
  dateTimeFormat: SIMPLE_DATE_AND_TIME_FORMAT,
  isTime24hFormat: true,
}

export const useDateAndTimeFormats = () => {
  const userInfo = useSelector(selectUserInfo)

  const dateFormat = userInfo?.display_date_format ?? defaults.dateFormat
  const timeFormat = userInfo?.display_time_format ?? defaults.timeFormat

  const dateTimeFormat =
    userInfo?.display_date_format && userInfo?.display_time_format
      ? `${dateFormat} ${timeFormat}`
      : SIMPLE_DATE_AND_TIME_FORMAT

  const isTime24hFormat = userInfo?.display_time_format
    ? userInfo.display_time_format === DisplayTimeFormats.TwentyFourHours
    : true

  return {
    dateTimeFormat,
    dateFormat,
    timeFormat,
    isTime24hFormat,
  }
}
