import React, { ReactNode } from 'react'
import styled from 'styled-components'

import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'

import GeneralNoDataIcon from '@app/components/atoms/icons/GeneralNoDataIcon/GeneralNoDataIcon'
import Typography from '@app/components/atoms/Typography/Typography'

type RequestSidebarSectionProps = {
  isLoading?: boolean
  isError?: boolean
  errorMessage?: string
  heading: string
  children: ReactNode
}

const RequestSidebarSection = ({
  isLoading,
  isError,
  errorMessage,
  heading,
  children,
}: RequestSidebarSectionProps) => {
  return (
    <Box width="100%" px="2rem" pt="2rem">
      <Box mb="1rem">
        <SidebarSectionHeading>{heading}</SidebarSectionHeading>
      </Box>

      {isError ? (
        <ErrorContainer message={errorMessage} />
      ) : isLoading ? (
        <LoadingContainer />
      ) : (
        children
      )}
    </Box>
  )
}

type ErrorContainerProps = {
  message?: string
}

const ErrorContainer = ({ message }: ErrorContainerProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p="1rem"
    >
      <GeneralNoDataIcon width="3rem" />

      {message && (
        <Box mt="1rem">
          <ErrorMessage>{message}</ErrorMessage>
        </Box>
      )}
    </Box>
  )
}

/**
 * @todo [refactor] create Typography variant
 */
const ErrorMessage = styled(Typography)`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.palette.grey[400]};
`

const LoadingContainer = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" p="2rem">
      <CircularProgress thickness={3} size={40} />
    </Box>
  )
}

const SidebarSectionHeading = styled(Typography)`
  font-size: 1rem;
  font-weight: bold;
`

export default RequestSidebarSection
